import React, { useState, useEffect } from "react";
interface Props {
  srcObject: MediaStream | null;
  muted?: boolean;
  className?: string;
  id?: string;
}

const Audio: React.FC<Props> = (props) => {
  const { srcObject, muted = false } = props;
  const [self, setSelf] = useState<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (self != null) {
      self.srcObject = srcObject;
    }
  }, [srcObject, self]);

  return <audio ref={setSelf} autoPlay playsInline muted={muted} />;
};

export default Audio;
