const notificationByType = {
  multipleFaces: "Multiple Faces",
  leaveEnter: "Notifications Room",
  cellPhone: "Cellphone",
  restrictedApps: "Restricted Apps",
};

const roomViewModeConst = {
  collabMode: "meeting",
  lectureMode: "supervisor",
};

const socketConnectiontype = {
  chat: "chat",
  media: "media",
  activityChannel: "activityChannel",
  desktopApp: "desktopApp",
  whiteBoard: "whiteBoard",
};

const chatType = {
  SAMEROOM: "SAMEROOM",
  OTHERROOM: "OTHERROOM",
  PRIVATE: "PRIVATE",
  BUSSUNIT: "BUSSUNIT",
  BROADCAST: "BROADCAST",
  SELF: "SELF",
};

// const chatMessageType = {
//     DOC: 'DOCUMENT',
//     IMG: 'IMAGE'
// };

const role = {
  SUPERVISOR: "Supervisor",
  ADMIN: "Admin",
  MANAGER: "Manager",
  DIRECTOR: "Director",
  AGENT: "Agent",
};

const meetingRoomConstant = {
  offline: "offline",
  stop: "stop",
};

const socketPathName = {
  chat: "/chatws",
  media: "/TrainingRoomSocketServer",
  activityChannel: "/acws",
  desktopApp: "/desk-ws",
  whiteboard: "/desk-ws",
};

const allowedImageTypes: string[] = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/svg+xml",
];

const allowedDocumentTypes: string[] = [
  "application/pdf",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "text/plain",
  "text/csv",
  "application/rtf", // RTF (Rich Text Format)
  "application/json", // JSON
];

const mediaTypeConstant = {
  video: "VIDEO",
  screen: "SCREEN",
  screentwo: "SCREENTWO",
};

const resolutionConstant = {
  high: "HIGH",
  mid: "MID",
  low: "LOW",
  verylow: "VERYLOW",
};

const screenResolution = {
  SCREEN_SHARE_HEIGHT: 1080,
  SCREEN_SHARE_WIDTH: 1920,
};

const higherRoleOptions = ["Whiteboard","Upload Video/Audio"];

const LUNCH_TIME_LIMIT_MINUTES = { value: "30", status: false };
const BREAK_TIME_LIMIT_MINUTES = { value: "30", status: false };
const BRB_TIME_LIMIT_MINUTES = { value: "10", status: false };

const emojiEventConst = {
  BRB: "BRB",
  LUNCH: "Lunch",
  BREAK: "Break",
  TECHNICALISSUE: "technicalissue",
  HANDRAISE: "Handraise",
  SMILE: "Smile",
  CANTHEAR: "Canthear",
  YES: "Yes",
  NO: "No"
}




export const NETWORK_MONITORING_INTERVAL = 10 * 1000;
export const AGENT_TO_AGENT_CHAT = true;
export const AGENT_TO_AGENT_PRIVATE_CALL = true;

export const SUPERVISOR_CAN_OBSERVE = true;
export const OTHER_CAMERA_ACCESS_TO_SUPERVISOR = true;
export const AGENT_CAM_AUTO_ON = false;
export const AGENT_MIC_ACCESS = true;
export const AGENT_SCREENS_ACCESS = true
export const EMOTION_DETECTION_ADMIN_CONTROL = true
export const REACT_APP_HEAD_POSE = true;
export const AGENT_CAM_ALWAYS_ON = false;
export const VIDEO_BACKGROUND_BLUR = true;
export const VIDEO_BACKGROUND_IMAGES = true;
export const FILE_UPLOADS_CHAT = true;
export const FILE_UPLOADS_TOPICS = true;

export {
  notificationByType,
  roomViewModeConst,
  socketConnectiontype,
  chatType,
  meetingRoomConstant,
  socketPathName,
  allowedImageTypes,
  allowedDocumentTypes,
  mediaTypeConstant,
  resolutionConstant,
  role,
  screenResolution,
  higherRoleOptions, LUNCH_TIME_LIMIT_MINUTES, BREAK_TIME_LIMIT_MINUTES, BRB_TIME_LIMIT_MINUTES,
  emojiEventConst
};
