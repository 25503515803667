import {createSlice,PayloadAction} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';


const initialState = {
    videoOption:"normal"
}



const videoOptionSlice = createSlice({
    name:"videoOption",
    initialState,
    reducers:{
        setVideoOption:(state,action:PayloadAction<any>) => {
            state.videoOption = action.payload
        },
        clearVideoOption:() =>{
            return initialState;
        }
    }
})




export const {setVideoOption,clearVideoOption} = videoOptionSlice.actions;
export const videoOptionSelector = (state:RootState) => state.videoOption
export default videoOptionSlice.reducer;