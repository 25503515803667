import React, { useEffect, useState } from "react";
import PasswordResetImage from '../../assets/images/forgot.png'
import Logo from '../../assets/images/logo.png'
import Input from "../../components/Input";
import { useForm, SubmitHandler } from 'react-hook-form';
import { PasswordResetInputs } from "../../interfaces/componentsInterface/passwordReset";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import { checkTokenValid, changePassword } from "../../services/forgotPassword";
import toast from "react-hot-toast";

export default function PasswordReset(): ReturnType<React.FC> {
    const navigate = useNavigate();
    const { resetPasswordToken } = useParams();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<PasswordResetInputs>()
    const [show, setShow] = useState<boolean>(true)
    const [message, setMessage] = useState<string>("");
    const handleGoBack = () => {
        navigate('/login')
    }

    const checkTokenIsValid = async () => {

        try {
            // @ts-ignore
            const res = await checkTokenValid(resetPasswordToken);
            if (res.success) {
                setShow(true);
            } else {
                setShow(false);
                setMessage(res.message);
            }
        } catch (err: any) {
            setShow(false);
            setMessage("Password reset token is invalid or has expired.");
        }
    };

    const onSubmit: SubmitHandler<PasswordResetInputs> = async (data) => {
        if (data.password !== data.confirmPassword) {
            toast.error("Confirm password should be same as password")
            return;
        }

        try {
            const res = await changePassword(resetPasswordToken, data.password);
            if (res.success) {
                toast.success("Password changed successfully!");
                setTimeout(() => {
                    navigate('/')
                }, 1500)
            } else {
                toast.error(res.message);
            }
        } catch (err) {
            console.log("Error in Changing Password", err);
        }



    }


    useEffect(() => {
        checkTokenIsValid();

        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <>

            <div style={{ backgroundImage: `url(${PasswordResetImage})` }} className="bg-cover w-screen h-screen overflow-hidden flex justify-center items-center">
                {
                    show ?
                        <div className="xl:w-[28%] lg:w-[30%] p-12 gap-2.5 bg-white rounded-xl">
                            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                                <img
                                    className="mx-auto h-16 w-auto"
                                    src={Logo}
                                    alt="Your Company"
                                />
                                <h2 className="mt-2 font-sans text-center lg:text-2xl xl:text-3xl font-semibold leading-9 tracking-tight text-gray-900 xl:mb-4">
                                    Reset Password
                                </h2>
                                <p className="font-sans text-gray-500 text-center lg:text-sm lg:mt-1 xl:text-[1rem] leading-normal text-base  not-italic font-normal self-stretch ">Please enter the details to reset your password!</p>
                            </div>
                            <div className="mt-5 sm:mx-auto  w-full">
                                <form onSubmit={handleSubmit(onSubmit)} className="lg:space-y-1.5 xl:space-y-6">
                                    <div>
                                        <Input {...register('password', { required: true })} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setValue('password', e.target.value, { shouldValidate: true });
                                        }} label='Password' type='password' placeholder='Enter Password' error={errors.password} message={errors.password && "Password is required*"} />
                                    </div>
                                    <div>
                                        <Input {...register('confirmPassword', { required: true })} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setValue('confirmPassword', e.target.value, { shouldValidate: true });
                                        }} label='Confirm Password' type='password' placeholder='Enter Confirm Password' error={errors.confirmPassword} message={errors.confirmPassword && "Confirm Password is required*"} />
                                    </div>



                                    <div>
                                        <button
                                            type='submit'
                                            className="flex w-full justify-center rounded-md bg-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue hover:opacity-[0.9] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue"
                                        >Sign In</button>


                                    </div>
                                </form>


                            </div>
                        </div> :
                        <>

                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 rounded-xl">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <h1 className="text-base font-semibold leading-6 text-gray-900">
                                            OOPS!
                                        </h1>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {message}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                        onClick={handleGoBack}
                                    >
                                        Back to Login
                                    </button>

                                </div>
                            </div>



                        </>












                }

            </div>

        </>
    )
}