import React from 'react';
import classNames from 'classnames';

interface PasswordStrengthIndicatorProps {
  strength: number;
}

const PasswordStrengthIndicator: React.FC<PasswordStrengthIndicatorProps> = ({ strength }) => {
  const getColor = () => {
    switch (strength) {
      case 1:
        return 'bg-red-600';
      case 2:
        return 'bg-yellow-500';
      case 3:
        return 'bg-yellow-500';
      case 4:
        return 'bg-green-500';
      case 5:
        return 'bg-green-500';
      default:
        return 'bg-gray-300';
    }
  };

  return (
    <div className="mt-2  h-2 rounded bg-gray-300 w-[30%]">
      <div
      className={classNames('h-full rounded transition-all duration-300', getColor())}
        style={{ width: `${(strength / 5) * 100}%` }}
      ></div>
    </div>
  );
};

export default PasswordStrengthIndicator;
