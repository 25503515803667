import { getToken } from "../utils/Token";
import { whiteboardRequest} from ".";



const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: getToken(),
  },
};

export const getConnectionDetailsForWhiteboard = async ( roomName:any,roomId:any) => {
    config["headers"]["Authorization"] = getToken();
   
    const reqBody = {
      roomName,
      roomId,
  };
    const connectionDetailsData = await whiteboardRequest.post(
      "/v1/api/get-connection-details",
      reqBody,
      config
    );
  
    return connectionDetailsData?.data?.connectionDetails;
  };