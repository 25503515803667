import React, { InputHTMLAttributes } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { FieldError } from 'react-hook-form';

interface GenericInputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    error?: FieldError | undefined | any;
    message?: string | any;
    className?:string;
}
export default function Input(props: GenericInputProps): ReturnType<React.FC> {
    return (
        <>
            <div className={`${props.className}`}>
                {props.label && <label className="block text-gray-700 text-sm font-bold mb-2">{props.label}</label>}
                <div className="relative flex items-center">
                    <input
                        {...props}
                        className={`w-full py-2 px-3 border-2  border-gray-300 focus:border-blue  focus:outline-none rounded-md leading-5 transition ease-in-out  sm:text-sm sm:leading-5 ${props.error && 'border-red-600 focus:border-red-600'} `}
                    />
                    {
                        props.error && <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-600" aria-hidden="true" />
                        </div>
                    }
                </div>
                {
                    props.message && <p className="lg:mt-[2px] xl:mt-2 text-sm text-red-600" id="email-error">
                        {props.message}
                    </p>
                }

            </div>


        </>
    )
}