import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
    getAllTopicsExtended,
    getSubscribedTopics,
    getAllFeeds,
} from "../../services/activitychannel";
import { Feed, Topic } from "../../interfaces/slicesInterface/activityChannel";

interface InitialState {
    loading: boolean;
    success: boolean;
    error: string;
    allTopics: Topic[];
    subscribedTopicIds: string[]; // Specify the type as number[]
    subscribedTopics: Topic[];
    allFeeds: Feed[];
    unreadPostsCount: number;
    activityPopup: boolean,
    selectedFeedTopic: string,
    selectedFeedTopicId: string,
    pageNo: number,
    modalShowUrgentPost: boolean
}

const initialState: InitialState = {
    loading: false,
    success: false,
    error: "",
    allTopics: [],
    subscribedTopicIds: [],
    subscribedTopics: [],
    allFeeds: [],
    unreadPostsCount: 0,
    activityPopup: false,
    selectedFeedTopic: "allPosts",
    selectedFeedTopicId: "",
    pageNo: 1,
    modalShowUrgentPost: false
};

// const initialState = {
//     loading: false,
//     success: false,
//     error: "",
//     allTopics: [] as Topic[],
//     subscribedTopicIds: [],
//     subscribedTopics: [] as Topic[],
//     allFeeds: [] as Feeds[],
//     unreadPostsCount:0,
// };

const activityChannelSlice = createSlice({
    name: "activityChannel",
    initialState,
    reducers: {
        setUnreadPostsCount: (state, action: PayloadAction<number>) => {
            state.unreadPostsCount = action.payload;
        },
        // removeDeletedFeed: (state, action: PayloadAction<string>) => {
        //     const idToRemove = action.payload;
        //     state.allFeeds = state.allFeeds.filter(
        //         (obj: any) => obj._id.toString().trim()!== idToRemove.toString().trim()
        //     );
        // },
        removeDeletedFeed: (state, action: PayloadAction<string>) => {
            const idToRemove = action.payload.trim();
            state.allFeeds = state.allFeeds.filter(
                (feed) => feed._id !== idToRemove
            )
        },
        setFeeds: (state, action: PayloadAction<Feed>) => {
            let hasMagenicVendor = state.allFeeds.some(feed => feed['_id'] === action.payload._id)
            if (!hasMagenicVendor) {
                state.allFeeds.unshift(action.payload);
            }
        },
        setActivityPopup: (state, action: PayloadAction<boolean>) => {
            state.activityPopup = action.payload;
        },
        setSelectedFeedTopic: (state, action: PayloadAction<string>) => {
            state.selectedFeedTopic = action.payload;
        },
        setSelectedFeedTopicId: (state, action: PayloadAction<string>) => {
            state.selectedFeedTopicId = action.payload;
        },
        setPageNo: (state, action: PayloadAction<number>) => {
            state.pageNo = action.payload;
        },
        setModalShowUrgentPost: (state, action: PayloadAction<boolean>) => {
            state.modalShowUrgentPost = action.payload;
        },
        updateFeeds: (state, action: PayloadAction<Feed>) => {
            const updatedFeed = action.payload;
            console.log({ updatedFeed, allFeeds: state.allFeeds })
            const index = state.allFeeds.findIndex(feed => feed?._id === updatedFeed?._id);
            if (index !== -1) {
                const existingFeed = state.allFeeds[index];
                state.allFeeds[index] = updatedFeed;
                state.allFeeds[index].imageUrl = updatedFeed?.imageUrl || existingFeed?.imageUrl;
                // state.allFeeds[index] = {
                //     ...existingFeed,
                //     ...updatedFeed,
                //     imageUrl: updatedFeed.imageUrl ?? existingFeed.imageUrl 
                // };
            }
        },
    },
    extraReducers(builder) {
        builder.addCase(getAllTopicsExtended.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllTopicsExtended.fulfilled, (state, action: PayloadAction<any>) => {
            if (action.payload.success) {
                state.loading = false;
                state.allTopics = action.payload.topics;
                state.subscribedTopicIds = action.payload.subscribedTopicIds;
                state.success = true;
            } else {
                state.loading = false;
                state.success = false;
                state.error = action.payload.message;
            }
        });
        builder.addCase(getAllTopicsExtended.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        });

        builder.addCase(getSubscribedTopics.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSubscribedTopics.fulfilled, (state, action: PayloadAction<any>) => {
            if (action.payload.success) {
                state.loading = false;
                state.success = true;
                state.subscribedTopics = action.payload.topics;
            } else {
                state.loading = false;
                state.success = false;
                state.error = action.payload.message;
            }
        });
        builder.addCase(getSubscribedTopics.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.success = false;
            state.error = action.payload.message;
        });

        builder.addCase(getAllFeeds.pending, (state, action) => {
            if (action.meta.arg.pageNo === 1) {
                state.loading = true;
            }
        });
        builder.addCase(getAllFeeds.fulfilled, (state, action: PayloadAction<any>) => {
            console.log({ action })
            if (action.payload.success) {
                state.loading = false;
                state.success = true;

                if (action.payload.feeds) {
                    if (action.payload.pageNo === 1) {
                        state.allFeeds = action.payload.feeds;
                    } else {
                        if (action.payload.feeds.length > 0) {
                            // const updatedFeeds = [...state.allFeeds, ...action.payload.feeds];
                            // return {
                            //     ...state,
                            //     allFeeds: updatedFeeds,
                            // };
                            state.allFeeds.push(...action.payload.feeds);
                        }
                    }
                }
            } else {
                state.loading = false;
                state.success = false;
                state.error = action.payload.message;
            }
        });
        builder.addCase(getAllFeeds.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.success = false;
            state.error = action.payload.message;
        });
    },
});

export const activityChannelSelector = (state: RootState) => state.activityChannel;
export const { setUnreadPostsCount, removeDeletedFeed, setFeeds, updateFeeds, setActivityPopup, setSelectedFeedTopicId, setSelectedFeedTopic, setModalShowUrgentPost, setPageNo } = activityChannelSlice.actions;
export default activityChannelSlice.reducer;
