import React from "react";
import Button from "../Button";

interface UrgentPostModalProps {
    show: boolean;
    onHide: () => void;
}

const UrgentPostModal: React.FC<UrgentPostModalProps> = ({ show, onHide }) => {
    if (!show) return null;

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>

                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="flex justify-end items-end ">
                            <button
                                onClick={onHide}
                                className="text-gray-400 hover:text-gray-500 focus:outline-none"
                            >
                                <span className="sr-only">Close</span>
                                <svg
                                    className="h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="flex justify-center items-center">
                            <h3
                                className=" text-lg leading-6 font-medium text-red-600"
                                id="modal-headline"
                            >
                                New Post Alert
                            </h3>
                        </div>
                        <div className="mt-3 text-center sm:mt-0 flex justify-center items-center">
                            <div className="mt-2">
                                <p className="text-sm text-gray-500 ">Urgent! You have new post.</p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse flex justify-center items-center">
                        <Button
                            onClick={onHide}
                            type="primary"
                            className="w-24 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3  sm:text-sm"
                        >
                            OK
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UrgentPostModal;
