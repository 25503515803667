import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FaceRegistrationParamsDataType,
  FaceRegistrationServiceFileReturns,
} from "../interfaces/slicesInterface/faceRegistration";
import resizeImageAndReturnFileAndURL from "../utils/base64tofile";
import { authRequest, faceRequest } from ".";
import generateUrl from "../utils/generateImageURL";
import { getToken } from "../utils/Token";

const faceConfig = {
  headers: {
    Authorization: "",
    "Content-Type": "application/json",
  },
};

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: "",
  },
};

async function returnFile(url: string) {
  try {
    const imageUrl = url;
    const targetWidth = 640;
    const targetHeight = 480;

    const { file } = await resizeImageAndReturnFileAndURL(
      imageUrl,
      targetWidth,
      targetHeight
    );
    return { file };
  } catch (error) {
    console.error(error);
  }
}

export const checkRegistrationStatus = createAsyncThunk(
  "faceRegistration/checkRegistrationStatus",
  async () => {
    try {
      config["headers"]["Authorization"] = getToken();

      

      const response = await authRequest.get(
        "/v1/api/facevalidation/checkRegistrationStatus",
        
        config
      );
      return response.data;
    } catch (err: unknown | any) {
      throw new Error(`Error fetching RegistrationStatus ${err.message}`);
    }
  }
);

export const faceRegistrationService = createAsyncThunk(
  "faceRegistration/faceRegistration",
  async (data: FaceRegistrationParamsDataType) => {
    // console.log(data)
    try {
      const updatedFile: FaceRegistrationServiceFileReturns | any =
        await returnFile(data.image);

      const registerResponse = await faceRegistration(data);

      if (registerResponse.is_registered) {
        const saveResponse = await saveFace(
          generateUrl(
            data.username,
            data.custId,
            data.spacesConfig,
            data.companyName
          ),
          updatedFile.file,
          data.companyName
        );
        return saveResponse;
      } else {
        return registerResponse;
      }
    } catch (err: unknown | any) {
      throw new Error(`Error in Face Registrations: ${err.message}`);
    }
  }
);

export const faceRegistration = async (
  data: FaceRegistrationParamsDataType
) => {
  faceConfig["headers"]["Authorization"] = `Bearer ${data.faceDetectionToken}`;

  const body = {
    username: data.username,
    client: data.client,
    image: data.image,
    remove_existing: data.remove_existing.toString(),
    custCode: data.custId,
    userId:data.userId
  };

  const response = await faceRequest(data.url).post(
    "/faceconfiguration",
    body,
    faceConfig
  );

  return response.data;
};

export const saveFace = async (
  url: string,
  file: File,
  companyName: string
) => {
  config["headers"]["Authorization"] = getToken();
  const data = new FormData();
  data.append("file", file);
  data.append("imageUrl", url);
  data.append("companyName", companyName);
  const response = await authRequest.post("/v1/api/facevalidation/saveface", data, config);
  return response.data;
};
