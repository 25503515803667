import { authRequest } from "./index";
import { getToken } from "../utils/Token";
import axios from "axios";

const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: '',
    },
};

export const processIPfromWebrtc = async (ipaddress: string) => {

    let response = await axios.get(
        `${process.env.REACT_APP_ABSTRACTAPI_BASE_URL}?api_key=${process.env.REACT_APP_ABSTRACTAPI_KEY}&ip_address=${ipaddress}`,
        {
            timeout: 2000,
        }
    );

    config["headers"]["Authorization"] = getToken();
    const body = {
        abstractAPIresponse: response.data,
    };

    // const result = await
    authRequest.post("/v1/api/userlocation/savelocationwebrtc", body, config);
    localStorage.setItem("webrtcipfetched", "true");
    return;
};

