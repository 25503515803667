import React from "react";
import { CheckIcon } from "@heroicons/react/20/solid";

interface DoubleCheckIconProps {
    seen: boolean;
}

const DoubleCheckIcon: React.FC<DoubleCheckIconProps> = ({ seen }) => {
    return (
        <div className="flex items-center relative h-3 w-3">
            <span className=" left-0 absolute bottom-0">
                <CheckIcon className={`h-3 w-3 ${seen ? "text-white" : ""}`} />
            </span>
            {seen && (
                <span className=" left-1 absolute bottom-0">
                    <CheckIcon className="h-3 w-3 text-white" />
                </span>
            )}
        </div>
    );
};

export default DoubleCheckIcon;
