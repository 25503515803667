import { authRequest } from ".";
import { ChangePassword } from "../interfaces/formsInterface/changePwd";
import { getToken } from "../utils/Token";

const config = {
  headers: {
    Authorization: "",
    ContentType: "application/json",
  },
};
export const changePassword = async (data: ChangePassword) => {
  config["headers"]["Authorization"] = getToken();
  try {
    const response = await authRequest.put(
      "/v1/api/user/password/update",
      data,
      config
    );
    return response.data;
  } catch (err: unknown | any) {
    return `Error fetching RegistrationStatus ${err.message}`;
  }
};
