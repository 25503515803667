import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { VerifyFaceState } from "../../interfaces/slicesInterface/verifyFace";

const initialState:VerifyFaceState = {
  faceVerification: true,
  recurrentCheck: false,
  wrongPerson: false,
};

const verifyFaceSlice = createSlice({
  name: "verifyFaceSlice",
  initialState,
  reducers: {
    setVerifyFace: (state, action: PayloadAction<any>) => {
      state.faceVerification = action.payload;
    },

    setRecurrentCheck: (state, action: PayloadAction<any>) => {
      state.recurrentCheck = action.payload;
    },
    setWrongPerson: (state, action: PayloadAction<any>) => {
      state.wrongPerson = action.payload;
    },
    clearVerifyFace:() => {
      return initialState;
    }
  },
});


export const {setVerifyFace,setRecurrentCheck,setWrongPerson,clearVerifyFace} = verifyFaceSlice.actions;
export const verifyFaceSelector = (state: RootState) => state.verifyFaceSlice;
export default verifyFaceSlice.reducer