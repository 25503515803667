import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { LoginState } from "../../interfaces/slicesInterface/login";
import {
  loginUser,
  autoLoginUser,
  logoutUser,
  verifyOtp,
  checkLogin,
  loginActiveTime
} from "../../services/login";
import toast from "react-hot-toast";

const initialState: LoginState = {
  isLoggedIn: false,
  isOtpTest: false,
  mfa: false,
  loginUsername: "",
  error: "",
  loading: false,
  autoLoginLoading: false,
  isComplexPassword: true,
  viaApp: false,
  token: "",
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    dispatchLogin: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    resetAutoLogin: (state) => {
      state.viaApp = false
    },
    setComplexPassword: (state,action: PayloadAction<boolean>) => {
      state.isComplexPassword = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled,  (state, action: PayloadAction<any>) => {
        if (action.payload.success) {
          state.loading = false;
          state.isLoggedIn = true;
          state.error = undefined;
          state.mfa = false;
          localStorage.setItem("token", action.payload.token);
          localStorage.setItem("webrtcipfetched", "false");
          toast.success("Logged In Successfully");
        } else {
          if (action.payload.otpverify) {
            state.mfa = true;
            state.loading = false;
            toast(action.payload.message, {
              icon: "✔",
            });
          } else {
            state.mfa = false;
            toast.error(action.payload.message);
            state.loading = false;
          }
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
        toast.error("Error in Logging");
      })
      //// LOGOUT /////
      .addCase(autoLoginUser.pending, (state) => {
        state.autoLoginLoading = true;
      })
      .addCase(autoLoginUser.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.success) {
          state.autoLoginLoading = false;
          state.isLoggedIn = true;
          state.viaApp = true;
          state.error = undefined;
          state.mfa = false;
          localStorage.setItem("token", action.payload.token);
          toast.success("Logged In Successfully");
        }else{
          state.error = action.payload.message;
          state.autoLoginLoading = false;
          state.viaApp = false;
          toast.error(action.payload.message);
        }
      })
      .addCase(autoLoginUser.rejected, (state, action) => {
        state.error = action.error.message;
        state.autoLoginLoading = false;
        state.viaApp = false;
        toast.error("Error in Auto Logging");
      })
      //// LOGOUT /////
      .addCase(logoutUser.fulfilled, (state) => {
        state.isLoggedIn = false;
        state.loading = false;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.error = action.error.message;
      })

      //// VERIFY OTP
      .addCase(verifyOtp.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(verifyOtp.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.success) {
          state.loading = false;
          state.isOtpTest = true;
          state.isLoggedIn = true;
          state.mfa = false;
          localStorage.setItem("token", action.payload.token);
          toast.success("Logged In Successfully");
        } else {
          // state.mfa = false;
          state.loading = false;
          state.error = action.payload.message;
          toast.error(action.payload.message);
        }
      })
      .addCase(verifyOtp.rejected, (state, action: any) => {
        state.loading = false;
        state.isOtpTest = false;
        state.mfa = false;
        state.error = action.error.message;
        toast.error(action.error && action.error.message);
      })

      //// CHECKLOGIN
      .addCase(checkLogin.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(checkLogin.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        if (action.payload.response.success) {
          if(action.payload.token){
            localStorage.setItem('token',action.payload.token);
          }
          state.isLoggedIn = true;
          state.error = "";
          state.mfa = false;
        } else {
          state.isLoggedIn = false;
          state.error = action.payload.message;
          toast.error(action.payload.message);
        }
      })
      .addCase(checkLogin.rejected,(state,action:any) => {
          state.loading = false;
          state.error = action.error.message;
          
      })
  },
});

export const loginSelector = (state: RootState) => state.login;
export const { dispatchLogin, resetAutoLogin , setComplexPassword} = loginSlice.actions;
export default loginSlice.reducer;
