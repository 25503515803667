export function getLevel(value: number) {
  if (value <= -100) {
    return 0;
  } else if (value > -100 && value <= -80) {
    return 1;
  } else if (value > -80 && value <= -60) {
    return 2;
  } else if (value > -60 && value <= -40) {
    return 3;
  } else if (value > -40 && value <= -20) {
    return 4;
  } else if (value > -20 && value <= -10) {
    return 5;
  } else {
    return 0; 
  }
}


export const getVolumeClasses = (value: number) => {
  const level = getLevel(value);
  if (level === 0) {
      return 'level_0'
  } else if (level === 1) {
      return 'level_1'
  } else if (level === 2) {
      return 'level_2'
  }
  else if (level === 3) {
      return 'level_3'
  }
  else if (level === 4) {
      return 'level_4'
  }
  else if (level === 5) {
      return 'level_5'
  }
}