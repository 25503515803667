import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { privateCallType } from "../../interfaces/slicesInterface/privateCall";

const initialState: privateCallType = {
  imCaller: false,
  callModalOption: {
    isCallModal: false,
    idForMakingCall: "",
    peerFirstName: "",
    callAcceptedButton: false,
    peerName: "",
    peerRemoteUserId: "",
  },
  isPrivateCallOn: false,
  openentCallerUserId: "",
  privateCallPeerOne: "",
};

const privateCallSlice = createSlice({
  name: "privateCall",
  initialState,
  reducers: {
    setCaller: (state, action: PayloadAction<any>) => {
      state.imCaller = action.payload;
    },
    setCallModal: (state, action: PayloadAction<any>) => {
      state.callModalOption = action.payload;
    },
    setIsPrivateCallOn: (state, action: PayloadAction<any>) => {
      state.isPrivateCallOn = action.payload;
    },
    setOpenentCallerUserId: (state, action: PayloadAction<any>) => {
      state.openentCallerUserId = action.payload;
    },
    setPrivateCallPeerOne: (state, action: PayloadAction<any>) => {
      state.privateCallPeerOne = action.payload;
    },
    clearPrivateCallReducerState: (state) => {
      return initialState;
    },
  },
  extraReducers(builder) {},
});

export const privateCallSelector = (state: RootState) => state.privateCall;
export const {
  setCaller,
  clearPrivateCallReducerState,
  setCallModal,
  setPrivateCallPeerOne,
  setOpenentCallerUserId,
  setIsPrivateCallOn,
} = privateCallSlice.actions;
export default privateCallSlice.reducer;
