import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { faceRegistrationState } from "../../interfaces/slicesInterface/faceRegistration";
import { RootState } from "../../app/store";
import {
  checkRegistrationStatus,
  faceRegistrationService,
} from "../../services/faceRegistration";
import toast from "react-hot-toast";

const initialState: faceRegistrationState = {
  isRejected: false,
  isRegistered: false,
  isVerified: false,
  error: "",
  faceRegisterCount: 0,
  loading: false,
};

const faceRegistrationSlice = createSlice({
  name: "faceRegistration",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(faceRegistrationService.pending, (state) => {
      state.loading = true;
      state.isRegistered = false;
      state.isRejected = false;
      state.isVerified = false;

    });
    builder.addCase(
      faceRegistrationService.fulfilled,
      (state, action: PayloadAction<any>) => {
        if (action.payload.success) {
          state.loading = false;
          state.isRegistered = true;
          state.isVerified = false;
          state.error = ""
          state.isRejected = false;
        } else if (
          action.payload.is_registered.toString() === "false" &&
          action.payload.errors
        ) {
          state.loading = false;
          state.error = action.payload.errors;
          state.isRegistered = false;
          Object.keys(action.payload?.errors).forEach((key: string) => {
            toast.error(action.payload?.errors[key]);
          });
        } else {
          state.loading = false;
          state.isRegistered = false;
          state.error = action.payload.message;
        }
      }
    );
    builder.addCase(
      faceRegistrationService.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
        state.isRegistered = false;
        
      }
    );

    builder.addCase(checkRegistrationStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      checkRegistrationStatus.fulfilled,
      (state, action: PayloadAction<any>) => {
        if (action.payload.success) {
          state.loading = false;
          state.isRegistered =
            action.payload.faceVerificationUserDetails.isRegistered;
          state.isRejected =
            action.payload.faceVerificationUserDetails.isRejected;
          state.isVerified =
            action.payload.faceVerificationUserDetails.isVerified;
        }
      }
    );
    builder.addCase(
      checkRegistrationStatus.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      }
    );
  },
});

export const faceRegistrationSelector = (state: RootState) =>
  state.faceRegistration;
export default faceRegistrationSlice.reducer;
