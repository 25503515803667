import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PeerVolumeAction,
  PeerVolumeState,
} from "../../interfaces/slicesInterface/peerVolume";
import { RootState } from "../../app/store";
const initialState: PeerVolumeState = {};

const peerVolumeSlice = createSlice({
  name: "peerVolume",
  initialState,
  reducers: {
    setPeerVolume: (state, action: PayloadAction<PeerVolumeAction>) => {
      const peerUserId = action.payload.peerUserId;
      const dBs = !action.payload.volume ? -100 :   action.payload.volume < -100 ? -100 : action.payload.volume;
      state[peerUserId] = dBs;
    },
    clearPeerVolume:(state) => {
      return initialState
    }
  },
});

export const { setPeerVolume, clearPeerVolume } = peerVolumeSlice.actions;
export default peerVolumeSlice.reducer;
export const peerVolumeSelector = (state:RootState) => state.peerVolume
