import moment from "moment-timezone";
import { formats, timezoneLocaleMap } from "../../constants/datetimemap";

export const timeZoneFormat = () => {
  const targetTimezone = moment.tz.guess();

  // Get the current date and time in the target timezone
  // const currentTime = moment().tz(targetTimezone);

  // Adjust the locale dynamically based on the target timezone
  moment.locale(moment.tz(targetTimezone).locale());

  // Determine the format based on the target timezone and locale
  let format, code;
  code = timezoneLocaleMap[targetTimezone];
  format = getLocaleDateString(code);

  // Display or use the formatted date and time
  return { format, targetTimezone };
};

function getLocaleDateString(format: string) {
  return formats[format] || "DD/MM/YYYY  HH:mm:ss A";
}

export function capitalizeWords(str: string): string {
  return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
}


/**
 * **********
 * @param startedAT Accepts in HH:MM am/pm Format
 * @returns time difference between provided time & current time
 * *********
 */

export const getMinuteDifference = (startedAT: any) => {
    let timeNow = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

    // start time and end time
    let startTime = moment(startedAT, 'HH:mm a');
    let endTime = moment(timeNow, 'HH:mm a');

    // calculate total duration
    let duration = moment.duration(endTime.diff(startTime));

    // duration in minutes
    //@ts-ignore
    let difference = moment.utc(duration.asMilliseconds()).format("HH:mm")
    //parseInt(duration.asMinutes()) % 60;
    return difference;
}

/**
 * Converts a time string in the format "HH:MM" to its equivalent in minutes.
 *
 * @param {string} time - A string representing time in "HH:MM" format.
 * @returns {number} The total minutes representation of the provided time.
 *
 * @example
 * timeToMinutes("01:30"); // Returns 90
 * timeToMinutes("02:15"); // Returns 135
 */
export const timeToMinutes = (time: string) => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
}

/**
 * Formats a given time string to represent minutes.
 *
 * @param {string} time - A string representing time in the format "HH:MM".
 * @param {any} index - The position of a character in the `time` string to be replaced with `char`.
 * @param {string} char - The character that will replace the character in the `time` string at the specified `index`.
 *
 * @returns {string} A formatted string representing minutes.
 *
 * @example
 * getFormattedMinutes("00:25", 2, "-")  // Returns "25 Min"
 * getFormattedMinutes("01:25", 2, "Hour")  // Returns "01 Hour 25 Min"
 *
 * @note
 * - Assumes the input `time` string is always in the "HH:MM" format.
 * - Behavior might be unexpected if `index` is not a valid position in the `time` string or if `char` is more than one character long.
 */
export const getFormattedMinutes = (time: string, index: any, char: string) => {
  if (time.substring(0, 2) === '00') {
      let min = time.substring(3, 5);
      if (min === '00') {
          return "0 Min";
      }
      let formattedMin = min === '01' ? min + ' Min' : min + ' Min';
      return formattedMin;
  } else {
      return time.replace(/./g, (c, i) => i === index ? char : c) + " Min";
  }
}


