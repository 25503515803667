import { createAsyncThunk } from "@reduxjs/toolkit";
import { authRequest } from "./index";
import {
  geoLocationInterface,
  LoginParamsDataType,
  SSOData,
  VerifyOTPParamsDataType,
} from "../interfaces/slicesInterface/login";
import { getToken } from "../utils/Token";
import { getUsersLocation } from "../utils/location";
import { getEnvironmentByCustCode } from "./environment";
import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};
const configuration = {
  headers: {
    Authorization: "",
  },
};

const getUserLocation = async () => {
  var geoLocationDetails: any = {};
  try {
    let geoRes: any = await getUsersLocation();
    geoLocationDetails = {
      latitude: geoRes?.coords?.latitude,
      longitude: geoRes?.coords?.longitude,
      fromApi: false,
    };
    // geo = true;
  } catch (err) {
    geoLocationDetails = {
      latitude: "",
      longitude: "",
      fromApi: false,
    };
  }

  return geoLocationDetails;
};

const getGeolocationResponse = async (data: geoLocationInterface) => {
  const envResponse = await getEnvironmentByCustCode({
    variable: "IPADDRESS",
    custCode: data.custCode,
  });
  let resIp: any = "";
  let ipAddressval = "";
  if (envResponse.success && envResponse.envData.status) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ABSTRACTAPI_BASE_URL}?api_key=${process.env.REACT_APP_ABSTRACTAPI_KEY}`,
        { timeout: 2000 }
      );
      console.log({response})
      ipAddressval = response?.data?.ip_address;
      resIp = response?.data;
    } catch (error) {
      console.error("Error fetching the data:", error);
    }
  }
  return { ipAddressval, resIp };
};

export const exportGeoLocationForSSO = async(data:any) => {

  const config = {
    headers: {
      "Content-Type": "application/json",
       Authorization : getToken(),
    },
  };

  try {
      const geoLocations = await getUserLocation();
      const { ipAddressval, resIp } = await getGeolocationResponse({
        custCode: data.custCode,
      });
      let payloadBody = {
        ipAddress: ipAddressval,
        ipData: {data:resIp},
        latitude: geoLocations && geoLocations.latitude,
        longitude: geoLocations && geoLocations.longitude,
      };
      const res = await authRequest.post("/v1/api/userlocation/savelocationforsamlokta", payloadBody, config);
      localStorage.setItem("webrtcipfetched", "false");
      return res.data;
    } catch (err: unknown | any) {
      throw new Error(`Error Save GeoLocation ${err.message}`);
  }
}

export const loginUser = createAsyncThunk(
  "login/loginUser",
  async (data: LoginParamsDataType) => {
    try {
      const geoLocations = await getUserLocation();
      const { ipAddressval, resIp } = await getGeolocationResponse({
        custCode: data.custCode,
      });
      console.log({resIp})

      let payloadBody = {
        custCode: data.custCode,
        username: data.username,
        password: data.password,
        ipAddress: ipAddressval,
        ipData: {data:resIp},
        latitude: geoLocations && geoLocations.latitude,
        longitude: geoLocations && geoLocations.longitude,
        fromApi: geoLocations.fromApi ? geoLocations.fromApi : false,
      };

      const res = await authRequest.post("/v1/api/login", payloadBody, config);
      return res.data;
    } catch (err: unknown | any) {
      throw new Error(`Error Logging User ${err.message}`);
    }
  }
);

export const autoLoginUser = createAsyncThunk(
  "login/autoLogin",
  async (data: LoginParamsDataType) => {
    try {
      const res = await authRequest.post("/v1/api/autologin", data, config);
      return res.data;
    } catch (err: unknown | any) {
      throw new Error(`Error Auto logging User ${err.message}`);
    }
  }
);

export const loginActiveTime = createAsyncThunk(
  "login/loginActiveTime",
  async () => {
    configuration["headers"]["Authorization"] = getToken();
    try {
      const res = await authRequest.get(
        "/v1/api/user/logintime/save",
        configuration
      );
      return res.data;
    } catch (err: unknown | any) {
      throw new Error(`Error Saving logging time for User ${err.message}`);
    }
  }
);

export const logOutActiveTime = createAsyncThunk(
  "login/logOutActiveTime",
  async () => {
    configuration["headers"]["Authorization"] = getToken();
    try {
      const res = await authRequest.get(
        "/v1/api/user/logouttime/save",
        configuration
      );
      return res.data;
    } catch (err: unknown | any) {
      throw new Error(`Error Saving logging out time for User ${err.message}`);
    }
  }
);
export const logoutUser = createAsyncThunk("login/logoutUser", async () => {
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("agentstatus");
    return Promise.resolve("Logged Out");
  } catch (err: unknown | any) {
    throw new Error(`Error in Logging Out ${err.message}`);
  }
});

export const verifyOtp = createAsyncThunk(
  "login/verifyOtp",
  async (data: VerifyOTPParamsDataType) => {
    try {
      const res = await authRequest.post("/v1/api/verifyotp", data, config);
      return res.data;
    } catch (err: unknown | any) {
      throw new Error(`Error Verifying OTP ${err.message}`);
    }
  }
);

export const checkLogin = createAsyncThunk(
  "login/checkLogin",
  async (token?: string) => {
    configuration["headers"]["Authorization"] = token
      ? `Bearer ${token}`
      : getToken();

    try {
      const res = await authRequest.get(
        "/v1/api/user/check-login",
        configuration
      );
      return { response: res.data, token };
    } catch (err: unknown | any) {
      throw new Error(`Error Checking OTP ${err.message}`);
    }
  }
);

export const fetchSaml = async (data: SSOData) => {
  try {
    const res = await authRequest.post("/v1/api/login/fetch-saml", data);
    return res.data;
  } catch (err: unknown | any) {
    throw new Error(`Error Checking OTP ${err.message}`);
  }
};
