import axios from "axios";

// Media
export const mediaRequest = axios.create({
  baseURL: process.env.REACT_APP_MEDIA_URL,
  withCredentials: true,
});

// Room
export const roomRequest = axios.create({
  baseURL: process.env.REACT_APP_ROOM_URL,
  withCredentials: true,
});

// Utils
export const utilsRequest = axios.create({
  baseURL: process.env.REACT_APP_UTILS_URL,
  withCredentials: true,
});

// Chat
export const chatRequest = axios.create({
  baseURL: process.env.REACT_APP_CHAT_URL,
  withCredentials: true,
});

// Desktop Request
export const desktopAppRequest = axios.create({
  baseURL: process.env.REACT_APP_DESKTOP_APP_URL,
  withCredentials: true,
});

// Whiteboard
export const whiteboardRequest = axios.create({
  baseURL: process.env.REACT_APP_WHITEBOARD_URL,
  withCredentials: true,
});

// Auth
export const authRequest = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  withCredentials: true,
});

export const activityChannelRequest = axios.create({
  baseURL: process.env.REACT_APP_ACTIVITY_CHANNEL_URL,
  withCredentials: true,
});

export const faceRequest = function (url: string) {
  return axios.create({
    baseURL: url,
    withCredentials: true,
  });
};
