// DynamicButton.tsx
import React, { ReactNode, MouseEvent } from 'react';

interface DynamicButtonProps {
  type?: 'primary' | 'secondary' | 'outline';
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
  className?: string;
  Icon?: any;
  style?: Object;
  disabled?: boolean;
}

export default function Button({
  type = 'secondary',
  onClick,
  children,
  className = '',
  Icon,
  style = {},
  disabled,
}: DynamicButtonProps): ReturnType<React.FC> {
  const baseClasses = 'py-2 px-4 rounded focus:outline-none';

  let buttonClasses = '';

  switch (type) {
    case 'primary':
      buttonClasses = 'bg-blue text-white hover:bg-blue';
      break;
    case 'secondary':
      buttonClasses = 'bg-gray-500 text-white';
      break;
    case 'outline':
      buttonClasses = 'bg-transparent text-black border-[1px] border-gray-300';
      break;
    default:
      buttonClasses = 'bg-gray-500 text-white hover:opacity-5';
  }

  return (
    <button
      style={style}
      className={`${baseClasses} ${buttonClasses} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {Icon && (
        <span className="mr-2 h-5 w-5">
          <Icon />
        </span>
      )}
      {children}
    </button>
  );
}
