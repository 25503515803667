export default function generateUrl(
  username: string,
  custId: string,
  spacesConfig: any,
  companyName: string
) {
  return `https://${spacesConfig.spacesBucket}.${spacesConfig.endpoint}/facetest/${custId}/${companyName}/${username}.jpeg`;
}

export function generateThumbnailsUrl(
  imageName: string | any,
  size: number,
  custId: string | null | any,
  userId: string | null | any,
  spacesConfig: any
) {
  return `https://${spacesConfig.spacesBucket}.${spacesConfig.endpoint}/personalization/${custId}/${userId}/thumbnails/${size}/${imageName}`;
}
