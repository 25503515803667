import { getToken } from "../utils/Token";
import { mediaRequest, chatRequest, desktopAppRequest } from "./index";

const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
    },
};

export const getConnectionDetailsForChat = async (
    roomName: string,
    roomId: string,
    popupOpenedFrom?: string
) => {
    config["headers"]["Authorization"] = getToken();
    const reqBody = {
        roomName,
        roomId,
        popupOpenedFrom
    };

    const connectionDetailsData = await chatRequest.post(
        "/v1/api/getConnectionDetailsforchat",
        reqBody,
        config
    );

    return connectionDetailsData?.data?.connectionDetails;
};

export const getConnectionDetailsForMedia = async (
    roomName: string,
    roomId: string,
) => {
    config["headers"]["Authorization"] = getToken();
    const reqBody = {
        roomName,
        roomId,
    };

    const connectionDetailsData = await mediaRequest.post(
        "/v1/api/media/get-connection-details",
        reqBody,
        config
    );

    return connectionDetailsData?.data?.connectionDetails;
};


export const getConnectionDetailsForDesktopApp = async (
    roomName: string,
    roomId: string
) => {
    config["headers"]["Authorization"] = getToken();
    const reqBody = {
        roomName,
        roomId,
    };

    const connectionDetailsData = await desktopAppRequest.post(
        "/v1/api/get-connection-details",
        reqBody,
        config
    );

    return connectionDetailsData?.data?.connectionDetails;
}