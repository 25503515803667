"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var sdk_1 = require("./sdk");
var WatchRTCHttp = /** @class */ (function () {
    function WatchRTCHttp() {
        if (WatchRTCHttp._instance) {
            (0, sdk_1.sdkLog)("info", "WatchRTCSocket instance already created");
        }
        else {
            WatchRTCHttp._instance = this;
        }
    }
    WatchRTCHttp.prototype.trace = function (url, sessionId, projectId, rtcRoomId, rtcPeerId) {
        var data = [];
        for (var _i = 5; _i < arguments.length; _i++) {
            data[_i - 5] = arguments[_i];
        }
        var args = Array.prototype.slice.call(data);
        args.push(Date.now());
        if (args[1] instanceof RTCPeerConnection) {
            args[1] = args[1].__rtcStatsId;
        }
        var response = fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                sessionId: sessionId,
                projectId: projectId,
                rtcRoomId: rtcRoomId,
                rtcPeerId: rtcPeerId,
                data: data,
            }),
        });
        return response
            .then(function () { return ({}); })
            .catch(function (err) {
            (0, sdk_1.sdkLog)("error", err.message, { err: err.stack });
            return { error: err };
        });
    };
    return WatchRTCHttp;
}());
exports.default = WatchRTCHttp;
