import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { observeType } from "../../interfaces/slicesInterface/observe";

const initialState: observeType = {
  openentObserverAudioId: "",
  openentObserveeAudioId: "",
  observerModeOn: false,
};

const observeSlice = createSlice({
  name: "observe",
  initialState,
  reducers: {
    setOpenentObserverAudioId: (state, action: PayloadAction<any>) => {
      state.openentObserverAudioId = action.payload;
    },
    setOpenentObserveeAudioId: (state, action: PayloadAction<any>) => {
      state.openentObserveeAudioId = action.payload;
    },
    setObserverModeOn: (state, action: PayloadAction<any>) => {
      state.observerModeOn = action.payload;
    },
    clearObserveReducer: (state) => {
      return initialState;
    },
  },
  extraReducers(builder) {},
});

export const observeSelector = (state: RootState) => state.observe;
export const {
  setOpenentObserverAudioId,
  setOpenentObserveeAudioId,
  setObserverModeOn,
  clearObserveReducer,
} = observeSlice.actions;
export default observeSlice.reducer;
