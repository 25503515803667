import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { fileStreamInterface } from "../../interfaces/slicesInterface/localFileStream";

const initialState: fileStreamInterface = {
  isAudioFile: false,
  isVideoFile: false,
  localAudioFilePause: undefined,
  localScreenFileStopped: undefined
};

const localFileStreamSlice = createSlice({
  name: "localfilestreamstatus",
  initialState,
  reducers: {
    setAudioFile: (state, action: PayloadAction<any>) => {
      state.isAudioFile = action.payload;
    },
    setVideoFile: (state, action: PayloadAction<any>) => {
      state.isVideoFile = action.payload;
    },
    clearLocalFileStream: (state) => {
      return initialState;
    },
    setLocalAudioFilePaused: (state, action: PayloadAction<any>) => {
      state.localAudioFilePause = action.payload;
    },
    setLocalScreenFileStopped: (state, action: PayloadAction<any>) => {
      state.localScreenFileStopped = action.payload;
    },

  },
  extraReducers(builder) {},
});

export const localFileStreamSelector = (state: RootState) =>
  state.localfilestreamstatus;
export const { setAudioFile, setVideoFile, clearLocalFileStream, setLocalAudioFilePaused, setLocalScreenFileStopped } =
  localFileStreamSlice.actions;
export default localFileStreamSlice.reducer;
