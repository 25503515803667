import React from "react";
import { PencilIcon } from "@heroicons/react/24/outline";

const PencilIconComponent = () => {
    return (
        <div>
            <PencilIcon className="h-6 w-6" />
        </div>
    );
};

export default PencilIconComponent;
