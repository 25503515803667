import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  EventType,
  Alert,
  AlertsState,
} from "../../interfaces/slicesInterface/alerts";

// Initial state
const initialState: AlertsState = {
  alerts: {},
  notificationCounter: 0,
};

const generateKey = (
  userId: string,
  event: EventType,
  startTime: string | number
): string => {
  return `${userId}_${event}_${startTime}`;
};

const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    addEvent: (state, action: PayloadAction<Alert>) => {
      const { userId, event, startTime, timestamp, peerName, peerUsername,message } =
        action.payload;
      const key = generateKey(userId, event, startTime);
      state.alerts = {
        [key]: { startTime, timestamp, peerName, peerUsername,message },
        ...state.alerts,
      };
      state.notificationCounter = state.notificationCounter + 1;
    },
    updateEvent: (
      state,
      action: PayloadAction<{
        userId: string;
        event: EventType | string;
        endTime: string | number;
      }>
    ) => {
      const { userId, event, endTime } = action.payload;
      const key = Object.keys(state.alerts).find(
        (k) => k.startsWith(`${userId}_${event}`) && !state.alerts[k].endTime
      );
      if (key) {
        state.alerts[key].endTime = endTime;
      }
    },
    setNotificationCounter : (state, action: PayloadAction<any>) => {
      state.notificationCounter = action.payload;
    },
    clearAlerts: () => {
      return initialState;
    },
  },
});

export const { addEvent, updateEvent, clearAlerts, setNotificationCounter } =
  alertsSlice.actions;
export const alertsSelector = (state: RootState) => state.alerts;
export default alertsSlice.reducer;
