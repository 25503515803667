import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BreakParticipant } from "../../interfaces/slicesInterface/tempRoomUser";
import { RootState } from "../../app/store";

const initialState = {
  breakParticipants: [] as Array<BreakParticipant>,
};

const tempRoomUserSlice = createSlice({
  name: "tempRoomUser",
  initialState,
  reducers: {
    setTempRoomUser: (state, action: PayloadAction<BreakParticipant>) => {
      console.log({user:action.payload})
      let participantIndex = state.breakParticipants.findIndex(
        (val: BreakParticipant) => val.userId === action.payload.userId
      );

      if (participantIndex !== -1) {
        state.breakParticipants.splice(participantIndex, 1);
      } else {
        state.breakParticipants.push(action.payload);
      }
    },

    clearTempRoomUser:(state) => {
      state.breakParticipants = [];
    }
  },
});

export const tempRoomUserSelector = (state:RootState) => state.tempRoomUser
export const { setTempRoomUser,clearTempRoomUser } = tempRoomUserSlice.actions;
export default tempRoomUserSlice.reducer;

