import React from "react";
import styles from './styles.module.css'
export default function Loader(): ReturnType<React.FC> {
    return (
        <>

            <div className="w-screen h-screen bg-white flex justify-center items-center">
                <div style={{
                    WebkitMask: 'radial-gradient(farthest-side, #0000 calc(100% - 16px), #000 0)'
                }} className={styles.custom_loader} ></div>
            </div>



        </>
    )
}