import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import PrivateRoute from "../components/PrivateRoute";
import routes from "../constants/routes";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import FaceRegistration from "../components/FaceRegistration";
import ErrorPage from "../pages/ErrorPage";
import Room from "../pages/Room";
import Chats from "../components/Chats";
import Alerts from "../components/Alerts";
import Participants from "../components/Participants";
import PasswordReset from "../pages/PasswordReset";
import PasswordChange from "../pages/PasswordChange";
import AdminVerification from "../components/AdminVerification";
import UserPersonalization from "../components/UserPersonalization";
import { checkLogin } from "../services/login";
import { dispatchLogin, loginSelector } from "../features/login/loginSlice";
import Whiteboard from "../components/Whiteboard";
import SSO from "../components/SSO";
import AutoLogin from "../components/AutoLogin";
import LaunchApp from "../pages/DownloadApp/launchApp";

// import Loader from "../components/Loader";

export default function AppRouter(): ReturnType<React.FC> {
  const dispatch = useAppDispatch();
  const loginState = useAppSelector(loginSelector);

  useEffect(() => {
    dispatch(checkLogin());
    // localStorage.getItem('token') ? dispatch(checkLogin(true)) : dispatch(checkLogin(false))
  }, [dispatch]);

  useEffect(() => {
    if (!localStorage.getItem("token") && !loginState.isLoggedIn) {
      dispatchLogin(false);
    }
  }, [loginState]);

  return (
    <Routes>
      <Route path="*" element={<ErrorPage />} />
      <Route path="/" element={<Navigate to={routes.login} replace={true} />} />
      <Route path={routes.sso} element={<SSO />} />
      <Route path={routes.autologin} element={<AutoLogin />} />
      <Route path={routes.login} element={<Login />} />
      <Route
        path={routes.dashboard}
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path={routes.faceRegistration}
        element={
          <PrivateRoute>
            <FaceRegistration />
          </PrivateRoute>
        }
      />
      <Route
        path={`${routes.launchApp}/:roomId/:roomname`}
        element={
          
            <LaunchApp />
          
        }
      />
      <Route
        path={routes.adminverification}
        element={
          <PrivateRoute>
            <AdminVerification />
          </PrivateRoute>
        }
      />
      <Route
        path={`${routes.room}/:roomName`}
        element={
          <PrivateRoute>
            <Room />
          </PrivateRoute>
        }
      />
      <Route
        path={routes.personalization}
        element={
          <PrivateRoute>
            <UserPersonalization />
          </PrivateRoute>
        }
      />
      <Route path={`${routes.chat}`} element={<Chats />} />
      <Route path={`${routes.alerts}`} element={<Alerts />} />
      <Route path={`${routes.participants}`} element={<Participants />} />
      <Route path={routes.passwordReset} element={<PasswordReset />} />
      <Route
        path={routes.passwordChange}
        element={
          <PrivateRoute>
            <PasswordChange />
          </PrivateRoute>
        }
      />
      <Route
        path={`${routes.whiteboard}/:roomname/:roomId`}
        element={<Whiteboard />}
      />
    </Routes>
  );
}
