import React, { useEffect, useState } from "react";
import { SkeletonLoadingProps } from "../../interfaces/componentsInterface/skeletonLoading";

export default function SkeletonLoading(props: SkeletonLoadingProps): ReturnType<React.FC> {
    const [value, setValue] = useState<number[] | Array<any>>([]);


    const randomIntArrayInRange = (n = 1) =>
        Array.from({ length: n }, () => Math.floor(Math.random() * (100 - 1 + 1)) + 1);

    useEffect(() => {
        let arr = randomIntArrayInRange(30);
        setValue(arr);
    }, []);

    useEffect(() => {
        return () => {
            setValue([]);
        };
    }, []);

    useEffect(() => {
        let arr = randomIntArrayInRange(30);
        setValue(arr);
    }, [])

    useEffect(() => {
        return () => {
            setValue([]);
        }
    }, [])

    return (
        <>
            {props.component === "Dashboard" &&
                value.map((val) => (
                    <div
                        key={val}
                        className="border border-slate-200 shadow rounded-md p-4  w-[32%] "
                    >
                        <div className="animate-pulse flex space-x-4">
                            <div className="rounded-full bg-slate-200 h-10 w-10"></div>
                            <div className="flex-1 space-y-6 py-1">
                                <div className="h-2 bg-slate-200 rounded"></div>
                                <div className="space-y-2">
                                    <div className="grid grid-cols-3 gap-4">
                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                    </div>
                                    <div className="h-2 bg-slate-200 rounded"></div>
                                    <div className="h-2 bg-slate-200 rounded"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            {props.component === "Chat" &&
                value.map((val) => (
                    <div
                        key={val}
                        className="border border-slate-200 shadow rounded-md p-4  w-full "
                    >
                        <div className="animate-pulse flex space-x-4">
                            <div className="rounded-full bg-slate-200 h-10 w-10"></div>
                            <div className="flex-1 space-y-6 py-1">
                                <div className="h-2 bg-slate-200 rounded"></div>
                                <div className="space-y-2">
                                    <div className="grid grid-cols-3 gap-4">
                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                    </div>
                                    <div className="h-2 bg-slate-200 rounded"></div>
                                    <div className="h-2 bg-slate-200 rounded"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

            {props.component === "Feeds" &&
                value.map((val) => (
                    <div
                        key={val}
                        className="border border-slate-200 shadow rounded-md p-4  w-full my-1 "
                    >
                        <div className="animate-pulse flex space-x-4">
                            <div className="rounded-full bg-slate-200 h-10 w-10"></div>
                            <div className="flex-1 space-y-6 py-1">
                                <div className="space-y-2">
                                    <div className="grid grid-cols-3 gap-4">
                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                    </div>
                                    <div className="grid grid-cols-3 gap-4">
                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                    </div>
                                    <div className="h-2 bg-slate-200 rounded"></div>
                                    <div className="h-2 bg-slate-200 rounded"></div>
                                    <div className="h-2 bg-slate-200 rounded"></div>
                                    <div className="h-2 bg-slate-200 rounded"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

            {props.component === "Topics" &&
                value.map((val) => (
                    <div
                        key={val}
                        className="border border-slate-200 shadow rounded-md p-4  w-full my-1 "
                    >
                        <div className="animate-pulse flex space-x-4">
                            <div className="rounded-full bg-slate-200 h-10 w-10"></div>
                            <div className="flex-1 space-y-6 py-1">
                                <div className="space-y-2">
                                    <div className="grid grid-cols-3 gap-4">
                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                    </div>
                                    <div className="grid grid-cols-3 gap-4">
                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            {props.component === "Alerts" &&
                value.map((val) => (
                    <div
                        key={val}
                        className="border border-slate-200 shadow rounded-md p-4  w-full "
                    >
                        <div className="animate-pulse flex space-x-4">
                            <div className="flex-1 space-y-6 py-1">
                                <div className="space-y-2">
                                    <div className="grid grid-cols-3 gap-4">
                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                    </div>
                                    <div className="grid grid-cols-3 gap-4">
                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
        </>
    );
}
