export const checkForSupManAdmin = (roleParam: string, check: string) => {
    if (roleParam && roleParam.length > 0) {
      let role = roleParam.toLowerCase();
      if (check === "equalTo") {
        if (
          role === "supervisor" ||
          role === "manager" ||
          role === "admin" ||
          role === "director"
        ) {
          return true;
        } else {
          return false;
        }
      } else if (check === "notEqualTo") {
        if (
          role !== "supervisor" &&
          role !== "manager" &&
          role !== "admin" &&
          role !== "director"
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
  };