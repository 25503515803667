import { getToken } from "../utils/Token";
import {activityChannelRequest} from ".";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface FeedsParams {
  topicIds: string[],
  pageNo: Number,
  myPosts: boolean
}

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: getToken(),
  },
};


export const getSubscribedTopics = createAsyncThunk(
  "activityChannel/getSubscribedTopics",
  async () => {
    config["headers"]["Authorization"] = getToken();

    const apiResponse = await activityChannelRequest.get(
      "/v1/api/topics/get-subscribed-topics",
      config
    );
    return apiResponse.data;
  }
);

export const getAllTopics = async () => {
  config["headers"]["Authorization"] = getToken();
  const apiResponse = await activityChannelRequest.get("/getTopics", config);
  return apiResponse.data;
};


export const subscribeTopic = async (username: string, topicId: any) => {
  config["headers"]["Authorization"] = getToken();
  const reqBody = {
    username,
    topicId,
  };

  const apiResponse = await activityChannelRequest.post(
    "/v1/api/subscribers/subscribe",
    reqBody,
    config
  );
  return apiResponse.data;
};

export const unsubscribeTopic = async (username: string, topicId: any) => {
  config["headers"]["Authorization"] = getToken();
  const reqBody = {
    username,
    topicId,
  };

  const apiResponse = await activityChannelRequest.post(
    "/v1/api/subscribers/unsubscribe",
    reqBody,
    config
  );
  return apiResponse.data;
};


export const getAllFeeds = createAsyncThunk(
  "activityChannel/getAllFeeds",
  async (data:FeedsParams) => {
    config["headers"]["Authorization"] = getToken();
  const apiResponse = await activityChannelRequest.post(
    "/v1/api/feeds/get",
    data,
    config
  );
  return apiResponse.data;
  }
);

export const getAllTopicsExtended = createAsyncThunk(
  "activityChannel/getAllTopicsExtended",
  async () => {
    config["headers"]["Authorization"] = getToken();

    const apiResponse = await activityChannelRequest.get(
      "/v1/api/topics/get-all-topics-extended",
      config
    );
    return apiResponse.data;
  }
);


export const editFeed = async (feedId: string,topicId:string,title:string,description:string, type:string) => {
  config['headers']['Authorization'] = getToken();
    let body = {
      _id: feedId,
      topicId,
      title,
      description,
      type
    };

    const feedResponse = await activityChannelRequest.put("/v1/api/feeds/update", body, config);
    return feedResponse.data;
};

export const updateFile = async (formData:any) => {
  config['headers']['Authorization'] = getToken();
    const feedResponse = await activityChannelRequest.post("/v1/api/feeds/upload", formData, config);
    return feedResponse.data;
};



export const getConnectionDetailsForActivityChannel = async (username: string) => {
  config["headers"]["Authorization"] = getToken();
  // const body = {
  //   username,
  // };

  const connectionDetailsData = await activityChannelRequest.get(
    "/v1/api/get-connection-details",
    config
  );

  return connectionDetailsData?.data?.connectionDetails;
};