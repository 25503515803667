import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import Loader from "../Loader";
import { loginSelector } from '../../features/login/loginSlice'



export default function PrivateRoute({ children }: any): ReturnType<React.FC> {

    const loginState = useAppSelector(loginSelector);


    return loginState.loading ? (
        <Loader />
    ) : !loginState.isLoggedIn && !loginState.loading ? (
        <Navigate to='/login' replace={true} />
    ) : loginState.isLoggedIn ? (
        <>
            {children}
        </>
    ) : (<></>)
};