import { createAsyncThunk } from "@reduxjs/toolkit";
import { authRequest } from ".";
import { BackgroundParams, UserDetailsParams } from "../interfaces/slicesInterface/userDetails";
import {getToken} from "../utils/Token";


const config = {
  headers: {
    "Content-Type": "application/json",
    "Authorization": ""
  },
};
export const getUserDetails = createAsyncThunk(
  "user/getUserDetails",
  async (data: UserDetailsParams) => {
    config["headers"]["Authorization"] = getToken();
    const response = await authRequest.get("/v1/api/user/get", config);
    return response.data; 
  }
);

export const getAllSupervisorForManager = createAsyncThunk (
  "user/getAllSupervisorForManager",
  async () => {
    config["headers"]["Authorization"] = getToken();
    const response = await authRequest.get("/v1/api/user/get/supervisor-of-manager", config);
    return response.data; 
  }
);


export const getBackgroundActivate = createAsyncThunk(
  "user/getBackgroundActivate",
  async () => {
    config["headers"]["Authorization"] = getToken();
    const response = await authRequest.get("/v1/api/background/get", config);
    return response.data;
  }
);
export const saveBackgroundActivate = createAsyncThunk(
  "user/saveBackgroundActivate",
  async (data: BackgroundParams) => {
    config["headers"]["Authorization"] = getToken();
    const response = await authRequest.post(
      "/v1/api/background/save",
      data,
      config
    );
    return response.data;
  }
);
