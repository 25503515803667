import React, { useEffect } from 'react';
import Loader from '../Loader';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import useQuery from '../../hooks/useQuery';
import routes from '../../constants/routes';
import { checkLogin, exportGeoLocationForSSO } from '../../services/login';
import toast from 'react-hot-toast';
import { getAudioPermission, getVideoPermission } from '../../utils/AudioVideoDevices';

const SSO = () => {
  const queryParams = useQuery();

  let username = queryParams.get('username');
  let custCode = queryParams.get('custCode');
  let token = queryParams.get('token');
  let message = queryParams.get('m');
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isLoggedIn = useAppSelector((state: RootState) => state.login.isLoggedIn);
  const autoLoginLoading = useAppSelector((state: RootState) => state.login.autoLoginLoading);
  const error = useAppSelector((state: RootState) => state.login.error);

  useEffect(() => {
    if (token) {
      dispatch(checkLogin(token))
      localStorage.setItem('isSamlLogin', 'true');
    } else if(message) {
      navigate('/');
      toast.error(message);
    }
  }, [dispatch, username, custCode]);


  useEffect(() => {
    const requestAudioDevicePermissions = async () => {
      try {

        await getAudioPermission();
        await getVideoPermission();
      } catch (err) {
        toast.error("Please provide mic and video access");
        console.error(err);
      }
    }; 
    requestAudioDevicePermissions();
  }, []);
  

  if (isLoggedIn) {
    return <Navigate to={routes.dashboard} replace />;
  }

  if (error && !autoLoginLoading) {
    return <Navigate to={routes.login} replace />;
  }

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="pt-10">
        <h1 className='text-center'>
          Welcome to <br/><b className='text-[25px] font-semibold text-[#9ABCFF]'>CollaborationRoom</b>
        </h1>
      </div>
      <div className="">
        <h4>Please wait while we log you into the application</h4>
      </div>
      <Loader />
    </div>
  );
};

export default SSO;
