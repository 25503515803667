import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../DashboardLayout";
import styles from './styles.module.css'
import { PhotoIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Button from "../Button";
import { getPersonalizationSettings, savePersonalizationSettings } from "../../services/personalization";
import { PersonalizationParams } from "../../interfaces/slicesInterface/personalization";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getVideoStream } from "../../utils/AudioVideoDevices";
import { Options, Category, CheckboxItemProps } from "../../interfaces/componentsInterface/personalization";
import Video from "../Video";
import { Switch } from '@headlessui/react'
import { deviceSelector } from "../../features/device/deviceSlice";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import dataURItoBlob from "../../utils/dataUriToBlob";
import { personalizationSelector } from "../../features/personalization/personalization";
import WarningModal from "../WarningModal";
import toast from "react-hot-toast";

// import { UserIcon } from "@heroicons/react/20/solid";

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}


const CheckboxItem: React.FC<CheckboxItemProps> = ({ label, value, checked, onChange }) => {
    return (
        <div className="flex items-between">
            <div className="flex justify-between h-6 items-center">
                <input
                    id="desktop"
                    aria-describedby="desktop-description"
                    name="desktop"
                    type="checkbox"
                    checked={checked}
                    onChange={() => onChange(value)}
                    className="h-4 w-4 rounded border-gray-300 text-blue focus:ring-blue"
                />
            </div>
            <div className="ml-3 text-sm leading-6">
                <label htmlFor="desktop" className="font-medium text-gray-900">
                    {label}
                </label>

            </div>
        </div>
    );
};



export default function UserPersonalization(): ReturnType<React.FC> {

    const dispatch = useAppDispatch()
    const devices = useAppSelector(deviceSelector);
    const personalization = useAppSelector(personalizationSelector);
    // const user = useAppSelector(userDetailsSelector)
    const [file, setFile] = useState<File | null>(null);
    const [image, setImg] = useState<string>("")
    const [imageDelete, setImageDelete] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [options, setOptions] = useState<Options>({
        chat: {
            audio: false,
            toast: false,
            desktop: false,
        },
        activity: {
            audio: false,
            toast: false,
            desktop: false,
        },
        mentions: {
            audio: false,
            toast: false,
            desktop: false,
        },
    });
    const [enabled, setEnabled] = useState(false)
    const isInitialized = useRef(false);
    const [isWarn, setIsWarn] = useState(false);
    const [localStream, setLocalStream] = useState<any>();
    const videoRef = useRef<any>(null);

    const [isComponentUnmount,setIsComponentUnmount] = useState(false);

    const setStream = async () => {
        try {

            const localVideoStream = await getVideoStream(
                devices.activeVideoDevice
            );

            setLocalStream(localVideoStream)
        } catch (err) {
            console.log(err);
        }
    }

    const handleFileChange = (e: React.ChangeEvent) => {
        const files = (e.target as HTMLInputElement).files;
        
        if (files && files.length > 0) {
            if(files[0]?.type.startsWith('image/')){
                if (files[0]?.size < 300 * 1024 || files[0].size > 5 * 1024 * 1024) {
                    setShowError(true);
                } else {
                    setShowError(false);
                    setFile(files[0]);
                    setImg(URL.createObjectURL(files[0]))
                    console.log(file)
                }
            }else {
                const toastMessage = `Invalid file type. Please upload an image.`;
                toast.success(`${toastMessage}`);
            }
        }



    }

    const handleCheckboxChange = (option: keyof Options[Category], category: Category) => {

        console.log({ option, category, options })


        setOptions({
            ...options,
            [category]: {
                ...options[category],
                [option]: !options[category][option],
            },

        });


    };


    const capureImage = () => {
        if (videoRef.current) {

            const offscreen = document.createElement("canvas");
            offscreen.width = videoRef.current.videoWidth;
            offscreen.height = videoRef.current.videoHeight;
            const ctx = offscreen.getContext("2d");
            if (ctx) {

                ctx?.drawImage(videoRef.current, 0, 0, offscreen.width, offscreen.height);
                const imageData = offscreen.toDataURL();
                const blob: Blob = dataURItoBlob(imageData);
                const file: File = new File([blob], "filename.png", { type: 'image/png' });

                setFile(file);
                setImg(imageData);
                setImageDelete(false);


            }


        }
    }

    const handleClearFile = () => {
        setFile(null);
        setImg("");
        setImageDelete(true);
    }

    const handleSubmit = () => {
        const data: PersonalizationParams = { settings: options };
        if (file) {
            data['image'] = file;
        }
        data['isImageToDelete'] = imageDelete;

        dispatch(savePersonalizationSettings(data))
        .then(() => {
            dispatch(getPersonalizationSettings());
            setImageDelete(false);
        });


    }

    const handleConfirmDelete = () => {
        handleClearFile();
        setIsWarn(false)
    }

    const handleUnmountSavePersonalization = () => {
            handleSubmit();
    }

    useEffect(() => {
        dispatch(getPersonalizationSettings())
    }, [dispatch])


    // useEffect(() =>{
    //         return () => {
                
    //             setIsComponentUnmount(true)
    //         }
    // },[])

    useEffect(() => {


        if (!isInitialized.current && personalization.personalization) {
            setOptions(personalization.personalization.settings);
            setImg(personalization.personalization.imageUrl || "");
            isInitialized.current = true;

        }

    }, [personalization])

    useEffect(function () {
        if (devices.activeVideoDevice !== '') {
            setStream();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devices])
    // useEffect(() => {

    // }, [file])


    return (

        <DashboardLayout>
            <div className={`w-full bg-white relative rounded-2xl pt-3 pb-1 flex flex-col justify-start items-start overflow-y-auto scrollbar ${styles.dynamic_height}`}>


                {
                    personalization.loading && <> <div style={{
                        WebkitMask: 'radial-gradient(farthest-side, #0000 calc(100% - 16px), #000 0)'
                    }} className={`${styles.custom_loader} absolute left-[43%] top-[43%]`} ></div>


                    </>
                }




                <div className={` w-full flex flex-col justify-start items-center ${personalization.loading && 'opacity-35'}  `}>

                    <div className="container flex justify-between pl-6 pr-6   ">
                        <h2 className=" font-sans font-medium xl:text-3xl lg:text-2xl text-gray-900 self-stretch">User Personalization</h2>
                        <div className=" flex justify-center items-center gap-4">
                            <Switch.Group as="div" className="flex items-center">
                                <Switch
                                    checked={enabled}
                                    onChange={setEnabled}
                                    className={classNames(
                                        enabled ? 'bg-blue' : 'bg-gray-300',
                                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-white transition-colors duration-200 ease-in-out focus:outline-none  focus:ring-offset-2'
                                    )}
                                >
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            enabled ? 'translate-x-5' : 'translate-x-0',
                                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                        )}
                                    />
                                </Switch>
                                <Switch.Label as="span" className="ml-3 text-sm">
                                    <span className={`font-medium text-gray-900 p-1.5 rounded-2xl ${enabled ? "bg-blue-50 " : "bg-gray-50"}`}>{enabled ? "Disable Camera" : "Enable Camera"}</span>{' '}

                                </Switch.Label>
                            </Switch.Group>

                        </div>
                    </div>
                    <div className="w-full border-t-[0.5px] border-gray-400 mt-4" />

                    <div className="flex flex-col w-full gap-4 justify-start items-center">
                        <p className="relative rounded-full bg-gray-50 px-4 py-1.5 text-sm leading-6 text-green-500 font-bold  mt-2 ring-1 ring-inset ring-gray-900/5">
                            <span className="hidden md:inline">Click on “Save” after making any changes on this page</span>

                        </p>
                        <div className=" flex justify-center gap-5 items-center">

                            <div className="w-[500px]">
                                <div className={`mt-4 relative flex justify-center rounded-lg border border-dashed border-gray-900/25 ${!file ? "px-6 py-8" : "p-4"}`}>
                                    {image && <p className=" text-sm absolute top-2 right-2 cursor-pointer z-10 text-white bg-gray-900 opacity-85 rounded-full p-1" onClick={() => setIsWarn(true)}><XMarkIcon className="h-5 w-5" /></p>}
                                    {
                                        image ? <img src={image} className=" hover:opacity-90 hover:brightness-50 transition-all cursor-pointer z-0  h-[200px] w-[200px] rounded-full" alt="mage" /> :


                                            enabled ?


                                                <div className=" flex flex-col justify-center gap-3 relative ">
                                                    <Video className="w-1/2 h-full" src={localStream} reference={videoRef} />
                                                    {/* <Button children="Capture" className=" w-3/4 ml-auto mr-auto" onClick={capureImage} type="primary" /> */}
                                                    <button
                                                        type="button"
                                                        className="inline-flex w-3/4 ml-auto mr-auto justify-center rounded-md border-[1px] border-blue-50 bg-white  px-4 py-2 text-sm font-medium text-blue hover:bg-blue-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue focus-visible:ring-offset-2"
                                                        onClick={capureImage}
                                                    >
                                                        Capture
                                                    </button>
                                                    <div className="w-full h-[85%] bg-gray-900 opacity-25 absolute top-0 left-0 rounded-xl flex justify-center items-center">
                                                        <div className=" bg-white rounded-full w-[250px] h-[250px]  "></div>
                                                    </div>
                                                </div>

                                                : <>
                                                    <div className="text-center">
                                                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                                            <label
                                                                htmlFor="file-upload"
                                                                className="relative cursor-pointer w-full rounded-md bg-white font-semibold text-blue flex justify-center items-center focus-within:outline-none  hover:text-blue"
                                                            >
                                                                <p >Upload your profile photo</p>
                                                                <input id="file-upload" name="file-upload" accept=".png, .jpeg, .jpg, .svg" type="file" onChange={handleFileChange} className="sr-only" />
                                                            </label>
                                                            <p className="pl-1"></p>
                                                        </div>

                                                    </div>

                                                </>



                                    }



                                </div>
                                {showError && <p className=" text-sm text-red-600 text-center mt-1 ">*File size must be between 300 KB and 5 MB</p>}
                            </div>


                        </div>
                        <p className="text-sm leading-5 text-gray-500 text-center">Please ensure your Image file is between <b>300 KB</b> and <b>5 MB</b> with a minimum resolution of <b>480x480</b> pixels.</p>

                        <div className=" w-1/2 flex flex-col justify-between  gap-2 items-center">

                            <legend className=" font-semibold">Chat Specification</legend>
                            <div className=" flex justify-between items-center gap-8">
                                <CheckboxItem
                                    label="Audio"
                                    value="audio"
                                    checked={options && options?.chat && options.chat.audio}
                                    onChange={(value) => handleCheckboxChange(value, 'chat')}
                                />
                                <CheckboxItem
                                    label="Visual Notification"
                                    value="toast"
                                    checked={options && options?.chat && options.chat.toast}
                                    onChange={(value) => handleCheckboxChange(value, 'chat')}
                                />
                                <CheckboxItem
                                    label="Desktop Notification"
                                    value="desktop"
                                    checked={options && options?.chat && options.chat.desktop}
                                    onChange={(value) => handleCheckboxChange(value, 'chat')}
                                />
                            </div>
                        </div>



                        <div className=" w-1/2 flex flex-col gap-2 justify-between items-center">

                            <legend className="font-semibold">Activity Channel</legend>
                            <div className=" flex justify-start items-center gap-8">
                                <CheckboxItem
                                    label="Audio"
                                    value="audio"
                                    checked={options && options?.activity && options.activity.audio}
                                    onChange={(value) => handleCheckboxChange(value, 'activity')}
                                />
                                <CheckboxItem
                                    label="Visual Notification"
                                    value="toast"
                                    checked={options && options?.activity && options.activity.toast}
                                    onChange={(value) => handleCheckboxChange(value, 'activity')}
                                />
                                <CheckboxItem
                                    label="Desktop Notification"
                                    value="desktop"
                                    checked={options && options?.activity && options.activity.desktop}
                                    onChange={(value) => handleCheckboxChange(value, 'activity')}
                                />
                            </div>

                        </div>


                        <div className=" w-1/2 flex flex-col gap-2 justify-between items-center">

                            <legend className="font-semibold">Mentions</legend>
                            <div className=" flex justify-start items-center gap-8">
                                <CheckboxItem
                                    label="Audio"
                                    value="audio"
                                    checked={options && options?.mentions && options.mentions.audio}
                                    onChange={(value) => handleCheckboxChange(value, 'mentions')}
                                />
                                <CheckboxItem
                                    label="Visual Notification"
                                    value="toast"
                                    checked={options && options?.mentions && options.mentions.toast}
                                    onChange={(value) => handleCheckboxChange(value, 'mentions')}
                                />
                                <CheckboxItem
                                    label="Desktop Notification"
                                    value="desktop"
                                    checked={options && options?.mentions && options.mentions.desktop}
                                    onChange={(value) => handleCheckboxChange(value, 'mentions')}
                                />
                            </div>

                        </div>



                    </div>
                    {/* <Button type="primary" children="Save" className="mx-auto mt-3 h-[40px]" onClick={handleSubmit} /> */}
                    <button
                        type="button"
                        className="inline-flex justify-center rounded-md border-[1px] mx-auto mt-3 border-blue-50 bg-white   px-4 py-2 text-sm font-medium text-blue hover:bg-blue-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue focus-visible:ring-offset-2"
                        onClick={handleSubmit}
                    >
                        Save
                    </button>

                </div>




            </div>


            {
                isWarn && <WarningModal title="Remove Current Image?" onConfirm={handleConfirmDelete} onCancel={() => setIsWarn(false)} confirmButtonText="Yes" cancelButtonText="No" />
            }

            {
                isComponentUnmount && <WarningModal type="info" title="Save Personalization" onConfirm={handleUnmountSavePersonalization} onCancel={() => setIsComponentUnmount(false)} confirmButtonText="Yes" cancelButtonText="No" />
            }
        </DashboardLayout >
    )
}

