import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DesktopApp } from "../../interfaces/slicesInterface/desktopApp";
import { RootState } from "../../app/store";
import SocketService from "../../services/socket";
import { socketConnectiontype } from "../../utils/constant";

  
const initialState: DesktopApp = {
    screenNumObj: {}
}

const desktopAppSlice = createSlice({
    name: 'desktopApp',
    initialState,
    reducers: {
        setScreenNumObj: (state, action) => {
            state.screenNumObj = action.payload
        },
        disconnnectSocketDesktop:()=>{
            const socketInstanceDesktopApp = new SocketService(
                socketConnectiontype.desktopApp
              ).getInstance(socketConnectiontype.desktopApp);
            socketInstanceDesktopApp.closeSocketConnection();
        },
    }
})


export const desktopAppSelector = (state: RootState) => state.desktopApp;
export const { setScreenNumObj,disconnnectSocketDesktop } = desktopAppSlice.actions;
export default desktopAppSlice.reducer;