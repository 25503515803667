const formatDuration = (durationInMilliseconds: number  | any): string => {
  const seconds = Math.floor((durationInMilliseconds / 1000) % 60);
  const minutes = Math.floor((durationInMilliseconds / (1000 * 60)) % 60);
  const hours = Math.floor((durationInMilliseconds / (1000 * 60 * 60)) % 24);

  let result = "";

  if (hours > 0) {
    result += `${hours}Hr. `;
  }
  if (minutes > 0) {
    result += `${minutes}Min. `;
  }
  result += `${seconds}Secs.`;

  return result.trim();
};

export default formatDuration;