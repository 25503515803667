import { createAsyncThunk } from "@reduxjs/toolkit";
import { authRequest } from ".";
import { getToken } from "../utils/Token";


const config = {
  headers: {
    Authorization: "",
    "Content-Type": "application/json",
    //   "mode":"no-cors"
  },
};

export const getPendingVerifications = createAsyncThunk(
  "adminVerification/getPendingVerifications",
  async () => {
    config["headers"]["Authorization"] = getToken();
   
    try {
      const response = await authRequest.get(
        "/v1/api/facevalidation/getpendingverification",

        config
      );
      return response.data;
    } catch (err: unknown | any) {
      throw new Error(`Error in Getting Pending Verifications ${err.message}`);
    }
  }
);

export const verifyAllUsers = createAsyncThunk(
  "adminVerification/verifyAllUsers",
  async (data: string[]) => {
    config["headers"]["Authorization"] = getToken();
    const body = {
      users: data,
    };
    try {
      const response = await authRequest.post("/v1/api/facevalidation/verifyUsers", body, config);
      return { response: response.data, data };
    } catch (err: unknown | any) {
      throw new Error(`Error in Verifying All Users: ${err.message}`);
    }
  }
);


export const rejectAllUsers = createAsyncThunk(
  "adminVerification/rejectAllUsers",
  async (data: string[]) => {
    config["headers"]["Authorization"] = getToken();
    const body = {
      users: data,
    };
    try {
      const response = await authRequest.post("/v1/api/facevalidation/rejectusers", body, config);
      return { response: response.data, data };
    } catch (err: unknown | any) {
      throw new Error(`Error in Verifying All Users: ${err.message}`);
    }
  }
);



