import React from "react";

type ModalProps = {
    title?: string;
    message?: string;
    onConfirm: () => void;
    onCancel: () => void;
    confirmButtonText?: string;
    cancelButtonText?: string;
    type?: string;
};

const WarningModal: React.FC<ModalProps> = ({ title, message, onConfirm, onCancel,cancelButtonText,confirmButtonText,type }) => {
    return (
        <div>
            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <div className="inline-block  align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full">
                        <div className="relative bg-white rounded-lg shadow ">
                            <button
                                type="button"
                                className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
                                onClick={onCancel}
                            >
                                <svg className="w-3 h-3" aria-hidden="true" viewBox="0 0 14 14">
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-7 text-center">
                                <h3 className="mb-5   font-bold text-xl ">{title}</h3>
                                <p className="mb-5 text-sm text-gray-500 font-normal ">{message}</p>

                                <button
                                    onClick={onCancel}
                                    className="py-2.5 px-12  text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
                                >
                                   { cancelButtonText ||  "Cancel"}
                                </button>
                                <button
                                    onClick={onConfirm}
                                    className={`text-white ms-5 ${type === "info" ? "bg-blue":"bg-red-600"} focus:ring-4 focus:outline-none focus:ring-red-300  font-medium rounded-lg text-sm inline-flex items-center px-12 py-2.5 text-center`}
                                >
                                    {confirmButtonText || "Delete"}
                                    
                                </button>
                               
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WarningModal;
