import React, { useState } from 'react';

interface ImageMessagePreviewProps {
  fileurl: string;
  contentType: string;
}

const ImageMessagePreview: React.FC<ImageMessagePreviewProps> = ({
  fileurl,
  contentType,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      {contentType === 'image' && (
        <div>
          <img
            src={fileurl}
            alt="Uploaded doc"
            className="w-40 cursor-pointer"
            onClick={openModal}
          />
          {modalOpen && (
            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                  className="fixed inset-0 transition-opacity"
                  aria-hidden="true"
                  onClick={closeModal}
                >
                  <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <img src={fileurl} alt="Uploaded doc" className="w-full" />
                  <div className="absolute top-0 right-0 m-2 flex">
                    <a
                      href={fileurl}
                      download
                      target="_blank"
                      rel="noreferrer"
                      className="bg-gray-300 rounded-full p-1 hover:bg-gray-400"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-7 w-7 cursor-pointer text-black"
                        viewBox="0 0 24 21"
                        fill="none"
                      >
                        <path
                          d="M12 16l-6-6h4V4h4v6h4l-6 6z"
                          fill="currentColor"
                        />
                      </svg>
                    </a>
                  </div>
                  {/* <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      onClick={closeModal}
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-black hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Close
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ImageMessagePreview;