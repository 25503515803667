import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { collabType } from "../../interfaces/slicesInterface/meetingRoom";

const initialState: collabType = {
  screenCollabPeer: { peerId: "", peerUsername: "" },
  screenCollabPeerArray: [],
};

const mettingRoomSlice = createSlice({
  name: "mettingroom",
  initialState,
  reducers: {
    addCollabScreenPeer: (state, action: PayloadAction<any>) => {
      if (state.screenCollabPeer.peerId === "") {
        state.screenCollabPeer = action.payload;
      }
      state.screenCollabPeerArray = [
        ...state.screenCollabPeerArray,
        { ...action.payload },
      ];
    },
    selectCollabScreenPeer: (state, action: PayloadAction<any>) => {
      state.screenCollabPeer = action.payload;
    },
    addScreenPeerWhileSocketConnected: (state, action: PayloadAction<any>) => {
      if (action.payload.length > 0) {
        state.screenCollabPeer = { ...action.payload[0] };
      }
      state.screenCollabPeerArray = action.payload;
    },
    removeCollabScreenPeer: (state, action: PayloadAction<any>) => {
      state.screenCollabPeerArray = state.screenCollabPeerArray.filter(
        (value) => value.peerId !== action.payload.peerId
      );
      if (action.payload.peerId === state.screenCollabPeer.peerId) {
        state.screenCollabPeer =
          state.screenCollabPeerArray.length > 0
            ? { ...state.screenCollabPeerArray[0] }
            : { peerId: "", peerUsername: "" };
      }
    },

    clearMeetingRoomReducer: (state) => {
      return initialState;
    },
  },
  extraReducers(builder) {},
});

export const meetingRoomSelector = (state: RootState) => state.meetingRoom;
export const {
  addCollabScreenPeer,
  selectCollabScreenPeer,
  addScreenPeerWhileSocketConnected,
  removeCollabScreenPeer,
  clearMeetingRoomReducer,
} = mettingRoomSlice.actions;
export default mettingRoomSlice.reducer;
