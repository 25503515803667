import React from "react";
import Chats from "../Chats";
import Alerts from "../Alerts";
import { RoomSidebarProps } from "../../interfaces/componentsInterface/roomSidebar";
import Participants from "../Participants";

export default function RoomSidebar({
  active,
  setActive,
  setIsAlertActive,
  isAlertActive,
  setIsParticipantActive,
  isParticipantActive,
  makePrivateCall,
  cellphoneDetected,
  performRightShiftByOne,
}: RoomSidebarProps): ReturnType<React.FC> {
  return (
    <>
      <div className="h-full w-full flex justify-start items-center flex-col gap-2 ">
        <Chats active={active} setActive={setActive} />
        {/* {isAlertActive.val && ( */}
          <Alerts
            active={active}
            isAlertActive={isAlertActive}
            setActive={setActive}
            setIsAlertActive={setIsAlertActive}
            setIsParticipantActive={setIsParticipantActive}
          />
        {/* // )} */}
        {/* {isParticipantActive.val && ( */}
          <Participants
            active={active}
            isParticipantActive={isParticipantActive}
            setActive={setActive}
            setIsAlertActive={setIsAlertActive}
            setIsParticipantActive={setIsParticipantActive}
            makePrivateCall={makePrivateCall}
            cellphoneDetected={cellphoneDetected}
            performRightShiftByOne={performRightShiftByOne}
          />
        {/* )} */}

        {/* <Participants active={active} setActive={setActive} /> */}
      </div>
    </>
  );
}
