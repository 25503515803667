import React, { useState, useEffect } from "react";
import sliderBackground from "../../assets/images/slider-background.svg";
import styles from "./styles.module.css";
import SocketService from "../../services/socket";
import { useParams, useNavigate } from "react-router-dom";
import { IConnectionDetails } from "../../interfaces/componentsInterface/whiteboard";
import { getConnectionDetailsForWhiteboard } from "../../services/whiteboard.service";
import toast from "react-hot-toast";
import { checkForSupManAdmin } from "../../utils/checkForSupAdmin";
import { socketConnectiontype, socketPathName } from "../../utils/constant";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import { getUserDetails } from "../../services/userDetails";
import Board from "../Board";
import {
  CheckIcon,
  FolderArrowDownIcon,
  LockClosedIcon,
  LockOpenIcon,
  PhotoIcon,
  TrashIcon,
  PencilIcon,
  ArrowUturnRightIcon,
  ArrowUturnLeftIcon,
  ArchiveBoxXMarkIcon,
} from "@heroicons/react/20/solid";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import PencilIconComponent from "../PencilIconComponent/PencilIconComponent";
import routes from "../../constants/routes";

// let socket :SocketIOClient.Socket | undefined = io("http://localhost:5000");

const socketInstanceWB = new SocketService(
  socketConnectiontype.whiteBoard
).getInstance(socketConnectiontype.whiteBoard);

const Whiteboard = (props: any) => {
  const [strokeColor, setStrokeColor] = useState("#000000");
  const [strokeWidth, setStrokeWidth] = useState("5");
  const [selectTool, setSelectTool] = useState({
    line: false,
    pen: true,
    circle: false,
    rectangle: false,
    eraser: false,
    stickyNote: false,
    textBox: false,
  });
  const [saveImage, setSaveImage] = useState(false);
  const [clearCanvas, setClearCanvas] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [stickNoteColor, setStickyNoteColor] = useState("#f2f542");
  const [dropIndicator, setDropIndicator] = useState(false);
  const [readOnly, setReadOnly] = useState(false);

  const params = useParams();

  const [backgroundImage, setBackgroundImage] = useState(
    [] as Array<{ id: string; img: string }>
  );
  const [readForInstructor, setReadForInstructor] = useState(false as boolean);
  const [uploadFiles, setUploadFiles] = useState({});
  const [showAllButton, setShowAllButton] = useState(true);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(userDetailsSelector);
  const role = user.role;
  let messageSubscriber: any;

  useEffect(() => {
    dispatch(getUserDetails({ data: "" }));
    window.addEventListener("dragover", function (e) {
      e.preventDefault();
    });
    console.log({ role });

    window.addEventListener("drop", function (e) {
      e.preventDefault();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    messageSubscriber = socketInstanceWB
      .getMessages()
      .subscribe((message: string) => {
        processMessage(JSON.parse(message));
      });

    const fetchConnectionDetails = async () => {
      try {
        const connectionDetails: IConnectionDetails =
          await getConnectionDetailsForWhiteboard(
            params?.roomname,
            params?.roomId
          );

        let hostname = process.env.REACT_APP_DESKTOP_WS_URL!;

        if (!hostname) {
          if (window.location.origin.includes("localhost")) {
            hostname = "http://localhost:8085";
          } else {
            hostname = window.location.origin;
          }
        }

        socketInstanceWB.connectToSocket(
          hostname,
          connectionDetails.path,
          connectionDetails.payload,
          connectionDetails.iv,
          connectionDetails.digest,
          "",
          socketPathName.whiteboard,
          true
        );
      } catch (err) {
        toast.error("OOPS! Room Name was not valid");
        navigate(routes.login);
      }
    };

    fetchConnectionDetails();

    return () => {
      messageSubscriber.unsubscribe();
      socketInstanceWB.reconnectFlushState();
      socketInstanceWB.closeSocketConnection(true);
      socketInstanceWB.socket = undefined;
    };
  }, [role]);

  const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const processMessage = async (message: any) => {
    console.log({ message });
    if (message.data === undefined) return;

    const data = message.data;
    switch (message.type) {
      case "WB_clearCanvas":
        setClearCanvas(data.isClearCanvas);
        break;
      case "WB_readOnly":
        readOnlyForAgent();
        break;
      case "WB_readWriteOnly":
        readWriteOnlyForAgent();
        break;
      default:
        break;
    }
  };

  const readOnlyForAgent = () => {
    if (checkForSupManAdmin(role, "notEqualTo")) {
      setReadOnly(true);
      setSelectTool((value) => ({
        line: false,
        pen: false,
        circle: false,
        rectangle: false,
        eraser: false,
        stickyNote: false,
        textBox: false,
      }));
    }
  };

  const readWriteOnlyForAgent = () => {
    if (checkForSupManAdmin(role, "notEqualTo")) {
      setReadOnly(false);
      setSelectTool((value) => ({
        line: false,
        pen: true,
        circle: false,
        rectangle: false,
        eraser: false,
        stickyNote: false,
        textBox: false,
      }));
    }
  };

  const uploadImage = async (e: any) => {
    if (e.dataTransfer) {
      if (e.dataTransfer.files.length) {
        e.preventDefault();
        e.stopPropagation();
        if (!e.dataTransfer.files[0]) return;

        let filename = e.dataTransfer.files[0].name;
        const fileSize = e.dataTransfer.files[0].size / (1024 * 1024);

        if (fileSize <= 5) {
          const fileExtensionArray = filename.split(".");
          let fileExtension = fileExtensionArray[fileExtensionArray.length - 1];

          fileExtension = fileExtension.toLowerCase();

          if (
            fileExtension === "jpg" ||
            fileExtension === "jpeg" ||
            fileExtension === "png"
          ) {
            const formData = new FormData();
            formData.append("productImage", e.dataTransfer.files[0]);

            let id = new Date() + "";
            setBackgroundImage([
              { id, img: URL.createObjectURL(e.dataTransfer.files[0]) },
            ]);

            setUploadFiles(e.dataTransfer.files[0]);
          }
        }
      }
    }

    setDropIndicator(false);
  };

  return (
    <>
      {role === "" ? null : (
        <div className={styles.container}>
          {/*-Whiteboard container -!*/}
          <div
            // id="whiteboardContainer"
            className="h-screen w-full"
            onDragEnter={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDropIndicator(true);
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDropIndicator(false);
            }}
            onDrop={(e) => {
              if (readOnly) return;
              uploadImage(e);
            }}
          >
            <Board
              strokeColor={strokeColor}
              strokeWidth={strokeWidth}
              selectTool={selectTool}
              clearCanvas={clearCanvas}
              setClearCanvas={setClearCanvas}
              stickyNoteColor={stickNoteColor}
              dropIndicator={dropIndicator}
              setDropIndicator={setDropIndicator}
              saveImage={saveImage}
              setSaveImage={setSaveImage}
              readOnly={readOnly}
              backgroundImage={backgroundImage}
              setBackgroundImage={setBackgroundImage}
              // currentUserRole={props.currentUserRole}
              uploadFiles={uploadFiles}
              b64toBlob={b64toBlob}
              readOnlyForAgent={readOnlyForAgent}
              readWriteOnlyForAgent={readWriteOnlyForAgent}
              setReadForInstructor={setReadForInstructor}
              setSelectTool={setSelectTool}
            />
          </div>
          {/*-Toolbar -!*/}
          <div
            id="toolbar"
            style={{ position: "absolute", top: "10px", left: "10px" }}
          >
            {checkForSupManAdmin(role, "equalTo") ? (
              <div
                className={`${styles.btnGroup}`}
                style={{ display: showAllButton ? "block" : "none" }}
              >
                {readForInstructor ? (
                  <button
                    id="whiteboardLockBtn"
                    style={{ backgroundColor: "orange" }}
                    title="View and Write"
                    type="button"
                    onClick={() => {
                      socketInstanceWB.sendMessage("WB_readWriteOnly", {
                        tool: "readWriteOnly",
                        readOnly: false,
                      });
                      setReadForInstructor(false);
                    }}
                  >
                    <LockClosedIcon className="h-6 w-6" />
                  </button>
                ) : null}
                {!readForInstructor ? (
                  <button
                    id="whiteboardUnlockBtn"
                    title="View Only"
                    type="button"
                    onClick={(e) => {
                      socketInstanceWB.sendMessage("WB_readOnly", {
                        tool: "readOnly",
                        readOnly: true,
                      });
                      setReadForInstructor(true);
                    }}
                  >
                    <LockOpenIcon className="h-6 w-6 " />
                  </button>
                ) : null}
              </div>
            ) : null}
            <div
              className={`${styles.btnGroup} ${styles.whiteboardEditGroup} ${
                readOnly ? `${styles.groupDisabled}` : ""
              }`}
              style={{ display: showAllButton ? "block" : "none" }}
            >
              <button
                id="whiteboardTrashBtn"
                title="Clear the whiteboard"
                type="button"
                style={{ display: confirmDelete ? "none" : "block" }}
                onClick={() => setConfirmDelete(true)}
              >
                <TrashIcon className="h-6 w-6" />
              </button>
              <button
                style={{ display: !confirmDelete ? "none" : "block" }}
                id="whiteboardTrashBtnConfirm"
                title="Confirm clear..."
                type="button"
                onClick={() => {
                  setClearCanvas(true);
                  setConfirmDelete(false);
                  socketInstanceWB?.sendMessage("WB_clearCanvas", {
                    isClearCanvas: true,
                  });
                }}
              >
                <CheckIcon className="h-6 w-6" />
              </button>
              <button
                id="whiteboardUndoBtn"
                title="Undo your last step"
                type="button"
                onClick={() => {
                  socketInstanceWB.sendMessage("WB_undo", {});
                }}
              >
                <ArrowUturnLeftIcon className="w-5 h-5" />
              </button>
              <button
                id="whiteboardRedoBtn"
                title="Redo your last undo"
                type="button"
                onClick={() => {
                  socketInstanceWB.sendMessage("WB_redo", {});
                }}
              >
                <ArrowUturnRightIcon className="w-5 h-5" />
              </button>
            </div>
            <div
              className={`${styles.btnGroup} ${styles.whiteboardEditGroup} ${
                readOnly ? `${styles.groupDisabled}` : ""
              }`}
              style={{ display: showAllButton ? "block" : "none" }}
            >
              <button
                title="Take the pen"
                type="button"
                className={`${styles.whiteboardTool}`}
                onClick={(e) => {
                  setSelectTool((value) => ({
                    line: false,
                    pen: true,
                    circle: false,
                    rectangle: false,
                    eraser: false,
                    stickyNote: false,
                    textBox: false,
                  }));
                }}
                style={
                  selectTool.pen
                    ? { background: "#dfdfdf" }
                    : { background: "transparent" }
                }
              >
                <PencilIconComponent />
              </button>
              <button
                title="draw a line"
                type="button"
                className={`${styles.whiteboardTool}`}
                onClick={(e) =>
                  setSelectTool((value) => ({
                    line: true,
                    pen: false,
                    circle: false,
                    rectangle: false,
                    eraser: false,
                    stickyNote: false,
                    textBox: false,
                  }))
                }
                style={
                  selectTool.line
                    ? {
                        background: "#dfdfdf",
                        paddingBottom: "8px",
                        paddingTop: "6px",
                      }
                    : {
                        background: "transparent",
                        paddingBottom: "8px",
                        paddingTop: "6px",
                      }
                }
              >
                ╱
              </button>
              <button
                title="draw a rectangle"
                type="button"
                className={`${styles.whiteboardTool}`}
                onClick={(e) =>
                  setSelectTool((value) => ({
                    line: false,
                    pen: false,
                    circle: false,
                    rectangle: true,
                    eraser: false,
                    stickyNote: false,
                    textBox: false,
                  }))
                }
                style={
                  selectTool.rectangle
                    ? {
                        background: "#dfdfdf",
                      }
                    : {
                        background: "transparent",
                      }
                }
              >
                <div className="inline-block w-4 h-4 border-2 rounded-sm border-black"></div>
              </button>
              <button
                title="draw a circle"
                type="button"
                className={`${styles.whiteboardTool}`}
                onClick={(e) =>
                  setSelectTool((value) => ({
                    line: false,
                    pen: false,
                    circle: true,
                    rectangle: false,
                    eraser: false,
                    stickyNote: false,
                    textBox: false,
                  }))
                }
                style={
                  selectTool.circle
                    ? { background: "#dfdfdf" }
                    : { background: "transparent" }
                }
              >
                <div className="inline-block w-4 h-4 border-2  border-black rounded-full"></div>
              </button>
              <button
                title="take the eraser"
                type="button"
                className={`${styles.whiteboardTool}`}
                onClick={(e) =>
                  setSelectTool((value) => ({
                    line: false,
                    pen: false,
                    circle: false,
                    rectangle: false,
                    eraser: true,
                    stickyNote: false,
                    textBox: false,
                  }))
                }
                style={
                  selectTool.eraser
                    ? { background: "#dfdfdf" }
                    : { background: "transparent" }
                }
              >
                <ArchiveBoxXMarkIcon className="w-6 h-6" />
              </button>
            </div>
            <div
              className={`${styles.btnGroup} ${styles.whiteboardEditGroup} ${
                readOnly ? `${styles.groupDisabled}` : ""
              }`}
              style={{ display: showAllButton ? "block" : "none" }}
            >
              <button style={{ width: "190px", cursor: "default" }}>
                <div
                  className="activeToolIcon"
                  style={{
                    position: "absolute",
                    top: "2px",
                    left: "2px",
                    fontSize: "0.6em",
                  }}
                >
                  <PencilIcon className="h-4 w-4" />
                </div>
                <img
                  alt="slider"
                  style={{
                    position: "absolute",
                    left: "11px",
                    top: "16px",
                    height: "14px",
                    width: "130px",
                  }}
                  src={sliderBackground}
                />
                <input
                  title="Thickness"
                  // id="whiteboardThicknessSlider"
                  className="appearance-none w-full h-3 bg-transparent rounded-md focus:outline-none opacity-100 transition-opacity duration-150 ease-in-out"
                  style={{
                    position: "absolute",
                    left: "9px",
                    width: "130px",
                    top: "15px",
                    border: "none",
                  }}
                  type="range"
                  min={1}
                  max={50}
                  value={strokeWidth}
                  onChange={(e) => {
                    setStrokeWidth(e.target.value);
                  }}
                />

                <input
                  type="color"
                  id="whiteboardColorpicker"
                  style={{
                    position: "absolute",
                    left: "155px",
                    top: "10px",
                    width: "28px",
                    height: "23px",
                    borderRadius: "3px",
                    border: "1px solid darkgrey",
                    padding: "0",
                  }}
                  onChange={(e) => {
                    setStrokeColor(e.target.value);
                  }}
                  value={strokeColor}
                />
              </button>
            </div>
            <div
              className={`${styles.btnGroup} ${styles.whiteboardEditGroup} ${
                readOnly ? `${styles.groupDisabled}` : ""
              }`}
              style={{ display: showAllButton ? "block" : "none" }}
            >
              <button
                title="place a sticky note"
                type="button"
                className={`${styles.whiteboardTool}`}
                onClick={(e) =>
                  setSelectTool((value) => ({
                    line: false,
                    pen: false,
                    circle: false,
                    rectangle: false,
                    eraser: false,
                    stickyNote: true,
                    textBox: false,
                  }))
                }
                style={
                  selectTool.stickyNote
                    ? { background: "#dfdfdf" }
                    : { background: "transparent" }
                }
              >
                <div>A</div>
              </button>

              <button
                id="textboxBackgroundColorPickerBtn"
                style={{
                  display:
                    selectTool.stickyNote || selectTool.textBox
                      ? "block"
                      : "none",
                }}
              >
                <input
                  type="color"
                  // id="textboxBackgroundColorPicker"
                  style={{
                    width: "26px",
                    height: "23px",
                    borderRadius: "3px",
                    border: "1px solid darkgrey",
                    left: "-4px",
                    top: "-2px",
                    position: "relative",
                    background: `${stickNoteColor}`,
                  }}
                  onChange={(e) => setStickyNoteColor(e.target.value)}
                  value={stickNoteColor}
                />
              </button>
            </div>

            <div
              className={`${styles.btnGroup}`}
              style={{ display: showAllButton ? "block" : "none" }}
            >
              <button
                id="saveAsImageBtn"
                title="Save whiteboard as image"
                type="button"
                onClick={() => setSaveImage(true)}
              >
                <PhotoIcon className="h-6 w-6" />

                <FolderArrowDownIcon className="absolute w-4 h-4 top-0 left-0 text-black" />
              </button>
            </div>
            <div className={`${styles.btnGroup} ${styles.minGroup}`}>
              <button
                style={{ width: "100%", padding: "11px 11px" }}
                id="minMaxBtn"
                title="hide buttons"
                type="button"
                onClick={() => setShowAllButton((value) => !value)}
              >
                <ChevronLeftIcon
                  className={`w-6 h-6  relative left-[-10px] ${
                    showAllButton ? "block" : "hidden"
                  }`}
                />

                <ChevronRightIcon
                  className={`w-6 h-6  relative left-[-10px] ${
                    !showAllButton ? "block" : "hidden"
                  }`}
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Whiteboard;
