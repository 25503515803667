import  { Component, ErrorInfo, ReactNode } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import React from 'react'
import Button from '../Button';
import { Link } from 'react-router-dom';
interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
    // You can log the error or send it to a logging service
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // You can customize the error message or render a fallback UI here
      return (
        <div className=' w-screen h-screen flex flex-col justify-center items-center bg-gray-50'>
        <div className='w-[30%] h-[35%] bg-white rounded-2xl shadow-md flex flex-col justify-center items-center'>
            <p><ExclamationCircleIcon className='h-20 w-20 text-red-600 bg-red-50 rounded-full' /></p>
            <p className=' text-red-600 text-lg mt-2 mb-2'>Sorry, There was an error.</p>
            <p className=' text-gray-500 mt-2 mb-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut, ab!</p>
            <div className='flex justify-center items-center mt-2 '>
                <Link to="/dashboard"><Button type='outline'>Back to Main Menu</Button></Link>
                <Button type='primary' className=' ml-3' onClick={()=>{
                    window.location.reload();
                }}>Reload Page</Button>
            </div>
        </div>
    </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
