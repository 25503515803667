"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractSuccessCandidatePairsIPs = exports.getSuccessCandidatePairIPs = void 0;
var findCandidatePairKeys = function (stats) {
    var keys = [];
    for (var _i = 0, _a = Object.entries(stats); _i < _a.length; _i++) {
        var _b = _a[_i], reportKey = _b[0], reportData = _b[1];
        if ((reportData === null || reportData === void 0 ? void 0 : reportData.type) === "candidate-pair") {
            keys.push(reportKey);
        }
    }
    return keys;
};
var getCandidateData = function (stats, candidatePair, type) {
    var candidateIdKey = type + "CandidateId";
    // @ts-ignore
    var candidateId = candidatePair[candidateIdKey];
    if (!candidateId) {
        return undefined;
    }
    var candidate = stats[candidateId];
    if (!candidate) {
        for (var _i = 0, _a = Object.entries(stats); _i < _a.length; _i++) {
            var _b = _a[_i], reportKey = _b[0], reportData = _b[1];
            if (reportKey.includes(candidateId)) {
                candidate = reportData;
            }
        }
    }
    return candidate;
};
var getSelectedPairId = function (stats) {
    for (var _i = 0, _a = Object.values(stats); _i < _a.length; _i++) {
        var reportData = _a[_i];
        var selectedCandidatePairId = reportData.selectedCandidatePairId;
        if (selectedCandidatePairId) {
            return selectedCandidatePairId;
        }
    }
    return null;
};
var getCandidatePairIPs = function (candidatePair) {
    var _a, _b, _c, _d, _e, _f;
    var local = ((_a = candidatePair === null || candidatePair === void 0 ? void 0 : candidatePair.localCandidate) === null || _a === void 0 ? void 0 : _a.ip) ||
        ((_b = candidatePair === null || candidatePair === void 0 ? void 0 : candidatePair.localCandidate) === null || _b === void 0 ? void 0 : _b.address) ||
        ((_c = candidatePair === null || candidatePair === void 0 ? void 0 : candidatePair.localCandidate) === null || _c === void 0 ? void 0 : _c.ipAddress);
    var remote = ((_d = candidatePair === null || candidatePair === void 0 ? void 0 : candidatePair.remoteCandidate) === null || _d === void 0 ? void 0 : _d.ip) ||
        ((_e = candidatePair === null || candidatePair === void 0 ? void 0 : candidatePair.remoteCandidate) === null || _e === void 0 ? void 0 : _e.address) ||
        ((_f = candidatePair === null || candidatePair === void 0 ? void 0 : candidatePair.remoteCandidate) === null || _f === void 0 ? void 0 : _f.ipAddress);
    if (local && remote) {
        return { local: local, remote: remote };
    }
    return null;
};
var getSuccessCandidatePairIPs = function (_a) {
    var selectedCandidatePairId = _a.selectedCandidatePairId, candidatePairs = __rest(_a, ["selectedCandidatePairId"]);
    if (selectedCandidatePairId) {
        var candidatePair = candidatePairs[selectedCandidatePairId];
        var ips = getCandidatePairIPs(candidatePair);
        if (ips) {
            return ips;
        }
    }
    else {
        for (var _i = 0, _b = Object.values(candidatePairs); _i < _b.length; _i++) {
            var candidatePair = _b[_i];
            if (candidatePair.state !== "succeeded" && !(candidatePair === null || candidatePair === void 0 ? void 0 : candidatePair.selected)) {
                continue;
            }
            var ips = getCandidatePairIPs(candidatePair);
            if (ips && (candidatePair.selected || candidatePair.activeCandidatePair)) {
                return ips;
            }
        }
    }
    return null;
};
exports.getSuccessCandidatePairIPs = getSuccessCandidatePairIPs;
var extractSuccessCandidatePairsIPs = function (stats) {
    if (!stats) {
        return null;
    }
    var candidatePairKeys = findCandidatePairKeys(stats);
    if (!candidatePairKeys.length) {
        return null;
    }
    var candidatePairs = {};
    for (var _i = 0, candidatePairKeys_1 = candidatePairKeys; _i < candidatePairKeys_1.length; _i++) {
        var candidatePairKey = candidatePairKeys_1[_i];
        var candidatePair = stats[candidatePairKey];
        if (!candidatePair) {
            continue;
        }
        var localCandidate = getCandidateData(stats, candidatePair, "local");
        var remoteCandidate = getCandidateData(stats, candidatePair, "remote");
        if (!candidatePairs[candidatePairKey]) {
            candidatePairs[candidatePairKey] = {};
        }
        candidatePairs[candidatePairKey] = __assign(__assign({}, candidatePair), { localCandidate: localCandidate, remoteCandidate: remoteCandidate });
    }
    var selectedCandidatePairId = getSelectedPairId(stats);
    if (selectedCandidatePairId) {
        candidatePairs.selectedCandidatePairId = selectedCandidatePairId;
    }
    var ips = (0, exports.getSuccessCandidatePairIPs)(candidatePairs);
    return ips;
};
exports.extractSuccessCandidatePairsIPs = extractSuccessCandidatePairsIPs;
