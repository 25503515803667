import React, { useState } from "react";
import styles from "./styles.module.css";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

// Define emojis with associated search terms
const emojisWithSearchTerms: { emoji: string; searchTerms: string[] }[] = [
    { emoji: "😀", searchTerms: ["smile"] },
    { emoji: "🙂", searchTerms: ["neutral"] },
    { emoji: "😔", searchTerms: ["sad"] },
    { emoji: "👍", searchTerms: ["thumbs up", "approve", "like"] },
    { emoji: "👎", searchTerms: ["thumbs down", "disapprove", "dislike"] },
    { emoji: "👌", searchTerms: ["ok","thumb and forefinger"] },
    { emoji: "🫰", searchTerms: ["ok", "thumb and forefinger", "perfect"] },
    { emoji: "🏃", searchTerms: ["pronto", "running", "quick"] },
  ];

const EmojiPicker: React.FC<{ onEmojiSelect: (emoji: string) => void, isChatPopupOpened:any }> = ({ onEmojiSelect,isChatPopupOpened }) => {
    const [searchTerm, setSearchTerm] = useState<string>("");

    // Function to handle search input change
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    // Function to filter emojis based on search term and associated keywords
    const filterEmojisBySearchTerm = () => {
        const lowerCaseSearch = searchTerm.toLowerCase().trim();

        if (!lowerCaseSearch) {
            return emojisWithSearchTerms.map((emojiObj) => emojiObj.emoji);
        }

        const filteredEmojis = emojisWithSearchTerms.filter((emojiObj) =>
            emojiObj.searchTerms.some((term) => term.includes(lowerCaseSearch))
        );

        return filteredEmojis.map((emojiObj) => emojiObj.emoji);
    };

    // Get filtered emojis based on search term
    const filteredEmojis = filterEmojisBySearchTerm();

    // Function to handle emoji selection
    const handleEmojiSelect = (emoji: string) => {
        onEmojiSelect(emoji);
    };

    return (
        <div
            className={`${styles.height} absolute left-14  z-10  ${isChatPopupOpened? "bottom-14": "bottom-12"} max-h-36 max-w-64   bg-white shadow-gray-400 border-gray-400 border rounded-lg p-4 overflow-auto shadow-lg`}
            style={{height: "calc(100vh - 80%)"}}
        >
            <div className="relative rounded-md shadow-sm w-full flex justify-center items-center mb-2">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                    type="text"
                    placeholder="Search emojis..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    id="search"
                    className="block w-full py-1 pl-10 sm:text-sm sm:leading-6 border-2 border-gray-300 focus:border-blue focus:outline-none rounded-md leading-5 transition ease-in-out"
                />
            </div>
            <div className="grid grid-cols-4 gap-2">
                {filteredEmojis.map((emoji) => (
                    <span
                        key={emoji}
                        onClick={() => handleEmojiSelect(emoji)}
                        className="cursor-pointer text-xl"
                    >
                        {emoji}
                    </span>
                ))}
            </div>
        </div>
    );
};

export default EmojiPicker;
