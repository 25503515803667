import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { screenControlInterface } from "../../interfaces/slicesInterface/screenControl";

const initialState: screenControlInterface = {
  openentPeerId: "",
  isScreenControl:false,
};

const screenControlSlice = createSlice({
  name: "screenControl",
  initialState,
  reducers: {
    setOpenentPeerId: (state, action: PayloadAction<any>) => {
      state.openentPeerId = action.payload;
    },
    setIsScreenControl: (state, action: PayloadAction<boolean>) => {
      state.isScreenControl = action.payload;
    },
    clearScreenControl: () => {
      return initialState;
    },
 
  },
});

export const { setOpenentPeerId, clearScreenControl ,setIsScreenControl} =
  screenControlSlice.actions;
export const screenControlSelector = (state: RootState) => state.screenControl;
export default screenControlSlice.reducer;
