import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { setImageLink, videoBackgroundSelector } from '../../features/videoBackground/videoBackgroundSlice';
import { environmentSelector } from '../../features/environment/environmentSlice';
import { getBackgroundImages } from '../../services/videoBackground';
import { setVideoOption } from '../../features/videoOption/videoOptionSlice';
import { setCameraBackground } from '../../features/userDetails/userDetailsSlice';
import { saveBackgroundActivate } from '../../services/userDetails';
import { VirtualBackgroundProps } from '../../interfaces/componentsInterface/virtualBackground';


export default function VirtualBackground(props:VirtualBackgroundProps): ReturnType<React.FC> {


    const dispatch = useAppDispatch();
    const videoBackground = useAppSelector(videoBackgroundSelector);
    const environments = useAppSelector(environmentSelector).environments;
    const companyName = environments.REACT_APP_COMPANY_NAME
        ? environments.REACT_APP_COMPANY_NAME.value
        : process.env.REACT_APP_COMPANY_NAME;

    const handleSelectBackground = (videoOption: string, url: string) => {
        dispatch(setImageLink(url));
        dispatch(setVideoOption(videoOption));
        dispatch(setCameraBackground({ backgroundType: videoOption, url }));
        dispatch(saveBackgroundActivate({ backgroundType: videoOption, url }))
        props.closeVirtualModal()
    };


    useEffect(() => {
        dispatch(getBackgroundImages())
    }, [dispatch, companyName])
 
    return (

        <>


            <div className="bg-white flex justify-center items-center flex-wrap gap-2">
                {videoBackground && videoBackground?.staticBackground && videoBackground?.staticBackground.map((image: any) => (
                    <div key={image._id} className="relative w-[48%] cursor-pointer shadow-lg border-2 border-gray-300 rounded-lg" title={image.files.filename}>
                        <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-40 xl:h-60">
                            <img
                                src={image.files.spacesUrl}
                                alt={image.files.filename}
                                className="w-full object-cover object-center lg:h-full lg:w-full"
                                onClick={() => {
                                    handleSelectBackground("background", image.files.spacesUrl);
                                }}
                            />
                        </div>

                    </div>
                ))}


            </div>

        </>
    )
}