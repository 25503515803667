import React, { useEffect } from "react";
import styles from "./styles.module.css";
import { TopicsProps } from "../../interfaces/componentsInterface/topics";
import toast from "react-hot-toast";
import { subscribeTopic, unsubscribeTopic } from "../../services/activitychannel";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import SkeletonLoading from "../SkeletonLoading";
import { activityChannelSelector } from "../../features/activityChannel/activityChannelSlice";
import { getAllTopicsExtended, getSubscribedTopics } from "../../services/activitychannel";
import { EyeIcon, PencilIcon } from "@heroicons/react/20/solid";
import { getInitials } from "../../utils/initials";
import { Role } from "../../interfaces/slicesInterface/activityChannel";

export default function Topics(props: TopicsProps): ReturnType<React.FC> {
    const { filteredTopics } = props;
    const dispatch = useAppDispatch();
    const user = useAppSelector(userDetailsSelector);
    const { allTopics, subscribedTopicIds, loading, error } =
        useAppSelector(activityChannelSelector);

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);

    const subscribe = async (topicId: any, topicName: string) => {
        let subscribe;
        try {
            subscribe = await subscribeTopic(user.username, topicId);
            if (subscribe.success) {
                dispatch(getAllTopicsExtended());
                dispatch(getSubscribedTopics());
                toast.success("Successfully Subscribed to " + topicName);
            } else {
                toast.error("Unable to Subscribe " + topicName);
            }
        } catch (error: any) {
            console.log(error.message);
        }
    };

    const unsubscribe = async (topicId: any, topicName: string) => {
        const subscribe = await unsubscribeTopic(user.username, topicId);
        if (subscribe.success) {
            dispatch(getAllTopicsExtended());
            dispatch(getSubscribedTopics());
            //   if (topicName === selectedTopic) {
            //     setSelectedTopic("Select a Topic");
            //     setSelectedTopicId("");
            //   }
            //   if (topicName === selectedFeedTopic) {
            //     handleSelectedTopicFeeds("ALL FEEDS", "");
            //     const feedsListContainer = document.querySelector(
            //       ".feeds-list-container"
            //     );
            //     const elements = feedsListContainer?.children;

            //     if (elements) {
            //       for (let i = 0; i < elements.length; i++) {
            //         const element = elements[i];
            //         if (element.classList.contains("active_feed")) {
            //           element.classList.remove("active_feed");
            //         }
            //       }
            //     }
            //     const feedElm = document.querySelector(
            //       "#all-feeds-li"
            //     ) as HTMLInputElement;
            //     feedElm.classList.add("active_feed");
            //   }
            toast.success("Unsubscribed from " + topicName);
        } else {
            toast.error("Unable to Unsubscribe from " + topicName);
        }
    };

    // const truncateName = (attachmentName: string, maxLength: number = 10) => {
    //     // const maxLength = 25;
    //     if (attachmentName.length > maxLength) {
    //         return <p title={attachmentName}>{attachmentName.substring(0, maxLength) + "..."}</p>;
    //     }
    //     return <p title={attachmentName}>{attachmentName}</p>;
    // };
    return (
        <>
            <div
                className={`w-full ${styles.height}  scrollbar overflow-y-scroll overflow-x-hidden flex flex-col justify-start items-center`}
            >
                {/* <div className="bg-red-500 sm:bg-yellow-500 md:bg-green-500 lg:bg-red-700 xl:bg-purple-500 p-1"></div> */}

                {loading ? (
                    <SkeletonLoading component="Topics" />
                ) : (
                    filteredTopics &&
                    filteredTopics.map((topic) =>
                        topic.permissions &&
                        !topic.permissions[user.role as Role].includes("none") ? (
                            <div
                                key={topic._id}
                                className="flex justify-between items-center sm:w-full  border-[0.5px] border-gray-400 p-2 xl:p-3 w-[96%] xl:w-[95%] rounded-lg shadow-sm my-2"
                            >
                                <div className="flex flex-center items-center gap-2">
                                    <span className="inline-flex h-8 w-8 xl:h-9 xl:w-9 items-center justify-center rounded-full bg-blue">
                                        <span className="text-[0.65rem] xl:text-sm leading-none text-white">
                                            TW
                                            {/* {topic.topic && getInitials(`${topic.topic}`)} */}
                                        </span>
                                    </span>
                                    <div className="float-end w-[65%]">
                                        <p className="text-[0.65rem] leading-[1rem] xl:text-sm   break-all  ">
                                            {topic.topic}
                                        </p>
                                        <p className="flex gap-2  items-center ">
                                            {topic.permissions &&
                                            topic.permissions[user.role as Role].includes(
                                                "read"
                                            ) ? (
                                                <EyeIcon className="h- w-3 text-blue " />
                                            ) : (
                                                ""
                                            )}
                                            {topic.permissions &&
                                            topic.permissions[user.role as Role].includes(
                                                "write"
                                            ) ? (
                                                <PencilIcon className="h-3 w-3 text-blue" />
                                            ) : (
                                                ""
                                            )}
                                        </p>
                                    </div>
                                </div>
                                {subscribedTopicIds.includes(topic._id) ? (
                                    <button
                                        type="button"
                                        className="inline-flex items-center gap-x-1.5 rounded-md bg-blue px-2 py-1 text-[0.65rem] leading-[1rem] xl:text-sm font-normal text-white shadow-md hover:bg-indigo-500 focus-visible:outline border-[0.5px] border-transparent focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue"
                                        onClick={() => unsubscribe(topic._id, topic.topic)}
                                    >
                                        Unsubscribe
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-2 py-1 text-[0.65rem] leading-[1rem] xl:text-sm font-normal text-gray-900 shadow-md hover:bg-gray-50 border-[0.5px] border-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                                        onClick={() => subscribe(topic._id, topic.topic)}
                                    >
                                        Subscribe
                                    </button>
                                )}
                            </div>
                        ) : null
                    )
                )}
            </div>
        </>
    );
}
