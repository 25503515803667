import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { scaleResultionInterface } from "../../interfaces/slicesInterface/scaleResolution";

const initialState: scaleResultionInterface = {
  highResUser: {},
  screenControlUsername: "",
};

const scaleResolutionSlice = createSlice({
  name: "scaleresolution",
  initialState,
  reducers: {
    addHighResUser: (state, action: PayloadAction<any>) => {
      state.highResUser[action.payload.username] = action.payload.streamType;
    },
    removeHighResUserStreamType: (state, action: PayloadAction<any>) => {
      state.highResUser[action.payload.username] = action.payload.streamType;
    },
    removeHighResUser: (state, action: PayloadAction<any>) => {},
    setScreenControlUsername: (state, action: PayloadAction<any>) => {
      state.screenControlUsername = action.payload;
    },
    clearScaleResolutionReducer: (state) => {
      return initialState;
    },
  },
  extraReducers(builder) {},
});

export const scaleResolutionSelector = (state: RootState) => state.meetingRoom;
export const {
  addHighResUser,
  removeHighResUserStreamType,
  clearScaleResolutionReducer,
  setScreenControlUsername,
} = scaleResolutionSlice.actions;
export default scaleResolutionSlice.reducer;
