import { authRequest } from ".";
import { ForgotPassword } from "../interfaces/formsInterface/forgotPwd";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const sendResetPwdLink = async (body: ForgotPassword) => {
  const passwordResetResponse = await authRequest.post(
    "/v1/api/forgot-password",
    body,
    config
  );
  return passwordResetResponse.data;
};

export const checkTokenValid = async (token: string) => {
  const reqBody = {
    token,
  };
  const checkTokenValidResponse = await authRequest.post(
    "/v1/api/check-reset-password-link-expiry",
    reqBody,
    config
  );
  return checkTokenValidResponse.data;
};

export const changePassword = async (token: string | undefined, password: string) => {
  const reqBody = {
    token,
    password,
  }
  const changePasswordResponse = await authRequest.post(
    "/v1/api/reset-password",
    reqBody,
    config
  );
  return changePasswordResponse.data;
};
