import DashboardLayout from "../DashboardLayout";
import styles from './styles.module.css';
import LeftImage from '../../assets/images/left_face.png';
import RightImage from '../../assets/images/right_face.png';
import CenterImage from '../../assets/images/center_face.png';
import { XMarkIcon, CheckIcon, CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import Video from "../Video";
import Button from "../Button";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { deviceSelector } from "../../features/device/deviceSlice";
import { useEffect, useRef, useState } from "react";
import { getVideoStream } from "../../utils/AudioVideoDevices";
import { environmentSelector } from "../../features/environment/environmentSlice";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import { checkRegistrationStatus, faceRegistrationService } from "../../services/faceRegistration";
import { faceRegistrationSelector } from "../../features/faceRegistration/faceRegistration";

export default function FaceRegistration() {
    const devices = useAppSelector(deviceSelector);
    const environments = useAppSelector(environmentSelector);
    const faceRegistration = useAppSelector(faceRegistrationSelector);

    const dispatch = useAppDispatch();

    const user = useAppSelector(userDetailsSelector);
    const [localStream, setLocalStream] = useState<any>();
    const [isRegistered, setIsRegistered] = useState(false);
    const [isRejected, setIsRejected] = useState<boolean>(false);
    const [isVerified, setIsVerified] = useState<boolean>(false);
    const registrationURL = environments.environments?.REACT_APP_FACE_REGISTRATION?.value || '';
    const faceDetectionToken = environments.environments?.REACT_APP_DETECTION_TOKEN?.value || '';
    const spacesConfig = environments.environments?.REACT_APP_SPACES_CONFIG?.data[0] || '';
    const companyName = environments.environments?.REACT_APP_COMPANY_NAME?.value || '';
    const videoRef = useRef<any>(null);

    const setStream = async () => {
        try {
            const localVideoStream = await getVideoStream(devices.activeVideoDevice);
            setLocalStream(localVideoStream);
        } catch (err) {
            console.log(err);
        }
    }

    const captureScreenshotAndRegister = () => {
        if (videoRef.current) {
            const offscreen = document.createElement("canvas");
            offscreen.width = videoRef.current.videoWidth;
            offscreen.height = videoRef.current.videoHeight;
            const ctx = offscreen.getContext("2d");
            if (ctx) {
                ctx.drawImage(videoRef.current, 0, 0, offscreen.width, offscreen.height);
                const imageData = offscreen.toDataURL("image/jpeg");

                const bodyData = {
                    url: registrationURL,
                    custId: user.custId,
                    userId: user.userId,
                    username: user.username,
                    client: environments.environments?.STORE_DETECTION_IMAGES?.value,
                    image: imageData,
                    remove_existing: true,
                    spacesConfig,
                    companyName,
                    faceDetectionToken,
                }

                dispatch(faceRegistrationService(bodyData));
            }
        }
    };

    const handleRegisterAgain = (e: any) => {
        e.preventDefault();
        setIsRegistered(false);
        setIsRejected(false);
        setIsVerified(false);
        dispatch(checkRegistrationStatus());
    }

    useEffect(() => {
        dispatch(checkRegistrationStatus());
    }, [dispatch])

    useEffect(() => {
        setIsRegistered(faceRegistration.isRegistered);
    }, [faceRegistration.isRegistered]);

    useEffect(() => {
        setIsRejected(faceRegistration.isRejected);
    }, [faceRegistration.isRejected]);

    useEffect(() => {
        setIsVerified(user.faceVerification.isVerified);
    }, [user.faceVerification.isVerified])

    useEffect(() => {
        if (devices.activeVideoDevice) {
            setStream();
        }
    }, [devices]);

    useEffect(() => {
        return () => {
            if (localStream) {
                localStream.getVideoTracks().forEach((track: any) => {
                    track.stop();
                });
            }
        }
    }, [localStream]);

    return (
        <DashboardLayout>
            <div className={`w-full bg-white rounded-2xl pt-3 pb-1 flex flex-col justify-start scrollbar items-start ${styles.dynamic_height} overflow-y-auto`}>
                <div className="flex w-full justify-between items-center px-6">
                    <h2 className="font-sans font-medium xl:text-3xl lg:text-2xl text-gray-900 self-stretch">Face Registration</h2>
                    {isRegistered && <Button type="primary" children="Register Again" onClick={handleRegisterAgain} />}
                </div>
                <div className="w-full border-t-[0.5px] border-gray-400 mt-2 mb-4" />
                <div className="bg-gray-50 border-[1px] border-gray-300 rounded-2xl p-2 w-[97%] mx-auto flex justify-between items-start ">
                    <div className="flex flex-col justify-start items-start w-[55%]">
                        <h2 className="text-xl text-gray-900">Instructions</h2>
                        <ul className="text-sm list-disc pl-7 leading-7 text-gray-500">
                            <li>Click the image once it is aligned in the boundary.</li>
                            <li>Preferably look for a plain background.</li>
                            <li>No light reflections in your glasses in case you wear glasses.</li>
                            <li>Below for your references on how to position your face within the boundary.</li>
                        </ul>
                    </div>
                    <div className="flex justify-between items-center w-[40%]">
                        <div className="flex flex-col justify-between items-center">
                            <img src={LeftImage} alt="left_image" />
                            <XMarkIcon className="p-2 text-red-600 bg-red-50 rounded-xl w-10" />
                        </div>
                        <div className="flex flex-col justify-between items-center">
                            <img src={CenterImage} alt="center_image" />
                            <CheckIcon className="p-2 text-green-500 bg-green-100 rounded-xl w-10" />
                        </div>
                        <div className="flex flex-col justify-between items-center">
                            <img src={RightImage} alt="right_image" />
                            <XMarkIcon className="p-2 text-red-600 bg-red-50 rounded-xl w-10" />
                        </div>
                    </div>
                </div>

                <div className="w-[50%] pb-8 mx-auto mt-10 flex flex-col justify-center items-center">
                    {!isRegistered && !isVerified ? (
                        <>
                            <Video className={`border-4 border-blue video-elem ${faceRegistration.loading ? styles.webcam : ''}`} src={localStream} reference={videoRef} />
                            <Button type="primary" children="Capture/Register" className="mx-auto my-5" onClick={captureScreenshotAndRegister} />
                        </>
                    ) : isRegistered && isRejected ? (
                        <>
                            <ExclamationCircleIcon className="text-red-600 w-4/5" />
                            <div className="bg-red-50 rounded-xl p-4 text-gray-900 font-semibold text-center">Your Registration has been Rejected. Try Re-registration</div>
                        </>
                    ) : isRegistered && !isVerified ? (
                        <>
                            <ExclamationCircleIcon className="text-yellow-400 w-4/5" />
                            <div className="bg-yellow-400 rounded-xl p-4 text-gray-900 font-semibold text-center">Correctly Scanned Image, Awaiting Approval</div>
                        </>
                    ) : (
                        <>
                            <CheckCircleIcon className="text-green-500 w-4/5" />
                            <div className="bg-green-100 rounded-xl p-4 text-green-500 font-semibold text-center">Face Registration Successful</div>
                        </>
                    )}
                </div>
            </div>
        </DashboardLayout>
    );
}
