import { createAsyncThunk } from "@reduxjs/toolkit";
import { utilsRequest } from ".";
import { getToken } from "../utils/Token";

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "",
  },
};
export const getEnvironments = createAsyncThunk(
  "/v1/api/environment/getEnv",
  async () => {
    config["headers"]["Authorization"] = getToken();
    const response = await utilsRequest.get("/v1/api/environments/get", config);
    return response.data;
  }
);



export const getEnvironmentByCustCode = async (data:any) => {
  config["headers"]["Authorization"] = getToken();
  const response = await utilsRequest.post("/v1/api/environments/get-env-by-custCode",data,config);
  return response.data;
}