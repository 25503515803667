import LoginType from "../../interfaces/componentsInterface/login";
import loginImage from "../../assets/images/login.png";
import LoginCard from "../../components/LoginCard";
import ForgotPassword from "../../components/ForgotPassword";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import React from "react";
import { loginSelector } from "../../features/login/loginSlice";
import { Navigate } from "react-router-dom";
import {
  getAudioPermission,
  getVideoPermission,
} from "../../utils/AudioVideoDevices";
import toast from "react-hot-toast";
import MFA from "../../components/MFA";
import { checkLogin } from "../../services/login";
import { getUsersLocation } from "../../utils/LocationUtils";

export default function Login(props: LoginType): ReturnType<React.FC> {
  const dispatch = useAppDispatch();
  const loginState = useAppSelector(loginSelector);

  const [forgotPassword, setForgotPassword] = React.useState<boolean>(false);
  const [custCode, setCustCode] = React.useState<string>("");
  const [username, setUsername] = React.useState<string>("");



  React.useEffect(() => {
    const requestAudioDevicePermissions = async () => {
      try { 
        const audioPermissionStatus = await navigator.permissions.query({ name: 'microphone' as PermissionName }); 
        const videoPermissionStatus = await navigator.permissions.query({ name: 'camera' as PermissionName }); 
        console.log({ videoPermissionStatus })
        if (audioPermissionStatus.state !== 'granted') {
          await getAudioPermission();
        }

        if (videoPermissionStatus.state !== 'granted') {
          await getVideoPermission();
        }

        if (audioPermissionStatus.state === 'denied' || videoPermissionStatus.state === 'denied') {
          toast("Please provide mic and video access", {
            icon: "📌",
          });
        }
      } catch (err: any) {
        toast("Please provide mic and video access", {
          icon: "📌",
        });
        console.error(err);
      }
    };
    const requestLocationAccessPermission = async () => {
      try {
        await getUsersLocation();
      } catch (err) {
        console.log(err);
      }
    };
    requestAudioDevicePermissions();
    requestLocationAccessPermission();
  }, []);

  if (loginState.isLoggedIn) {
    return <Navigate to="/dashboard" replace={true} />;
  }

  console.log({ loginState });

  return (
    <>
      <div style={{ backgroundImage: `url(${loginImage})` }} className="bg-cover  w-screen h-screen flex justify-center items-center overflow-hidden ">
        {

          loginState.mfa ?
            <MFA username={username} custCode={custCode} /> : (forgotPassword ? <ForgotPassword setForgotPassword={setForgotPassword} /> : <LoginCard setForgotPassword={setForgotPassword} setUsername={setUsername} setCustCode={setCustCode} />)
        }


      </div>
    </>
  );
}
