import React, { useEffect } from "react";
import DashboardLayout from "../DashboardLayout";
import styles from './styles.module.css'
import Button from "../Button";
import { CheckCircleIcon, InformationCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";

import { getPendingVerifications, verifyAllUsers, rejectAllUsers } from "../../services/adminVerification";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { adminVerificationSelector, updateCount } from "../../features/adminVerification/adminVerification";


export default function AdminVerification(): ReturnType<React.FC> {


    const dispatch = useAppDispatch();
    const adminVerification = useAppSelector(adminVerificationSelector);
    const [isAllSelected, setIsAllSelected] = React.useState<boolean>(false);
    const [userNames, setUsernames] = React.useState<Array<string>>([]);


    const getExtractedString = (str: string) => {
        return str.replace(/^\(|\)$/g, "");
    }


    const handleImageClick = (e: any, ele: any) => {
        setIsAllSelected(false);
        // const extractedString = getExtractedString(
        //     e.target.previousSibling.innerText
        // );

        // console.log({ ele });

        if (!userNames.includes(ele._id)) {
            e.target.classList.add(styles.border);
        } else {
            e.target.classList.remove(styles.border);
        }
        // console.log(extractedString);
        // removeSelect(e.target);
        setUsernames((prev) => {
            if (!prev.includes(ele._id)) {
                return [...prev, ele._id];
            } else {
                return prev.filter((elem) => elem !== ele._id);
            }
        });
    }


    const handleDeSelectAll = (e: any) => {
        setIsAllSelected(false);

        console.log(document.querySelector("#images_container")?.children);
        // let usernames: string[] = [];

        const parentElement = document.querySelector("#images_container");

        const childElements = parentElement?.children;

        if (childElements && childElements.length > 0) {
            for (let i = 0; i < childElements?.length; i++) {
                childElements[i]?.children[1].classList.remove(styles.border);
            }
        }
        // }

        setUsernames([]);
    }

    const handleSelectAll = (e: any) => {
        setIsAllSelected(true);
        // console.log(document.querySelector("#images_container")?.children);
        let usernames: string[] = [];

        const parentElement = document.querySelector("#images_container");

        const childElements: any = parentElement?.children;

        console.log({ childElements })
        if (childElements && childElements.length > 0) {
            for (let i = 0; i < childElements?.length; i++) {
                childElements[i]?.children[1].classList.add(styles.border);
            }
        }

        for (let i = 0; i < adminVerification.pendingUsers.length; i++) {
            usernames.push(adminVerification.pendingUsers[i]._id)
        }

        setUsernames(usernames);
    }




    const verifyUsers = () => {
        if (userNames.length < 0) {
            return;
        }

        const newCount = adminVerification.count - userNames.length;
        dispatch(verifyAllUsers(userNames))
        dispatch(updateCount(newCount));
        setUsernames([])
        setIsAllSelected(false)


    }

    const rejectUsers = () => {
        if (userNames.length < 0) {
            return;
        }
        const newCount = adminVerification.count - userNames.length
        dispatch(rejectAllUsers(userNames))
        dispatch(updateCount(newCount))
    setUsernames([])
    setIsAllSelected(false);
    }



    useEffect(() => {
        dispatch(getPendingVerifications())
    }, [dispatch])



    return (
        <>
            <DashboardLayout>
                <div className={`w-full bg-white rounded-2xl pt-3 pb-1 flex flex-col justify-start scrollbar items-start ${styles.dynamic_height} overflow-y-auto`}>
                    <div className="container flex justify-between pl-6 pb-3 pr-6 border-b-[1px] border-gray-300">
                        <h2 className=" font-sans font-medium xl:text-3xl lg:text-2xl text-gray-900 self-stretch">Admin Verification</h2>

                        {!isAllSelected ? (
                            <Button type="primary" style={{
                                opacity: adminVerification.pendingUsers.length <= 0 ? 0.7 : 1,
                                cursor: adminVerification.pendingUsers.length <= 0 ? "auto" : "pointer",
                            }} children="Select All" disabled={adminVerification.pendingUsers.length <= 0} onClick={handleSelectAll} className={`inline-flex items-center  rounded-md bg-blue px-3 py-2 text-sm font-semibold text-white shadow-md hover:bg-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue`} />
                        ) : (
                            <Button type="primary" style={{
                                opacity: userNames.length <= 0 ? 0.7 : 1,
                                cursor: userNames.length <= 0 ? "auto" : "pointer",
                            }} children="Unselect All" disabled={userNames.length <= 0} onClick={handleDeSelectAll} className={`inline-flex items-center  rounded-md bg-blue px-3 py-2 text-sm font-semibold text-white shadow-md hover:bg-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue`} />
                        )}
                        <div className=" flex justify-center items-center gap-4">
                            <Button type="primary" children="Verify"
                                onClick={verifyUsers}
                                disabled={userNames.length <= 0}
                                style={{
                                    opacity: userNames.length <= 0 ? 0.7 : 1,
                                    cursor: userNames.length <= 0 ? "auto" : "pointer",
                                }}
                                Icon={CheckCircleIcon} className="inline-flex items-center  rounded-md bg-blue px-3 py-2 text-sm font-semibold text-white shadow-md hover:bg-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue" />
                            <Button type="secondary" children="Reject" onClick={rejectUsers}
                                disabled={userNames.length <= 0}
                                style={{
                                    opacity: userNames.length <= 0 ? 0.7 : 1,
                                    cursor: userNames.length <= 0 ? "auto" : "pointer",
                                }}
                                Icon={XCircleIcon} className="inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-none" />
                        </div>


                    </div>
                    <div className="rounded-md bg-blue-50 p-4 mx-auto w-4/5 mt-2 flex ">
                        <div className="flex justify-center items-center">
                            <div className="flex-shrink-0">
                                <InformationCircleIcon className="h-5 w-5 text-blue" aria-hidden="true" />
                            </div>
                            <div className="ml-3 flex-1 md:flex md:justify-between">
                                <p className="text-sm text-blue-700">Please select an image for verification by clicking on it, or choose the <b className="text-blue">Select All</b> button to verify/reject all images.</p>

                            </div>
                        </div>
                    </div>

                    <div id="images_container" className=" w-full grid grid-cols-5 justify-center items-center gap-5 p-5 pb-9 ">
                        {adminVerification.pendingUsers && adminVerification.pendingUsers.length > 0
                            ? adminVerification.pendingUsers.map((ele: any, index: number) => {
                                return (
                                    <div key={index} className="flex justify-center items-center flex-col mt-7 relative">
                                        <p className=" text-sm mb-1 text-white uppercase absolute bottom-0 bg-gray-900 w-[95%] truncate text-center opacity-80 rounded-sm">({ele.username})</p>
                                        <img
                                            className=" w-48 h-36 rounded-lg cursor-pointer p-[2px]"
                                            id={`image-${ele.index}`}
                                            src={ele && ele?.faceVerification.image}
                                            alt="user-img"
                                            onClick={(e) => handleImageClick(e, ele)}
                                        />
                                    </div>
                                );
                            })
                            : ""}
                    </div>

                </div>
            </DashboardLayout>

        </>
    )
}