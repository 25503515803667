import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

const initialState = {
    troubleshoot: false
}

const troubleshootSlice = createSlice({
    name: "troubleshoot",
    initialState,
    reducers: {
        toggleTroubleshoot: (state, action: PayloadAction<boolean>) => {
            if(action.payload) {
                state.troubleshoot = false
            } else {
                state.troubleshoot = true;
            }
        }
    }
});

export const troubleshootSelector = (state: RootState) => state.troubleshoot;
export const { toggleTroubleshoot } = troubleshootSlice.actions
export default troubleshootSlice.reducer;