
import { useNavigate } from "react-router-dom"
import Image404 from '../../assets/images/404.jpg'
import { useEffect, useState } from "react"
import routes from "../../constants/routes";


export default function ErrorPage(): ReturnType<React.FC> {

    const navigate = useNavigate();
    const [count, setCount] = useState(5);

    useEffect(() => {
        const interval = setInterval(() => {
            setCount((prev) => prev - 1)
        }, 1000)

        return () => clearInterval(interval);
    }, [])



    useEffect(() => {
        if (count === 0) {
            navigate(routes.dashboard);
        }
    }, [count, navigate])

    return (

        <>

            <div className=" w-screen h-screen flex flex-col justify-center items-center">
                <img className="h-[30%] w-[25%]  shadow-md rounded-xl" src={Image404} alt="404" />

                <h1 className="font-sans text-lg text-gray-900 font-semibold mt-6">OOPS!! 404 Page Not found!</h1>
                <p className="font-sans text-mg text-red-600 font-normal text-base">Redirecting you in {count} secs.</p>
            </div>


        </>
    )
}