import React, { useState } from "react";
import {
    EditorState,
    AtomicBlockUtils,
    ContentState,
    convertFromHTML,
    CompositeDecorator,
    RichUtils,
    convertFromRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import { getToken } from "../../utils/Token";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import { stateToHTML } from "draft-js-export-html";
import { useAppSelector } from "../../app/hooks";
import { environmentSelector } from "../../features/environment/environmentSlice";
import { FILE_UPLOADS_TOPICS } from "../../utils/constant";

// TypeScript types
interface CustomFileUploadButtonProps {
    editorState: EditorState;
    onChange: (editorState: EditorState) => void;
    setUploadLoading: (content: boolean) => void;
}

interface EditorComponentProps {
    initialData: string;
    setEditorContent: (content: string) => void;
}

// Custom button component for file upload
const CustomFileUploadButton: React.FC<CustomFileUploadButtonProps> = ({
    editorState,
    onChange,
    setUploadLoading,
}) => {
    const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const formData = new FormData();
            formData.append("file", file);

            try {
                setUploadLoading(true);
                const response = await axios.post(
                    `${process.env.REACT_APP_ACTIVITY_CHANNEL_URL}/v1/api/feeds/upload`,
                    formData,
                    {
                        headers: {
                            Authorization: getToken(),
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                console.log({ response });
                setUploadLoading(false);
                const fileUrl = response.data.location; // Assume the API returns the uploaded file URL
                const fileType = file.type;

                const contentState = editorState.getCurrentContent();
                let contentStateWithEntity;

                if (fileType.startsWith("image/")) {
                    contentStateWithEntity = contentState.createEntity("IMAGE", "IMMUTABLE", {
                        src: fileUrl,
                    });
                } else if (fileType === "application/pdf") {
                    contentStateWithEntity = contentState.createEntity("LINK", "IMMUTABLE", {
                        url: fileUrl,
                    });
                }

                if (contentStateWithEntity) {
                    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
                        EditorState.set(editorState, {
                            currentContent: contentStateWithEntity,
                        }),
                        entityKey,
                        fileUrl
                    );

                    onChange(
                        RichUtils.toggleLink(
                            newEditorState,
                            newEditorState.getSelection(),
                            entityKey
                        )
                    );
                } else {
                    console.error("Error creating entity: contentStateWithEntity is undefined");
                }
            } catch (error) {
                setUploadLoading(false);
                console.error("Error uploading file:", error);
            }
        }
    };

    return (
        <div>
            <input
                type="file"
                accept="image/*,application/pdf"
                className="hidden"
                id="input-file"
                onChange={handleFileInput}
            />
            <label htmlFor="input-file" title="Attach File/Image" className=" cursor-pointer">
                <div className="  w-5 p-1 cursor-pointer flex justify-center items-center">
                    <p className="">
                        <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                    </p>
                </div>
            </label>
        </div>
    );
};

const Link = (props: any) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    console.log("i called Link", url);
    return (
        <a href={url} style={{ textDecoration: "underline", color: "blue" }}>
            {url}
        </a>
    );
};

const convertHtmlToContentState = (html: string): EditorState => {
    console.log({ html });
    const blocksFromHTML = convertFromHTML(html);
    console.log({ blocksFromHTML });
    const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
    );

    const newEditorState = EditorState.createWithContent(state);
    return newEditorState;
};
const linkStrategy = (contentBlock: any, callback: any, contentState: any) => {
    contentBlock.findEntityRanges((character: any) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === "LINK";
    }, callback);
};

const decorator = new CompositeDecorator([{ strategy: linkStrategy, component: Link }]);

const TextEditor: React.FC<EditorComponentProps> = ({ initialData, setEditorContent }) => {
    const calcState = (value: any) => {
        return value ? convertHtmlToContentState(initialData) : EditorState.createEmpty(decorator);
    };
    const [editorState, setEditorState] = useState(calcState(initialData));
    const [uploadLoading, setUploadLoading] = useState<boolean>(false);

    const environments = useAppSelector(environmentSelector);
    const onEditorStateChange = (editorState: EditorState) => {
        setEditorState(editorState);
        const contentState = editorState.getCurrentContent();
        const html = stateToHTML(contentState);
        console.log({ html });
        // Regular expression to remove <figure> tags wrapping <a> tags
        const processedHtml = html.replace(/<figure>(\s*<a[^>]*>.*?<\/a>\s*)<\/figure>/gi, "$1");
        const processedAddBlankHtml = processedHtml.replace(
            /<a\s+([^>]*href=[^>]*)>/gi,
            '<a $1 target="_blank">'
        );
        console.log({ processedAddBlankHtml });
        setEditorContent(processedAddBlankHtml);
    };
    const fileUploadsTopics = environments.environments && environments.environments.FILE_UPLOADS_TOPICS ? environments.environments.FILE_UPLOADS_TOPICS.status : FILE_UPLOADS_TOPICS



    return (
        <div>
            {uploadLoading && (
                <div className="fixed h-screen inset-0 flex items-center justify-center bg-transparent bg-opacity-50 z-50">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-gray-500 backdrop-blur-sm motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>
            )}
            <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                    options: [
                        "history",
                        "inline",
                        "blockType",
                        "fontSize",
                        "fontFamily",
                        "list",
                        "textAlign",
                        "emoji",
                    ],
                    inline: {
                        inDropdown: true,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: [
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                            "monospace",
                            "superscript",
                            "subscript",
                        ],
                    },
                    blockType: {
                        inDropdown: true,
                        options: ["Normal", "Blockquote", "Code"],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    },
                    list: {
                        inDropdown: true,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ["unordered", "ordered", "indent", "outdent"],
                    },
                    textAlign: {
                        inDropdown: true,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ["left", "center", "right", "justify"],
                    },
                }}



                toolbarCustomButtons={[
                    fileUploadsTopics ?
                        <CustomFileUploadButton
                            editorState={editorState}
                            onChange={setEditorState}
                            setUploadLoading={setUploadLoading}
                            key="file-upload-button"
                        /> : <></>,
                ]}
            />
        </div>
    );
};

export default TextEditor;
