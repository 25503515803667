const toBoolean = (string: string | undefined) => string === "true";

const cdnUrl = process.env.REACT_APP_CDN_BASE_URL;
const config = {
  audioToAllPeers: toBoolean(process.env.REACT_APP_AUDIO_TO_ALL),
  isRecordingEnabled: toBoolean(process.env.REACT_APP_RECORDING_ENABLED),
  centralAudioOptions: {
    sampleRate: 48000, // Default : 96khz / will not eat that much bandwidth thanks to opus
    channelCount: 1, // Default : 1 / usually mics are mono so this saves bandwidth
    volume: 1.0, // Default : 1.0
    sampleSize: 16, // Default : 16
    opusStereo: false, // Default : false / usually mics are mono so this saves bandwidth
    opusDtx: true, // Default : true / will save bandwidth
    opusFec: true, // Default : true / forward error correction
    opusPtime: 20, // Default : 20 / minimum packet time (3, 5, 10, 20, 40, 60, 120)
    opusMaxPlaybackRate: 96000,
  },
  frameRate: 10, // Reduced frame rate for better performance
  screenSharingFrameRate: 5, // Reduced screen sharing frame rate
  humanApi: {
    warmup: "none",
    backend: "wasm",
    wasmPath: "https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@3.9.0/dist/",
    modelBasePath: `${cdnUrl}/models`,
    filter: { enabled: false },
    gesture: { enabled: false },
    face: {
      enabled: true,
      detector: {
        minConfidence: 0.6, // Adjusted for less false positives
      },
      mesh: {
        enabled: false,
      },
      iris: {
        enabled: false,
      },
      description: {
        enabled: false,
      },
      emotion: {
        enabled: false, // Disabled emotion detection
      },
    },
    body: { enabled: false },
    hand: { enabled: false },
    object: { enabled: false },
    segmentation: { enabled: false },
  },
  pwa: {
    enabled: true,
    cacheName: "CollabRoomHuman",
    scriptFile: "/sw.js",
    cacheModels: true,
    cacheWASM: true,
    cacheOther: false,
  },
  cdnUrl: cdnUrl,
  tinyMCE: process.env.REACT_APP_TINYMCE_API_KEY
};

export default config;
