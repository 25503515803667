import { useEffect, useRef } from "react";
import { ScreenControlProps } from "../../interfaces/componentsInterface/screenControl";
import Video from "../Video";
import {
  mediaTypeConstant,
  resolutionConstant,
  socketConnectiontype,
} from "../../utils/constant";
import SocketService from "../../services/socket";
import { useAppSelector } from "../../app/hooks";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import streamService from "../../services/streamService";
import { screenControlSelector } from "../../features/screenControl/screenControlSlice";

const streamInstance = new streamService().getInstance();

const socketInstanceDesktopApp = new SocketService(
  socketConnectiontype.desktopApp
).getInstance(socketConnectiontype.desktopApp);

const mediaSocketInstance = new SocketService(
  socketConnectiontype.media
).getInstance(socketConnectiontype.media);
export default function ScreenControl(
  props: ScreenControlProps
): ReturnType<React.FC> {
  let screenDiv = useRef({} as HTMLDivElement);
  let scrollVal = { x: 0, y: 0 };
  let clearTime: any;

  const user = useAppSelector(userDetailsSelector);
  const { openentPeerId } = useAppSelector(screenControlSelector);

  const gettingMousePosition = (e: React.MouseEvent) => {
    // @ts-ignore
    let modalPosX = Math.ceil(document.querySelector("#video_screen_control").getBoundingClientRect()
        .left
    );
    // @ts-ignore
    let modalPosY = Math.ceil(document.querySelector("#video_screen_control").getBoundingClientRect()
        .top
    );
    let x = e.pageX - modalPosX + 1;
    let y = e.pageY - modalPosY + 2;

    const perY = ((y * 100) / screenDiv.current.clientHeight).toFixed(2);
    const perX = ((x * 100) / screenDiv.current.clientWidth).toFixed(2);

    return { perX, perY };
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    const getPosInPercentage = gettingMousePosition(e);
    console.log({ getPosInPercentage });
    socketInstanceDesktopApp.sendMessage("APP_CONTROL_ACTION", {
      type: "mouse-move",
      data: { x: getPosInPercentage.perX, y: getPosInPercentage.perY },
    });
  };

  const handleMouseScroll = (e: React.WheelEvent) => {
    scrollVal = { x: scrollVal.x + e.deltaX, y: scrollVal.y + e.deltaY };
    console.log({ scrollVal });
    clearTimeout(clearTime);
    clearTime = setTimeout(
      () =>
        socketInstanceDesktopApp.sendMessage("APP_CONTROL_ACTION", {
          type: "mouse-scroll",
          data: { x: scrollVal.x, y: scrollVal.y },
        }),
      300
    );
  };

  const handleLeftClick = (e: React.MouseEvent) => {
    e.preventDefault();
    socketInstanceDesktopApp.sendMessage("APP_CONTROL_ACTION", {
      type: "mouse-left-click",
      data: {},
    });
  };

  const handleRightClick = (e: React.MouseEvent) => {
    e.preventDefault();

    socketInstanceDesktopApp.sendMessage("APP_CONTROL_ACTION", {
      type: "mouse-right-click",
      data: {},
    });
  };

  const handleType = (e: React.KeyboardEvent) => {
    socketInstanceDesktopApp.sendMessage("APP_CONTROL_ACTION", {
      type: "keyboard-press",
      data: { key: e.key.toLowerCase() },
    });
  };

  useEffect(() => {
    // @ts-ignore
    document.addEventListener("keyup", handleType);
    mediaSocketInstance.sendMessage(
      "SETTING_SCALE_RESOLUTION_DOWN_FOR_SELECTED_MEDIA_TYPE",
      {
        peerUserId: openentPeerId,
        resolution: resolutionConstant.high,
        mediaType: mediaTypeConstant.screen,
        username: user.username,
        screenControl: true,
      }
    );
    // Remove event listeners on cleanup
    return () => {
      // @ts-ignore
      document.removeEventListener("keyup", handleType);
      mediaSocketInstance.sendMessage(
        "SETTING_SCALE_RESOLUTION_DOWN_FOR_SELECTED_MEDIA_TYPE",
        {
          peerUserId: openentPeerId,
          resolution: resolutionConstant.low,
          mediaType: mediaTypeConstant.screen,
          username: user.username,
          screenControl: true,
        }
      );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className=""
        ref={screenDiv}
        id="video_screen_control"
        onMouseMove={(e: React.MouseEvent) => {
          handleMouseMove(e);
        }}
        onClick={(e: React.MouseEvent) => {
          handleLeftClick(e);
        }}
        onContextMenu={(e: React.MouseEvent) => {
          handleRightClick(e);
        }}
        onWheel={(e: React.WheelEvent) => handleMouseScroll(e)}
      >
        {streamInstance && streamInstance?.peerRemoteStream && streamInstance.peerRemoteStream[openentPeerId] ? (
          <Video
            className="w-1/2"
            src={streamInstance.peerRemoteStream[openentPeerId].screenStream}
            mediaType={mediaTypeConstant.screen}
          />
        ) : null}
      </div>

      <button
        type="submit"
        className="flex w-1/3 mx-auto mt-2 justify-center items-center gap-1 rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900 border-2 border-gray-900 shadow-md hover:shadow-2xl hover:opacity-[0.9] "
        onClick={props.closeScreenModal}
      >
        Disconnect
      </button>
    </>
  );
}
