import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getRooms, getUsersCountsPerRoom } from "../../services/room";
import { RootState } from "../../app/store";
import {
  Room,
  scaleResultion,
} from "../../interfaces/componentsInterface/room";

const initialState = {
  rooms: [] as Room[],
  usersCount: {} as { [key: string]: number },
  loading: false,
  success: false,
  error: "",
  viewMode: "",
  currentRoom: "",
  isAudioAllowedInRoom : true,
  currentRoomId: "",
  currentRoomBussId:"",
  perPagePeerNum:0,
  screenScale: {} as scaleResultion,
  videoScale: {} as scaleResultion,
};

const roomSlice = createSlice({
  name: "room",
  initialState,
  reducers: {
    setRooms: (state, action: PayloadAction<Room[]>) => {
      state.rooms = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSuccess: (state, action: PayloadAction<boolean>) => {
      state.success = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setViewMode: (state, action: PayloadAction<string>) => {
      state.viewMode = action.payload;
    },
    setCurrentRoom: (state, action: PayloadAction<any>) => {   
      console.log({Payload:action.payload})
      state.currentRoom = action.payload.roomName;
      state.isAudioAllowedInRoom = action.payload.isAudioAllowedInRoom !== undefined ? action.payload.isAudioAllowedInRoom : true;
      state.currentRoomId = action.payload.currentRoomId;
      state.viewMode = action.payload.viewMode;
      state.screenScale = {
        ...action.payload.screenScale,
        HIGH: parseFloat(action.payload.screenScale.HIGH),
        LOW: parseFloat(action.payload.screenScale.LOW),
      };
      state.videoScale = {
        ...action.payload.videoScale,
        HIGH: parseFloat(action.payload.videoScale.HIGH),
        LOW: parseFloat(action.payload.videoScale.LOW),
      };
      state.perPagePeerNum = action.payload.perPagePeerNum;
      state.currentRoomBussId = action.payload.bussId;
    },
    clearCurrentRoom: (state) => {
      state.currentRoom = "";
      state.currentRoomId = "";
      state.viewMode = "";
      state.currentRoomBussId = "";
    },
    setScreenScale: (state, action: PayloadAction<scaleResultion>) => {
      state.screenScale = action.payload;
    },setVideoScale: (state, action: PayloadAction<scaleResultion>) => {
      state.videoScale = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getRooms.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRooms.fulfilled, (state, action: PayloadAction<any>) => {
      if (action.payload.success) {
        state.loading = false;
        state.rooms = action.payload.rooms;
        state.success = true;
      } else {
        state.loading = false;
        state.success = false;
        state.error = action.payload.message;
      }
    });
    builder.addCase(getRooms.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    });
    builder.addCase(getUsersCountsPerRoom.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsersCountsPerRoom.fulfilled, (state, action: PayloadAction<any>) => {
      if (action.payload.success) {
        state.loading = false;
        state.usersCount = action.payload.allUsersByRoom;
        state.success = true;
      } else {
        state.loading = false;
        state.success = false;
        state.error = action.payload.message;
      }
    });
    builder.addCase(getUsersCountsPerRoom.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const roomSelector = (state: RootState) => state.room;
export const {setCurrentRoom,setViewMode,setScreenScale,setRooms,setVideoScale, clearCurrentRoom} = roomSlice.actions;
export default roomSlice.reducer;
