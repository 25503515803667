import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PersonalizationState } from "../../interfaces/slicesInterface/personalization";
import { RootState } from "../../app/store";
import {
  getPersonalizationSettings,
  savePersonalizationSettings,
} from "../../services/personalization";
import toast from "react-hot-toast";

const initialState: PersonalizationState = {
  personalization: {},
  loading: false,
  success: false,
  error: "",
};

const personalizationSlice = createSlice({
  name: "personalization",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getPersonalizationSettings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getPersonalizationSettings.fulfilled,
      (state, action: PayloadAction<any>) => {
        if (action.payload.success) {
          state.loading = false;
          state.personalization = action.payload.personalization;
          state.success = true;
        } else {
          state.loading = false;
          state.success = false;
          state.error = action.payload.message;
        }
      }
    );
    builder.addCase(
      getPersonalizationSettings.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      }
    );
    builder.addCase(savePersonalizationSettings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      savePersonalizationSettings.fulfilled,
      (state, action: PayloadAction<any>) => {
        if (action.payload.success) {
          state.loading = false;
          state.success = true;
          toast.success(action.payload.message);
        }else{
          state.loading = false;
          state.success = false;
          state.error = action.payload.message;
          toast.error(action.payload.message);
        }
      }
    );
    builder.addCase(
      savePersonalizationSettings.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload.message;
      }
    );
  },
});

export const personalizationSelector = (state: RootState) =>
  state.personalization;
export default personalizationSlice.reducer;
