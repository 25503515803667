import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getAllSupervisorForManager } from "../../services/userDetails";


interface SupervisorState {
  allSupervisor: any;
  errorMessage: string;
  loading: boolean;
}

const initialState: SupervisorState = {
  allSupervisor: [],
  errorMessage: "",
  loading: false,
};



const supervisorSlice = createSlice({
  name: "allsupervisor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllSupervisorForManager.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getAllSupervisorForManager.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.allSupervisor = action.payload.supervisors; // Adjust based on the actual response structure
        }
      )
      .addCase(
        getAllSupervisorForManager.rejected,
        (state, action) => {
          state.loading = false;
          state.errorMessage = action.payload as string; // Handle error message
        }
      );
  },
});

export const {} = supervisorSlice.actions;
export const supervisorSelector = (state: RootState) => state.allSupervisor;
export default supervisorSlice.reducer;
