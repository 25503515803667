import React, { lazy, useEffect } from "react"



// import Rooms from '../../components/Rooms';
import DashboardLayout from "../../components/DashboardLayout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getEnvironments } from "../../services/environment";
import { exportGeoLocationForSSO, loginActiveTime } from "../../services/login";
import { getRooms } from "../../services/room";
import useInterval from "../../hooks/useInterval";
import { getPendingVerifications } from "../../services/adminVerification";
import { environmentSelector } from "../../features/environment/environmentSlice";
import { useNavigate } from "react-router-dom";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import { loginSelector } from "../../features/login/loginSlice";
import { getBackgroundActivate } from "../../services/userDetails";
const Rooms = lazy(() => import('../../components/Rooms')); 


export default function Dashboard({filteredRooms}:any): ReturnType<React.FC> {

    const dispatch = useAppDispatch()

  const environments = useAppSelector(environmentSelector);
  const {isComplexPassword,isLoggedIn} = useAppSelector(loginSelector);
  const {username,passwordUpdatedOn,complexPassword,custCode} = useAppSelector(userDetailsSelector);
  const navigate = useNavigate();
  console.log({passwordUpdatedOn,complexPassword})
    useEffect(() => {
        dispatch(getEnvironments());
        dispatch(loginActiveTime())
        localStorage.setItem("chatPopOut", "false");
    }, [dispatch])
    

    useEffect(()=>{
      dispatch(getBackgroundActivate())
    },[dispatch,username])

    useInterval(() => {
        dispatch(getPendingVerifications())
    }, 5000);

    useEffect(() => {
      const isSamlLogin = localStorage.getItem('isSamlLogin');
      if (isLoggedIn && custCode && isSamlLogin) {
        // Check local storage to determine if the function has been called
        const hasFunctionBeenCalled = localStorage.getItem('isGeoLocationExported');
        if (!hasFunctionBeenCalled) {
          exportGeoLocationForSSO({ custCode });
          // Set a flag in local storage
          localStorage.setItem('isGeoLocationExported', 'true');
        }
      }
      // Optional: Clear the flag if the user logs out
      // Add an effect here to listen for logout and clear the local storage item if needed
    }, [custCode, isLoggedIn]);

    
   
  

    if (passwordUpdatedOn !=="" &&
        environments?.environments?.REACT_APP_COMPLEX_PASSWORD &&
        environments?.environments?.REACT_APP_COMPLEX_PASSWORD.status
      ) {
        let today: any = new Date();
        let pwdDate: any = new Date(passwordUpdatedOn);
        const diffTime = Math.abs(today - pwdDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (
          ( environments?.environments?.REACT_APP_COMPLEX_PASSWORD.value !== 0  &&
            diffDays > environments?.environments?.REACT_APP_COMPLEX_PASSWORD.value) ||
            complexPassword === false ||
            isComplexPassword === false
        ) {
            navigate("/passwordchange?expires=true"); 
        }
      }

    return (
        <>
            <DashboardLayout>
                <Rooms filteredRooms={filteredRooms} />
            </DashboardLayout>

        </>
    )
}