"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var sdk_1 = require("./sdk");
var LZString = require("./lz-string");
var PROTOCOL_VERSION = "2.0";
var SIGNALLING_MESSAGES = [
    "watchrtc",
    "reconnect",
    "reconnectDuration",
    "collectionIntervalChange",
    "timeChange",
    "candidatePairIPs",
];
var isBrowser = typeof window !== "undefined";
var globalContext = isBrowser ? window : global;
var WatchRTCSocket = /** @class */ (function () {
    function WatchRTCSocket() {
        var _this_1 = this;
        this.connection = null;
        this.wasConnected = false;
        this.buffer = [];
        this.sendInterval = 1;
        this.onClose = function () { };
        this.dataCollection = true;
        this.sendPromises = [];
        this.signalsBuffer = [];
        this.timeouted = false;
        this.reconnectId = null; // Manage websocket connection timeout (Do not wait for more than 5 seconds)
        this.trace = function (_a) {
            var data = _a.data, options = _a.options;
            var signallingChannel = SIGNALLING_MESSAGES.includes(data[0]);
            var args = Array.prototype.slice.call(data);
            var now = Date.now();
            args.push(now);
            if (args[1] instanceof RTCPeerConnection) {
                args[1] = args[1].__rtcStatsId;
            }
            if (!WatchRTCSocket._instance.dataCollection) {
                if (options === null || options === void 0 ? void 0 : options.promiseFuncs) {
                    options.promiseFuncs.resolve({ error: "Data collection disabled" });
                }
                return;
            }
            // Don't store a new message if the connection is not open and the buffer is full (> 1000 messages)
            if (!WatchRTCSocket._instance.connection ||
                WatchRTCSocket._instance.connection.readyState !== globalContext.WebSocket.OPEN) {
                if (WatchRTCSocket._instance.buffer.length > 1000) {
                    if (options === null || options === void 0 ? void 0 : options.promiseFuncs) {
                        options.promiseFuncs.resolve({ error: "Message buffer size exceeded" });
                    }
                    return;
                }
            }
            // Else, store the message in the buffer
            var targetBuffer = signallingChannel ? _this_1.signalsBuffer : _this_1.buffer;
            targetBuffer.push(args);
            if (options === null || options === void 0 ? void 0 : options.promiseFuncs) {
                WatchRTCSocket._instance.sendPromises.push(options.promiseFuncs);
            }
            // If the connection is open and the buffer threshold is reached, send the messages
            if (WatchRTCSocket._instance.connection &&
                WatchRTCSocket._instance.connection.readyState === globalContext.WebSocket.OPEN) {
                if (signallingChannel) {
                    // empty signals buffer
                    for (var _i = 0, _b = _this_1.signalsBuffer; _i < _b.length; _i++) {
                        var args_1 = _b[_i];
                        var message = JSON.stringify(args_1);
                        WatchRTCSocket._instance.connection.send(message);
                    }
                    _this_1.signalsBuffer = [];
                    return;
                }
                if (WatchRTCSocket._instance.buffer.length >= WatchRTCSocket._instance.sendInterval) {
                    var lines = JSON.stringify(WatchRTCSocket._instance.buffer);
                    var compressedMessage = LZString.compressToEncodedURIComponent(lines);
                    (0, sdk_1.sdkLogWithoutTrace)("debug", "lines: ".concat(lines.length));
                    (0, sdk_1.sdkLogWithoutTrace)("debug", "compressedMessage: ".concat(compressedMessage.length));
                    WatchRTCSocket._instance.buffer = [];
                    WatchRTCSocket._instance.connection.send(compressedMessage);
                    WatchRTCSocket._instance.sendPromises.forEach(function (_a) {
                        var resolve = _a.resolve;
                        return resolve({});
                    });
                    WatchRTCSocket._instance.sendPromises = [];
                }
            }
        };
        if (WatchRTCSocket._instance) {
            (0, sdk_1.sdkLog)("info", "WatchRTCSocket instance already created");
        }
        else {
            WatchRTCSocket._instance = this;
        }
    }
    WatchRTCSocket.prototype.connect = function (_a) {
        var url = _a.url, onData = _a.onData, onError = _a.onError, onOpen = _a.onOpen, onClose = _a.onClose, _b = _a.options, options = _b === void 0 ? {} : _b;
        var cleanOldTraces = options.cleanOldTraces;
        if (WatchRTCSocket._instance.connection) {
            WatchRTCSocket._instance.connection.close();
            WatchRTCSocket._instance.connection = null;
        }
        var _this = WatchRTCSocket._instance;
        if (cleanOldTraces) {
            // we want to keep last 10 minutes of logs, to prevent old logs timestamp impacting actual session duration
            var timeThreshold_1 = Date.now() - 10 * 60 * 1000; // 10 min
            var relevantTraces = _this.buffer.filter(function (data) { return data[3] > timeThreshold_1; });
            var filteredCount = _this.buffer.length - relevantTraces.length;
            _this.buffer = relevantTraces;
            var args = ["oldTracesCleanup", null, { filteredCount: filteredCount }, Date.now()];
            (0, sdk_1.sdkLog)("info", "watchRTC SDK flushed ".concat(filteredCount, " messages"));
            _this.buffer.push(args);
        }
        if (_this.reconnectId) {
            clearTimeout(_this.reconnectId);
        }
        _this.timeouted = false;
        _this.reconnectId = setTimeout(function () {
            var _a;
            (0, sdk_1.sdkLogWithoutTrace)("info", "websocket connexion timeout");
            try {
                (_a = _this.connection) === null || _a === void 0 ? void 0 : _a.close();
            }
            catch (err) {
                (0, sdk_1.sdkLog)("error", "\n", { err: err.stack });
            }
            _this.connection = null;
            _this.timeouted = true;
        }, 15000);
        WatchRTCSocket._instance.connection = new globalContext.WebSocket(url, PROTOCOL_VERSION);
        WatchRTCSocket._instance.connection.onopen = function () {
            // Close the reconnect timeout if it exists
            if (_this.reconnectId) {
                clearTimeout(_this.reconnectId);
                _this.reconnectId = null;
            }
            if (onOpen) {
                onOpen();
            }
        };
        WatchRTCSocket._instance.connection.onclose = onClose || null;
        WatchRTCSocket._instance.connection.onmessage = function (e) {
            var _a;
            try {
                var data = JSON.parse(e.data);
                if (data.error) {
                    (_a = _this === null || _this === void 0 ? void 0 : _this.connection) === null || _a === void 0 ? void 0 : _a.close();
                    _this.connection = null;
                    (0, sdk_1.sdkLog)("error", "\n" + data.error);
                    onError(data.error, "auth");
                }
                else {
                    if (data.sendInterval) {
                        WatchRTCSocket._instance.sendInterval = data.sendInterval;
                    }
                    onData(data);
                    WatchRTCSocket._instance.wasConnected = true;
                }
            }
            catch (err) {
                (0, sdk_1.sdkLog)("error", "\n", { err: err.stack });
                onError(err.message);
            }
        };
        WatchRTCSocket._instance.connection.onerror = function (e) {
            (0, sdk_1.sdkLog)("error", "\n", e);
            if (WatchRTCSocket._instance.connection.bufferedAmount > 0) {
                (0, sdk_1.sdkLog)("error", "socket buffer was not empty - ".concat(WatchRTCSocket._instance.connection.bufferedAmount, " bytes not yet sent"));
            }
            if (_this.reconnectId) {
                clearTimeout(_this.reconnectId);
                _this.reconnectId = null;
            }
            onError(e, _this.timeouted ? "timeout" : "connection");
        };
    };
    WatchRTCSocket.prototype.close = function (nailUpCallEnd) {
        var _a, _b;
        if (((_b = (_a = WatchRTCSocket._instance) === null || _a === void 0 ? void 0 : _a.connection) === null || _b === void 0 ? void 0 : _b.readyState) === globalContext.WebSocket.OPEN) {
            // Send existing data in buffer
            if (WatchRTCSocket._instance.buffer.length > 0) {
                (0, sdk_1.sdkLogWithoutTrace)("debug", "flushing buffer");
                var lines = JSON.stringify(WatchRTCSocket._instance.buffer);
                var compressedMessage = LZString.compressToEncodedURIComponent(lines);
                WatchRTCSocket._instance.connection.send(compressedMessage);
            }
            // Send nailUpCallEnd in case of persistent call
            if (nailUpCallEnd) {
                var lines = JSON.stringify(["nailUpCallEnd", null, null, Date.now()]);
                var compressedMessage = LZString.compressToEncodedURIComponent(lines);
                WatchRTCSocket._instance.connection.send(compressedMessage);
            }
        }
        WatchRTCSocket._instance.buffer = [];
        WatchRTCSocket._instance.sendPromises.forEach(function (_a) {
            var resolve = _a.resolve;
            return resolve({ error: "Connection was close" });
        });
        WatchRTCSocket._instance.sendPromises = [];
        if (WatchRTCSocket._instance.connection) {
            WatchRTCSocket._instance.connection.close();
            WatchRTCSocket._instance.onClose();
            WatchRTCSocket._instance.connection = null;
        }
    };
    WatchRTCSocket.prototype.disableDataCollection = function () {
        (0, sdk_1.sdkLog)("debug", "Data collection disabled.");
        WatchRTCSocket._instance.dataCollection = false;
    };
    WatchRTCSocket.prototype.enableDataCollection = function () {
        (0, sdk_1.sdkLog)("debug", "Data collection enabled.");
        WatchRTCSocket._instance.dataCollection = true;
    };
    WatchRTCSocket.prototype.isDisabledDataCollection = function () {
        return !WatchRTCSocket._instance.dataCollection;
    };
    return WatchRTCSocket;
}());
exports.default = WatchRTCSocket;
