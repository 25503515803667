import { MicrophoneIcon, PhotoIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef, useState } from 'react';
import Button from '../Button';
import streamService from '../../services/streamService';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { userDetailsSelector } from '../../features/userDetails/userDetailsSlice';
import { RootState } from '../../app/store';
import { setPeerVolume } from '../../features/peerVolume/peerVolumeSlice';
import { mediaSelector } from '../../features/media/mediaSlice';
import { roomSelector } from '../../features/room/roomSlice';

interface UploadVideoAudioProps {
    isUploadModalOpen: boolean;
}


const UploadVideoAudio: React.FC<UploadVideoAudioProps> = ({ isUploadModalOpen }) => {
    const dispatch = useAppDispatch();
    const [file, setFile] = useState<File | null>(null);
    const [isVideoOrAudio, setIsVideoOrAudio] = useState("");
    const streamInstance = new streamService().getInstance();
    const user = useAppSelector(userDetailsSelector);
    const mediaReducer = useAppSelector(mediaSelector);
    const roomReducer = useAppSelector(roomSelector);
    const [isFileShared, setIsFileShared] = useState(false);

    const isAudioFile = useAppSelector(
        (state: RootState) => state.localfilestreamstatus.isAudioFile
    );
    const isVideoFile = useAppSelector(
        (state: RootState) => state.localfilestreamstatus.isVideoFile
    );

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const files = e.target.files;

        if (files && files.length > 0) {
            const fileVal = files[0];
            console.log('File selected:', fileVal);

            if (fileVal.type) {
                if (fileVal.type.includes("audio")) {
                    setIsVideoOrAudio("audio");
                } else if (fileVal.type.includes("video")) {
                    setIsVideoOrAudio("video");
                } else {
                    setIsVideoOrAudio("unknown");
                }

                setFile(fileVal);
            } else {
                console.error('Selected file does not have a type');
            }
        }
    };

    const renderPreview = () => {
        if (!file) return <p>No file selected</p>;

        const fileType = file.type;
        const previewUrl = URL.createObjectURL(file);

        if (fileType.includes("video")) {
            return (
                <video id="videoFile" width="300" height="200" controls className='rounded-xl shadow-xl'>
                    <source src={previewUrl} type={fileType} />
                </video>
            );
        } else if (fileType.includes("audio")) {
            return (
                <audio id="audioFile" controls className='bg-white shadow-md rounded-3xl'>
                    <source src={previewUrl} type={fileType} />
                </audio>
            );
        } else {
            return <p>Unsupported file format</p>;
        }
    };

    useEffect(() => {
        return () => {
            if (file) {
                URL.revokeObjectURL(URL.createObjectURL(file));
            }
        }
    }, [file]);

    const handleFileSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (isVideoOrAudio === "video") {
            streamInstance.addStreamOfVideoFile(user.userId);
        } else if (isVideoOrAudio === "audio") {
            streamInstance.addStreamOfAudioFile(user.userId, "audioFile");
        }
        setIsFileShared(true);
    };

    const handleBack = () => {
        setIsVideoOrAudio("");
        setIsFileShared(false);
        setFile(null);
        let screenStopped = mediaReducer.localScreenStopped;
        if (screenStopped) {
          // streamInstance.shareScreen(user.userId);
        } else {
          streamInstance.unshareScreen(user.userId);
        }
        streamInstance.addStreamOfAudioFile(user.userId, "audioFile");
        streamInstance.localAudioFileStream = undefined;
        streamInstance.localScreenFileStream = undefined;
        // if (isVideoOrAudio === "video") {
        //     streamInstance.addStreamOfVideoFile(user.userId);
        // } else if (isVideoOrAudio === "audio") {
        //     streamInstance.addStreamOfAudioFile(user.userId, "audioFile");
        // }
    };

    // const prevCloseUploadModalRef = useRef(closeUploadModal);

    useEffect(() => {
        if (!isUploadModalOpen) {
            handleBack();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUploadModalOpen]);

    const audioPauseOrUnpause = () => {
        const checkAudioPaused = mediaReducer.localAudioPaused;

        if (checkAudioPaused) {
            streamInstance.unpauseLocalMic(user.userId);
        } else {
            streamInstance.pauseLocalMic(user.userId);
            dispatch(setPeerVolume({ volume: -100, peerUserId: user.userId }));
        }
    };

    return (
        <>
            {
                !file ?
                    <div className="col-span-full">
                        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                            <div className="text-center">
                                <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                    <label
                                        htmlFor="file-upload"
                                        className="relative cursor-pointer rounded-md bg-white font-semibold text-blue hover:text-blue"
                                    >
                                        <span>Upload a file</span>
                                        <input
                                            id="file-upload"
                                            name="file-upload"
                                            type="file"
                                            onChange={handleFileChange}
                                            accept='audio/*, video/*'
                                            className="sr-only"
                                        />
                                    </label>
                                    <p className="pl-1">, video or audio</p>
                                </div>
                                <p className="text-xs leading-5 text-gray-600">Formats mp3, mp4 up to 10MB</p>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='w-full flex flex-col justify-center items-center gap-2'>
                        {renderPreview()}
                        <div className='flex justify-around items-center gap-[1%] w-[70%]'>
                        {!isFileShared && (
                                <Button type='outline' className="shadow-md" onClick={handleFileSubmit}>
                                    Share To All
                                </Button>
                            )}
                            <Button type='outline' className="shadow-md" onClick={handleBack}>
                                Cancel
                            </Button>
                            {roomReducer.isAudioAllowedInRoom && (!mediaReducer.localAudioPaused ? (
                                <MicrophoneIcon
                                    className="xl:w-11  w-9   text-gray-500 p-2 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 "
                                    title="Mute"
                                    onClick={audioPauseOrUnpause}
                                />
                            ) : (
                                <div
                                    className=" relative flex justify-center items-center transition-all duration-100 hover:opacity-80 cursor-pointer"
                                    onClick={audioPauseOrUnpause}
                                >
                                    <div className=" lg:border-t-[1.4px] xl:border-t-2 border-white lg:w-[40%] xl:w-[43%] absolute -translate-x-1/2 lg:right-[25%] xl:right-[20%] rotate-45"></div>
                                    <MicrophoneIcon
                                        className="xl:w-11 lg:w-9 lg:border-[1.4px] xl:border-2px border-blue   text-white p-2 rounded-full shadow-md mr-5 bg-blue cursor-pointer    "
                                        title="Unmute"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
            }
        </>
    );
}

export default UploadVideoAudio;
