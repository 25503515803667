interface ResizeResult {
    file: File;
    modifiedImageUrl?: string;
  }
  
  export default async function resizeImageAndReturnFileAndURL(
    imageUrl: string,
    targetWidth: number,
    targetHeight: number
  ): Promise<ResizeResult> {
    return new Promise(async (resolve, reject) => {
      try {
        const img = new Image();
  
        img.onload = function () {
          const canvas = document.createElement("canvas");
          canvas.width = targetWidth;
          canvas.height = targetHeight;
  
          const ctx = canvas.getContext("2d");
  
          // @ts-ignore
          ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
  
          canvas.toBlob(function (blob) {
            const file = new File([blob!], "resized_image.jpg", {
              type: "image/jpeg",
            });
  
            const modifiedImageUrl = canvas.toDataURL("image/jpeg");
  
            resolve({ file, modifiedImageUrl });
          }, "image/jpeg");
        };
  
        // Set the source of the image to your base64 URL
        img.src = imageUrl;
  
        // Handle image errors
        img.onerror = function () {
          reject(new Error("Error loading image"));
        };
      } catch (error) {
        reject(error);
      }
    });
  }
  