import React from 'react';
import Input from '../Input';
// import { useNavigate } from 'react-router-dom';
import { ForgotCardInputs } from '../../interfaces/componentsInterface/login'
import { useForm, SubmitHandler } from 'react-hook-form';
import Logo from '../../assets/images/logo.png'
import { sendResetPwdLink } from '../../services/forgotPassword';
import toast from 'react-hot-toast';
import { ForgotPasswordProps } from '../../interfaces/componentsInterface/forgotPassword';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';



export default function ForgotPassword(props: ForgotPasswordProps): ReturnType<React.FC> {

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<ForgotCardInputs>()


    const handleBack = () => {
        props.setForgotPassword(false);
    }

    const onSubmit: SubmitHandler<ForgotCardInputs> = async (data) => {
        try {
            // localStorage.setItem("resetEmail", resetEmail);
            const response = await sendResetPwdLink(data);

            if (response.success) {
                toast.success("You will receive a reset email if user with that email exist");
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.log("Error sending reset link: ", error);
        }
    }

    return (

        <>
            <div className="xl:w-[25%] lg:w-[30%] ml-auto mr-[10%] p-12 gap-2.5 bg-white relative rounded-xl">
                <ChevronLeftIcon className="w-9 h-9  text-gray-500 p-2 absolute top-2 left-2 mr-5 bg-white cursor-pointer hover:text-gray-900" onClick={handleBack} title="Back" />
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img
                        className="mx-auto h-16 w-auto"
                        src={Logo}
                        alt="Your Company"
                    />
                    <h2 className="mt-2 font-sans text-center lg:text-2xl xl:text-3xl font-semibold leading-9 tracking-tight text-gray-900 xl:mb-4">
                        Forgot Password!
                    </h2>
                    <p className="font-sans text-gray-500 text-center lg:text-sm lg:mt-1 xl:text-[1rem] leading-normal text-base  not-italic font-normal self-stretch ">Please Provide us your email to continue</p>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form onSubmit={handleSubmit(onSubmit)} className="lg:space-y-1.5 xl:space-y-6">
                        <div>
                            <Input {...register('email', { required: true })} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setValue('email', e.target.value, { shouldValidate: true })
                            }} label='Email' type='email' placeholder='Enter your email' error={errors.email} message={errors.email && "Email is Required*"} />
                        </div>
                        <div>
                            <button
                                type='submit'
                                className="flex w-full justify-center rounded-md bg-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue hover:opacity-[0.9] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue"
                            >
                                Continue
                            </button>
                        </div>
                    </form>


                </div>
            </div>


        </>
    )
}