
import SignalIndicatorProps from "../../interfaces/componentsInterface/signalIndicator";


const strengths = {
    EXCELLENT: "EXCELLENT",
    GREAT: "GREAT",
    OKAY: "OKAY",
    WEAK: "WEAK",
};
export default function SignalIndicator(props: SignalIndicatorProps): ReturnType<React.FC> {
    const signalStrength = props.networkStrength ? props.networkStrength : strengths.EXCELLENT;
    const numBars = 4;
    let fill = 0;
    if (signalStrength.toUpperCase() === strengths.EXCELLENT) {
        fill = numBars;
    } else if (signalStrength.toUpperCase() === strengths.GREAT) {
        fill = numBars - 1;
    } else if (signalStrength.toUpperCase() === strengths.OKAY) {
        fill = numBars - 2;
    } else if (signalStrength.toUpperCase() === strengths.WEAK) {
        fill = numBars - 3;
    }


    return (

        <>

            <div className={` ${props.fromParticipant ? "xl:w-6 sm:md:lg:w-5 xl:h-6 sm:md:lg:h-5" : "xl:w-11 sm:md:lg:w-9 xl:h-10 sm:md:lg:h-8"} gap-[.1rem]  p-[.1rem] flex justify-center items-end cursor-pointer hover:opacity-80 `} title="Signal Strength">
                <div className={` h-1/4 w-1/4 ${fill >= 1 ? "bg-blue" : 'bg-gray-300'} rounded-sm`}></div>
                <div className={` h-1/2 w-1/4 ${fill >= 2 ? "bg-blue" : 'bg-gray-300'} rounded-sm`}></div>
                <div className={` h-3/4 w-1/4 ${fill >= 3 ? "bg-blue" : 'bg-gray-300'} rounded-sm`}></div>
                <div className={` h-full w-1/4 ${fill >= 4 ? "bg-blue" : 'bg-gray-300'} rounded-sm`}></div>


            </div>


        </>
    )
}