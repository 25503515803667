import React, { useState, useEffect } from 'react';
import moment from 'moment';

interface TimeAgoProps {
    timestamp: string;
}

const TimeAgo: React.FC<TimeAgoProps> = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState<string>('');

    useEffect(() => {
        const update = () => {
            const time = moment(timestamp);
            const yesterday = moment().subtract(1, 'days').startOf('day');
            if (time.isBefore(yesterday)) {
                setTimeAgo(time.format('YYYY-MM-DD'));
            } else {
                setTimeAgo(time.fromNow());
            }
        };

        update(); // Initial update
        const intervalId = setInterval(update, 60000); // Update every minute

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, [timestamp]);

    return <span>{timeAgo}</span>;
};

export default TimeAgo;
