import { role } from "./constant";

export const constantMethod = {
  sleep: (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },
  checkHigherRole: (paramRole: string) => {
    if (
      paramRole === role.SUPERVISOR ||
      paramRole === role.ADMIN ||
      paramRole === role.MANAGER ||
      paramRole === role.DIRECTOR
    ) {
      return true;
    } else {
      return false;
    }
  },
  convertIntoBoolean: (value: String | Boolean) => {
    if (value === "true") {
      return true;
    } else if (value === true) {
      return true;
    } else {
      return false;
    }
  },
};
