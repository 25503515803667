import React from 'react';
import Input from '../Input';
import { MFACardInputs, MFACardProps } from '../../interfaces/componentsInterface/login'
import { useForm, SubmitHandler } from 'react-hook-form';
import Logo from '../../assets/images/logo.png'
// import toast from 'react-hot-toast';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { verifyOtp } from '../../services/login';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { loginSelector } from '../../features/login/loginSlice';



export default function MFA(props: MFACardProps): ReturnType<React.FC> {

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<MFACardInputs>()
    const dispatch = useAppDispatch();
    const loginState = useAppSelector(loginSelector)
    // const handleBack = () => {
    //     props.setForgotPassword(false);
    // }

    const onSubmit: SubmitHandler<MFACardInputs> = async (data) => {
        try {
            // localStorage.setItem("resetEmail", resetEmail);
            const body = {
                custCode: props.custCode,
                username: props.username,
                otp: data.otp

            }
            console.log(body)
            dispatch(verifyOtp(body));
        } catch (error) {
            console.log("Error sending reset link: ", error);
        }
    }

    return (

        <>
            <div className="xl:w-[25%] lg:w-[30%] ml-auto mr-[10%] p-12 gap-2.5 bg-white rounded-xl">

                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img
                        className="mx-auto h-16 w-auto"
                        src={Logo}
                        alt="Your Company"
                    />
                    <h2 className="mt-2 font-sans text-center lg:text-2xl xl:text-3xl font-semibold leading-9 tracking-tight text-gray-900 xl:mb-4">
                        Login
                    </h2>
                    <p className="font-sans text-gray-500 text-center lg:text-sm lg:mt-1 xl:text-[1rem] leading-normal text-base  not-italic font-normal self-stretch ">Enter your OTP to login our dashboard</p>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="lg:space-y-1.5 xl:space-y-6" onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <Input {...register('otp', { required: true })} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setValue('otp', e.target.value, { shouldValidate: true })
                            }} label='OTP' type='text' placeholder='Please enter your OTP' error={errors.otp} message={errors.otp && "Please Provide OTP*"} />
                        </div>
                        <div>
                            <button
                                type='submit'
                                className="flex w-full justify-center items-center gap-1 rounded-md bg-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue hover:opacity-[0.9] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue"
                            >
                                {loginState && loginState.loading && (
                                    <div
                                        className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                                        role="status"
                                    >
                                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                            Loading...
                                        </span>
                                    </div>
                                )}
                                Verify OTP</button>

                        </div>
                    </form>


                </div>
            </div>


        </>
    )
}