import axios from "axios";

export const detect = async (
  url?: string,
  username?: string,
  userId?:string,
  custId?:string | null,
  image?: string,
  client?: string,
  features?: any,
  token?: any
) => {
  const request = axios.create({
    baseURL: url,
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const reqBody = {

    username: username,
    userId,
    custId,
    features: features,
    client: client,
    image: image,
  };
  try {
    const detectResponse = await request.post("/detect", reqBody, config);
    return detectResponse.data;
  } catch (error: any) {
    throw new Error(`Unable to detect: ${error.message}`);
  }
};
