import React, { useEffect, useState } from 'react';
import PasswordResetImage from '../../assets/images/forgot.png';
import Logo from '../../assets/images/logo.png';
import Input from '../../components/Input';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { PasswordChangeInputs } from '../../interfaces/componentsInterface/passwordChange';
import Button from '../../components/Button';
import routes from '../../constants/routes';
import ChangePasswordImage from '../../assets/images/changepassword.jpg'
import { changePassword } from '../../services/changePassword';
import { environmentSelector } from '../../features/environment/environmentSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import PasswordStrengthIndicator from './PasswordStrengthIndicator';
import { setComplexPassword } from '../../features/login/loginSlice';
import { getUserDetails } from '../../services/userDetails';

export default function PasswordChange(): ReturnType<React.FC> {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const environments = useAppSelector(environmentSelector);
  const [passwordStrength, setPasswordStrength] = useState<number>(0);
  const complexPassword = environments?.environments?.REACT_APP_COMPLEX_PASSWORD?.status;
  const search = useLocation().search;
  const [expires, setExpires] = useState(new URLSearchParams(search).get("expires"));
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<PasswordChangeInputs>();
  const newPassword = watch('newPassword');

  const handleGoBack = () => {
    navigate(routes.dashboard);
  };

  const onSubmit: SubmitHandler<PasswordChangeInputs> = async (data) => {
    if (data.newPassword !== data.confirmNewPassword) {
      toast.error('Confirm new password should be same as new password');
      return;
    }

    const body = {
      password: data.newPassword
    }
    try {
      const response = await changePassword(body)
      if (response && response.success) {
        toast.success(response.message)
        dispatch(setComplexPassword(true))
        dispatch(getUserDetails({ data: "" }));
        setExpires("")
        setTimeout(() => {
          navigate(routes.dashboard)
        }, 2000)
      }
    } catch (err: unknown | any) {
      toast.error(err.message);
    }


  };

  

  
  const checkPassword = (password: string) => {
    let strength = 0;
    let messages:string = "";
   
    if (password.length >= 8) {
      strength += 1;
    } else {
      messages='Minimum length of password is 8 characters';
    }
    if (password.match(/[$@#&!]+/)) {
      strength += 1;
    } else {
      messages='At least one special character required';
    }
    if (password.match(/[0-9]+/)) {
      strength += 1;
    } else {
      messages='At least one numeric character required';
    }
    if (password.match(/[A-Z]+/)) {
      strength += 1;
    } else {
      messages= 'At least one uppercase character required';
    }
    if (password.match(/[a-z]+/)) {
      strength += 1;
    } else {
      messages = 'At least one lowercase character required';
    }
    setPasswordStrength(strength);
    return strength === 5 || messages;
  };



  useEffect(() => {
  console.log({newPassword})
  if (newPassword?.length===0) {
    setPasswordStrength(0);
  }
  }, [newPassword])
  

 useEffect(() => {
if(expires){
  toast.error("Your password has been expired please update your password")
}
}, [expires])


  return (
    <>
      <div
        style={{ backgroundImage: `url(${ChangePasswordImage})` }}
        className="bg-cover w-screen h-screen overflow-hidden flex justify-center items-center "
      >
        <div className='w-screen h-screen overflow-hidden flex justify-center items-center backdrop-blur-md'>

        <div className="xl:w-[28%] lg:w-[30%] p-12 gap-2.5 bg-white rounded-xl">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-16 w-auto"
              src={Logo}
              alt="Your Company"
            />
            <h2 className="mt-2 font-sans text-center lg:text-2xl xl:text-3xl font-semibold leading-9 tracking-tight text-gray-900 xl:mb-4">
              Change Password
            </h2>
            <p className="font-sans text-gray-500 text-center lg:text-sm lg:mt-1 xl:text-[1rem] leading-normal text-base  not-italic font-normal self-stretch ">
              Please enter the details to change your password!
            </p>
          </div>
          <div className="mt-5 sm:mx-auto  w-full">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="lg:space-y-1.5 xl:space-y-6"
            >
              <div>
                <Input
                  {...register('newPassword', { required: "New Password is required*" ,validate: complexPassword && checkPassword})}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setValue('newPassword', e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                  label="New Password"
                  type="password"
                  placeholder="Enter New Password"
                  error={errors.newPassword}
                  message={errors.newPassword?.message}
                />
              {complexPassword && <PasswordStrengthIndicator strength={passwordStrength} />}
              </div>
              <div>
                <Input
                  {...register('confirmNewPassword', {  required: 'Confirm New Password is required'})}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setValue('confirmNewPassword', e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                  label="Confirm New Password"
                  type="password"
                  placeholder="Enter Confirm New Password"
                  error={errors.confirmNewPassword}
                  message={errors.confirmNewPassword?.message}
                />
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue hover:opacity-[0.9] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue"
                >
                  Change Password
                </button>

                <div className="py-3 sm:flex sm:flex-row-reverse ">
                  <Button
                    type="outline"
                    children="Back To Dashboard"
                    onClick={handleGoBack}
                    className="flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm hover:opacity-[0.9] focus-visible:outline text-black focus-visible:outline-2 ring-1 ring-inset sm:col-start-2 sm:mt-0"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}