import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getPendingVerifications,
  rejectAllUsers,
  verifyAllUsers,
} from "../../services/adminVerification";
import { RootState } from "../../app/store";
import toast from "react-hot-toast";

const initialState = {
  pendingUsers: [] as any,
  count:0,
  success: false,
  error: "",
  loading: false,
};

const adminVerificationSlice = createSlice({
  name: "adminVerification",
  initialState,
  reducers: {
    updateCount : (state,action:PayloadAction<any>) => {
      state.count = action.payload
    }
  },
  extraReducers(builder) {
    builder.addCase(getPendingVerifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getPendingVerifications.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        if (action.payload.success) {
          state.pendingUsers = action.payload.users;
          state.success = true;
          state.count = action.payload.count
        } else {
          state.pendingUsers = [];
          state.success = false;
          state.count = 0
        }
      }
    );
    builder.addCase(
      getPendingVerifications.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      }
    );
    builder.addCase(verifyAllUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      verifyAllUsers.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        if (action.payload.response.success) {

          state.success = true;
          state.pendingUsers = state.pendingUsers.filter(
            (user: any) => !action.payload.data.includes(user._id)
          );
          toast.success(action.payload.response.message)
        }
      }
    );
    builder.addCase(verifyAllUsers.rejected,(state,action:PayloadAction<any>)=>{
        state.loading = false;
        state.success = false;
        state.error = action.payload
    })
    builder.addCase(rejectAllUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      rejectAllUsers.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        if (action.payload.response.success) {

          state.success = true;
          state.pendingUsers = state.pendingUsers.filter(
            (user: any) => !action.payload.data.includes(user._id)
          );
          toast.success(action.payload.response.message)
        }
      }
    );
    builder.addCase(rejectAllUsers.rejected,(state,action:PayloadAction<any>)=>{
        state.loading = false;
        state.success = false;
        state.error = action.payload
    })
  },
});

export const adminVerificationSelector = (state: RootState) =>
  state.adminVerification;
export const {updateCount } = adminVerificationSlice.actions
export default adminVerificationSlice.reducer;
