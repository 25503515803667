function dataURItoBlob(dataURI: string): Blob {
   
    const byteString: string = atob(dataURI.split(',')[1]);

  
    const mimeString: string = dataURI.split(',')[0].split(':')[1].split(';')[0];

  
    const ab: ArrayBuffer = new ArrayBuffer(byteString.length);
    const ia: Uint8Array = new Uint8Array(ab);
    for (let i: number = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

   
    const blob: Blob = new Blob([ab], { type: mimeString });
    return blob;
  }


  export default dataURItoBlob;