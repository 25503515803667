import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken } from "../utils/Token";
import { utilsRequest } from ".";
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "",
  },
};
export const getBackgroundImages = createAsyncThunk(
  "user/getBackgroundActivate",
  async () => {
    config["headers"]["Authorization"] = getToken();
    const response = await utilsRequest.get("/v1/api/backgroundimage/get", config);
    return response.data;
  }
);


