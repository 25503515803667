import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getBackgroundImages } from "../../services/videoBackground";
import toast from "react-hot-toast";

const initialState = {
  loading: false,
  error: "",
  staticBackground: [],
  selectedBackground: "",
  isVirtualModalOpen: false,
};

const videoBackgroundSlice = createSlice({
  name: "videoBackground",
  initialState,
  reducers: {
    setImageLink: (state, action: PayloadAction<any>) => {
      state.selectedBackground = action.payload;
    },
    setIsVirtualModalOpen: (state, action: PayloadAction<any>) => {
      state.isVirtualModalOpen = action.payload;
    },
    clearBackground :() =>{
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getBackgroundImages.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getBackgroundImages.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          if (action.payload.success) {
            state.staticBackground = action.payload.backgroundImage;
            state.error = "";
          } else {
            toast.error("Cannot Get Background Images");
          }
        }
      )
      .addCase(getBackgroundImages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "";
        state.staticBackground = [];
        toast.error("Error Getting Background Images");
      });
  },
});

export const { setImageLink, setIsVirtualModalOpen,clearBackground } =
  videoBackgroundSlice.actions;
export const videoBackgroundSelector = (state: RootState) =>
  state.videoBackground;
export default videoBackgroundSlice.reducer;
