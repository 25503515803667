import { createAsyncThunk } from "@reduxjs/toolkit";
import { roomRequest } from ".";
import { getToken } from "../utils/Token";

const config = {
  headers: {
    Authorization: "",
    "Content-Type": "application/json",
  },
};

export const getRooms = createAsyncThunk("room/getRooms", async () => {
  config["headers"]["Authorization"] = getToken();
  
    const response = await roomRequest.get("/v1/api/room/get", config);
    return response.data;
});

export const getUsersCountsPerRoom = createAsyncThunk("room/getUsersCountPerRooms", async () => {
  config["headers"]["Authorization"] = getToken();

  const response = await roomRequest.get("/v1/api/room/get-all-users-count-by-room", config);
  return response.data;
});


export const createTempRoom = async (data: any) => {
  config["headers"]["Authorization"] = getToken();

  const response = await roomRequest.post("/v1/api/room/create", data, config);
  return response.data;
};


export const shiftToExistingRoom = async (data: any) => {
  config["headers"]["Authorization"] = getToken();

  const response = await roomRequest.post("/v1/api/room/shift-to-room", data, config);
  return response.data;
};
