export const formats:Record<string,string> = { 
    "af-ZA": "YYYY/MM/DD HH:mm:ss A",
    "am-ET": "D/M/YYYY HH:mm:ss A",
    "ar-AE": "DD/MM/YYYY HH:mm:ss A",
    "ar-BH": "DD/MM/YYYY HH:mm:ss A",
    "ar-DZ": "DD-MM-YYYY HH:mm:ss A",
    "ar-EG": "DD/MM/YYYY HH:mm:ss A",
    "ar-IQ": "DD/MM/YYYY HH:mm:ss A",
    "ar-JO": "DD/MM/YYYY HH:mm:ss A",
    "ar-KW": "DD/MM/YYYY HH:mm:ss A",
    "ar-LB": "DD/MM/YYYY HH:mm:ss A",
    "ar-LY": "DD/MM/YYYY HH:mm:ss A",
    "ar-MA": "DD-MM-YYYY HH:mm:ss A",
    "ar-OM": "DD/MM/YYYY HH:mm:ss A",
    "ar-QA": "DD/MM/YYYY HH:mm:ss A",
    "ar-SA": "DD/MM/YY HH:mm:ss A",
    "ar-SY": "DD/MM/YYYY HH:mm:ss A",
    "ar-TN": "DD-MM-YYYY HH:mm:ss A",
    "ar-YE": "DD/MM/YYYY HH:mm:ss A",
    "arn-CL": "DD-MM-YYYY HH:mm:ss A",
    "as-IN": "DD-MM-YYYY HH:mm:ss A",
    "az-Cyrl-AZ": "DD.MM.YYYY HH:mm:ss A",
    "az-Latn-AZ": "DD.MM.YYYY HH:mm:ss A",
    "ba-RU": "DD.MM.YY HH:mm:ss A",
    "be-BY": "DD.MM.YYYY HH:mm:ss A",
    "bg-BG": "DD.M.YYYY HH:mm:ss A",
    "bn-BD": "DD-MM-YY HH:mm:ss A",
    "bn-IN": "DD-MM-YY HH:mm:ss A",
    "bo-CN": "YYYY/M/D HH:mm:ss A",
    "br-FR": "DD/MM/YYYY HH:mm:ss A",
    "bs-Cyrl-BA": "D.M.YYYY HH:mm:ss A",
    "bs-Latn-BA": "D.M.YYYY HH:mm:ss A",
    "ca-ES": "DD/MM/YYYY HH:mm:ss A",
    "co-FR": "DD/MM/YYYY HH:mm:ss A",
    "cs-CZ": "D.M.YYYY HH:mm:ss A",
    "cy-GB": "DD/MM/YYYY HH:mm:ss A",
    "da-DK": "DD-MM-YYYY HH:mm:ss A",
    "de-AT": "DD.MM.YYYY HH:mm:ss A",
    "de-CH": "DD.MM.YYYY HH:mm:ss A",
    "de-DE": "DD.MM.YYYY HH:mm:ss A",
    "de-LI": "DD.MM.YYYY HH:mm:ss A",
    "de-LU": "DD.MM.YYYY HH:mm:ss A",
    "dsb-DE": "D.M.YYYY HH:mm:ss A",
    "dv-MV": "DD/MM/YY HH:mm:ss A",
    "el-GR": "D/M/YYYY HH:mm:ss A",
    "en-029": "MM/DD/YYYY HH:mm:ss A",
    "en-AU": "D/MM/YYYY HH:mm:ss A",
    "en-BZ": "DD/MM/YYYY HH:mm:ss A",
    "en-CA": "DD/MM/YYYY HH:mm:ss A",
    "en-GB": "DD/MM/YYYY HH:mm:ss A",
    "en-IE": "DD/MM/YYYY HH:mm:ss A",
    "en-IN": "DD-MM-YYYY HH:mm:ss A",
    "en-JM": "DD/MM/YYYY HH:mm:ss A",
    "en-MY": "D/M/YYYY HH:mm:ss A",
    "en-NZ": "D/MM/YYYY HH:mm:ss A",
    "en-PH": "MM/DD/YYYY HH:mm:ss A",
    "en-SG": "D/M/YYYY HH:mm:ss A",
    "en-TT": "DD/MM/YYYY HH:mm:ss A",
    "en-US": "MM/DD/YYYY HH:mm:ss A",
    "en-ZA": "YYYY/MM/DD HH:mm:ss A",
    "en-ZW": "MM/DD/YYYY HH:mm:ss A",
    "es-AR": "DD/MM/YYYY HH:mm:ss A",
    "es-BO": "DD/MM/YYYY HH:mm:ss A",
    "es-CL": "DD-MM-YYYY HH:mm:ss A",
    "es-CO": "DD/MM/YYYY HH:mm:ss A",
    "es-CR": "DD/MM/YYYY HH:mm:ss A",
    "es-DO": "DD/MM/YYYY HH:mm:ss A",
    "es-EC": "DD/MM/YYYY HH:mm:ss A",
    "es-ES": "DD/MM/YYYY HH:mm:ss A",
    "es-GT": "DD/MM/YYYY HH:mm:ss A",
    "es-HN": "DD/MM/YYYY HH:mm:ss A",
    "es-MX": "DD/MM/YYYY HH:mm:ss A",
    "es-NI": "DD/MM/YYYY HH:mm:ss A",
    "es-PA": "MM/DD/YYYY HH:mm:ss A",
    "es-PE": "DD/MM/YYYY HH:mm:ss A",
    "es-PR": "DD/MM/YYYY HH:mm:ss A",
    "es-PY": "DD/MM/YYYY HH:mm:ss A",
    "es-SV": "DD/MM/YYYY HH:mm:ss A",
    "es-US": "M/D/YYYY HH:mm:ss A",
    "es-UY": "DD/MM/YYYY HH:mm:ss A",
    "es-VE": "DD/MM/YYYY HH:mm:ss A",
    "et-EE": "D.MM.YYYY HH:mm:ss A",
    "eu-ES": "YYYY/MM/DD HH:mm:ss A",
    "fa-IR": "MM/DD/YYYY HH:mm:ss A",
    "fi-FI": "D.M.YYYY HH:mm:ss A",
    "fil-PH": "M/D/YYYY HH:mm:ss A",
    "fo-FO": "DD-MM-YYYY HH:mm:ss A",
    "fr-BE": "D/MM/YYYY HH:mm:ss A",
    "fr-CA": "YYYY-MM-DD HH:mm:ss A",
    "fr-CH": "DD.MM.YYYY HH:mm:ss A",
    "fr-FR": "DD/MM/YYYY HH:mm:ss A",
    "fr-LU": "DD/MM/YYYY HH:mm:ss A",
    "fr-MC": "DD/MM/YYYY HH:mm:ss A",
    "fy-NL": "D-M-YYYY HH:mm:ss A",
    "ga-IE": "DD/MM/YYYY HH:mm:ss A",
    "gd-GB": "DD/MM/YYYY HH:mm:ss A",
    "gl-ES": "DD/MM/YY HH:mm:ss A",
    "gsw-FR": "DD/MM/YYYY HH:mm:ss A",
    "gu-IN": "DD-MM-YY HH:mm:ss A",
    "ha-Latn-NG": "D/M/YYYY HH:mm:ss A",
    "he-IL": "DD/MM/YYYY HH:mm:ss A",
    "hi-IN": "DD-MM-YYYY HH:mm:ss A",
    "hr-BA": "D.M.YYYY. HH:mm:ss A",
    "hr-HR": "D.M.YYYY HH:mm:ss A",
    "hsb-DE": "D.M.YYYY HH:mm:ss A",
    "hu-HU": "YYYY. MM. DD. HH:mm:ss A",
    "hy-AM": "DD.MM.YYYY HH:mm:ss A",
    "id-ID": "DD/MM/YYYY HH:mm:ss A",
    "ig-NG": "D/M/YYYY HH:mm:ss A",
    "ii-CN": "YYYY/M/D HH:mm:ss A",
    "is-IS": "D.M.YYYY HH:mm:ss A",
    "it-CH": "DD.MM.YYYY HH:mm:ss A",
    "it-IT": "DD/MM/YYYY HH:mm:ss A",
    "iu-Cans-CA": "D/M/YYYY HH:mm:ss A",
    "iu-Latn-CA": "D/MM/YYYY HH:mm:ss A",
    "ja-JP": "YYYY/MM/DD HH:mm:ss A",
    "ka-GE": "DD.MM.YYYY HH:mm:ss A",
    "kk-KZ": "DD.MM.YYYY HH:mm:ss A",
    "kl-GL": "DD-MM-YYYY HH:mm:ss A",
    "km-KH": "YYYY-MM-DD HH:mm:ss A",
    "kn-IN": "DD-MM-YY HH:mm:ss A",
    "ko-KR": "YYYY.MM.DD HH:mm:ss A",
    "kok-IN": "DD-MM-YYYY HH:mm:ss A",
    "ky-KG": "DD.MM.YY HH:mm:ss A",
    "lb-LU": "DD/MM/YYYY HH:mm:ss A",
    "lo-LA": "DD/MM/YYYY HH:mm:ss A",
    "lt-LT": "YYYY.MM.DD HH:mm:ss A",
    "lv-LV": "YYYY.MM.DD. HH:mm:ss A",
    "mi-NZ": "DD/MM/YYYY HH:mm:ss A",
    "mk-MK": "DD.MM.YYYY HH:mm:ss A",
    "ml-IN": "DD-MM-YY HH:mm:ss A",
    "mn-MN": "YY.MM.DD HH:mm:ss A",
    "mn-Mong-CN": "YYYY/M/D HH:mm:ss A",
    "moh-CA": "M/D/YYYY HH:mm:ss A",
    "mr-IN": "DD-MM-YYYY HH:mm:ss A",
    "ms-BN": "DD/MM/YYYY HH:mm:ss A",
    "ms-MY": "DD/MM/YYYY HH:mm:ss A",
    "mt-MT": "DD/MM/YYYY HH:mm:ss A",
    "nb-NO": "DD.MM.YYYY HH:mm:ss A",
    "ne-NP": "M/D/YYYY HH:mm:ss A",
    "nl-BE": "D/MM/YYYY HH:mm:ss A",
    "nl-NL": "D-M-YYYY HH:mm:ss A",
    "nn-NO": "DD.MM.YYYY HH:mm:ss A",
    "nso-ZA": "YYYY/MM/DD HH:mm:ss A",
    "oc-FR": "DD/MM/YYYY HH:mm:ss A",
    "or-IN": "DD-MM-YY HH:mm:ss A",
    "pa-IN": "DD-MM-YY HH:mm:ss A",
    "pl-PL": "DD.MM.YYYY HH:mm:ss A",
    "prs-AF": "DD/MM/YY HH:mm:ss A",
    "ps-AF": "DD/MM/YY HH:mm:ss A",
    "pt-BR": "D/M/YYYY HH:mm:ss A",
    "pt-PT": "DD-MM-YYYY HH:mm:ss A",
    "qut-GT": "DD/MM/YYYY HH:mm:ss A",
    "quz-BO": "DD/MM/YYYY HH:mm:ss A",
    "quz-EC": "DD/MM/YYYY HH:mm:ss A",
    "quz-PE": "DD/MM/YYYY HH:mm:ss A",
    "rm-CH": "DD/MM/YYYY HH:mm:ss A",
    "ro-RO": "DD.MM.YYYY HH:mm:ss A",
    "ru-RU": "DD.MM.YYYY HH:mm:ss A",
    "rw-RW": "M/D/YYYY HH:mm:ss A",
    "sa-IN": "DD-MM-YYYY HH:mm:ss A",
    "sah-RU": "MM.DD.YYYY HH:mm:ss A",
    "se-FI": "D.M.YYYY HH:mm:ss A",
    "se-NO": "DD.MM.YYYY HH:mm:ss A",
    "se-SE": "YYYY-MM-DD HH:mm:ss A",
    "si-LK": "YYYY-MM-DD HH:mm:ss A",
    "sk-SK": "D.M.YYYY HH:mm:ss A",
    "sl-SI": "D.M.YYYY HH:mm:ss A",
    "sma-NO": "DD.MM.YYYY HH:mm:ss A",
    "sma-SE": "YYYY-MM-DD HH:mm:ss A",
    "smj-NO": "DD.MM.YYYY HH:mm:ss A",
    "smj-SE": "YYYY-MM-DD HH:mm:ss A",
    "smn-FI": "D.M.YYYY HH:mm:ss A",
    "sms-FI": "D.M.YYYY HH:mm:ss A",
    "sq-AL": "YYYY-MM-DD HH:mm:ss A",
    "sr-Cyrl-BA": "D.M.YYYY HH:mm:ss A",
    "sr-Cyrl-CS": "D.M.YYYY HH:mm:ss A",
    "sr-Cyrl-ME": "D.M.YYYY HH:mm:ss A",
    "sr-Cyrl-RS": "D.M.YYYY HH:mm:ss A",
    "sr-Latn-BA": "D.M.YYYY HH:mm:ss A",
    "sr-Latn-CS": "D.M.YYYY HH:mm:ss A",
    "sr-Latn-ME": "D.M.YYYY HH:mm:ss A",
    "sr-Latn-RS": "D.M.YYYY HH:mm:ss A",
    "sv-FI": "D.M.YYYY HH:mm:ss A",
    "sv-SE": "YYYY-MM-DD HH:mm:ss A",
    "sw-KE": "M/D/YYYY HH:mm:ss A",
    "syr-SY": "DD/MM/YYYY HH:mm:ss A",
    "ta-IN": "DD-MM-YYYY HH:mm:ss A",
    "te-IN": "DD-MM-YY HH:mm:ss A",
    "tg-Cyrl-TJ": "DD.MM.YY HH:mm:ss A",
    "th-TH": "D/M/YYYY HH:mm:ss A",
    "tk-TM": "DD.MM.YY HH:mm:ss A",
    "tn-ZA": "YYYY/MM/DD HH:mm:ss A",
    "tr-TR": "DD.MM.YYYY HH:mm:ss A",
    "tt-RU": "DD.MM.YYYY HH:mm:ss A",
    "tzm-Latn-DZ": "DD-MM-YYYY HH:mm:ss A",
    "ug-CN": "YYYY-M-D HH:mm:ss A",
    "uk-UA": "DD.MM.YYYY HH:mm:ss A",
    "ur-PK": "DD/MM/YYYY HH:mm:ss A",
    "uz-Cyrl-UZ": "DD.MM.YYYY HH:mm:ss A",
    "uz-Latn-UZ": "DD/MM YYYY HH:mm:ss A",
    "vi-VN": "DD/MM/YYYY HH:mm:ss A",
    "wo-SN": "DD/MM/YYYY HH:mm:ss A",
    "xh-ZA": "YYYY/MM/DD HH:mm:ss A",
    "yo-NG": "D/M/YYYY HH:mm:ss A",
    "zh-CN": "YYYY/M/D HH:mm:ss A",
    "zh-HK": "D/M/YYYY HH:mm:ss A",
    "zh-MO": "D/M/YYYY HH:mm:ss A",
    "zh-SG": "D/M/YYYY HH:mm:ss A",
    "zh-TW": "YYYY/M/D HH:mm:ss A",
    "zu-ZA": "YYYY/MM/DD HH:mm:ss A",
  };

  export const timezoneLocaleMap:Record<string,string> = {
    'Africa/Abidjan': 'fr-FR',
    'Africa/Accra': 'en-GH',
    'Africa/Algiers': 'fr-DZ',
    'Africa/Bissau': 'pt-GW',
    'Africa/Cairo': 'ar-EG',
    'Africa/Casablanca': 'fr-MA',
    'Africa/Ceuta': 'es-ES',
    'Africa/El_Aaiun': 'es-ES',
    'Africa/Johannesburg': 'en-ZA',
    'Africa/Khartoum': 'ar-SD',
    'Africa/Lagos': 'en-NG',
    'Africa/Maputo': 'pt-MZ',
    'Africa/Monrovia': 'en-LR',
    'Africa/Nairobi': 'sw-KE',
    'Africa/Ndjamena': 'fr-TD',
    'Africa/Tripoli': 'ar-LY',
    'Africa/Tunis': 'ar-TN',
    'Africa/Windhoek': 'en-NA',
    'America/Adak': 'en-US',
    'America/Anchorage': 'en-US',
    'America/Araguaina': 'pt-BR',
    'America/Argentina/Buenos_Aires': 'es-AR',
    'America/Argentina/Catamarca': 'es-AR',
    'America/Argentina/Cordoba': 'es-AR',
    'America/Argentina/Jujuy': 'es-AR',
    'America/Argentina/La_Rioja': 'es-AR',
    'America/Argentina/Mendoza': 'es-AR',
    'America/Argentina/Rio_Gallegos': 'es-AR',
    'America/Argentina/Salta': 'es-AR',
    'America/Argentina/San_Juan': 'es-AR',
    'America/Argentina/San_Luis': 'es-AR',
    'America/Argentina/Tucuman': 'es-AR',
    'America/Argentina/Ushuaia': 'es-AR',
    'America/Asuncion': 'es-PY',
    'America/Atikokan': 'en-CA',
    'America/Bahia': 'pt-BR',
    'America/Bahia_Banderas': 'es-MX',
    'America/Barbados': 'en-BB',
    'America/Belem': 'pt-BR',
    'America/Belize': 'en-BZ',
    'America/Blanc-Sablon': 'fr-CA',
    'America/Boa_Vista': 'pt-BR',
    'America/Bogota': 'es-CO',
    'America/Boise': 'en-US',
    'America/Cambridge_Bay': 'en-CA',
    'America/Campo_Grande': 'pt-BR',
    'America/Cancun': 'es-MX',
    'America/Caracas': 'es-VE',
    'America/Cayenne': 'fr-GF',
    'America/Cayman': 'en-KY',
    'America/Chicago': 'en-US',
    'America/Chihuahua': 'es-MX',
    'America/Costa_Rica': 'es-CR',
    'America/Creston': 'en-CA',
    'America/Cuiaba': 'pt-BR',
    'America/Curacao': 'nl-CW',
    'America/Danmarkshavn': 'da-DK',
    'America/Dawson': 'en-CA',
    'America/Dawson_Creek': 'en-CA',
    'America/Denver': 'en-US',
    'America/Detroit': 'en-US',
    'America/Edmonton': 'en-CA',
    'America/Eirunepe': 'pt-BR',
    'America/El_Salvador': 'es-SV',
    'America/Fort_Nelson': 'en-CA',
    'America/Fortaleza': 'pt-BR',
    'America/Glace_Bay': 'en-CA',
    'America/Godthab': 'kl-GL',
    'America/Goose_Bay': 'en-CA',
    'America/Grand_Turk': 'en-TC',
    'America/Guatemala': 'es-GT',
    'America/Guayaquil': 'es-EC',
    'America/Guyana': 'en-GY',
    'America/Halifax': 'en-CA',
    'America/Havana': 'es-CU',
    'America/Hermosillo': 'es-MX',
    'America/Indiana/Indianapolis': 'en-US',
    'America/Indiana/Knox': 'en-US',
    'America/Indiana/Marengo': 'en-US',
    'America/Indiana/Petersburg': 'en-US',
    'America/Indiana/Tell_City': 'en-US',
    'America/Indiana/Vevay': 'en-US',
    'America/Indiana/Vincennes': 'en-US',
    'America/Indiana/Winamac': 'en-US',
    'America/Inuvik': 'en-CA',
    'America/Iqaluit': 'en-CA',
    'America/Jamaica': 'en-JM',
    'America/Juneau': 'en-US',
    'America/Kentucky/Louisville': 'en-US',
    'America/Kentucky/Monticello': 'en-US',
    'America/Kralendijk': 'nl-BQ',
    'America/La_Paz': 'es-BO',
    'America/Lima': 'es-PE',
    'America/Los_Angeles': 'en-US',
    'America/Lower_Princes': 'en-SX',
    'America/Maceio': 'pt-BR',
    'America/Managua': 'es-NI',
    'America/Manaus': 'pt-BR',
    'America/Marigot': 'fr-MF',
    'America/Martinique': 'fr-MQ',
    'America/Matamoros': 'es-MX',
    'America/Mazatlan': 'es-MX',
    'America/Menominee': 'en-US',
    'America/Merida': 'es-MX',
    'America/Metlakatla': 'en-US',
    'America/Mexico_City': 'es-MX',
    'America/Miquelon': 'fr-PM',
    'America/Moncton': 'en-CA',
    'America/Monterrey': 'es-MX',
    'America/Montevideo': 'es-UY',
    'America/Montserrat': 'en-MS',
    'America/Nassau': 'en-BS',
    'America/New_York': 'en-US',
    'America/Nipigon': 'en-CA',
    'America/Nome': 'en-US',
    'America/Noronha': 'pt-BR',
    'America/North_Dakota/Beulah': 'en-US',
    'America/North_Dakota/Center': 'en-US',
    'America/North_Dakota/New_Salem': 'en-US',
    'America/Ojinaga': 'es-MX',
    'America/Panama': 'es-PA',
    'America/Pangnirtung': 'en-CA',
    'America/Paramaribo': 'nl-SR',
    'America/Phoenix': 'en-US',
    'America/Port-au-Prince': 'fr-HT',
    'America/Port_of_Spain': 'en-TT',
    'America/Porto_Velho': 'pt-BR',
    'America/Puerto_Rico': 'es-PR',
    'America/Punta_Arenas': 'es-CL',
    'America/Rainy_River': 'en-CA',
    'America/Rankin_Inlet': 'en-CA',
    'America/Recife': 'pt-BR',
    'America/Regina': 'en-CA',
    'America/Resolute': 'en-CA',
    'America/Rio_Branco': 'pt-BR',
    'America/Santarem': 'pt-BR',
    'America/Santiago': 'es-CL',
    'America/Santo_Domingo': 'es-DO',
    'America/Sao_Paulo': 'pt-BR',
    'America/Scoresbysund': 'kl-GL',
    'America/Sitka': 'en-US',
    'America/St_Barthelemy': 'fr-BL',
    'America/St_Johns': 'en-CA',
    'America/St_Kitts': 'en-KN',
    'America/St_Lucia': 'en-LC',
    'America/St_Thomas': 'en-VI',
    'America/St_Vincent': 'en-VC',
    'America/Swift_Current': 'en-CA',
    'America/Tegucigalpa': 'es-HN',
    'America/Thule': 'da-DK',
    'America/Thunder_Bay': 'en-CA',
    'America/Tijuana': 'es-MX',
    'America/Toronto': 'en-CA',
    'America/Tortola': 'en-VG',
    'America/Vancouver': 'en-CA',
    'America/Whitehorse': 'en-CA',
    'America/Winnipeg': 'en-CA',
    'America/Yakutat': 'en-US',
    'America/Yellowknife': 'en-CA',
    'Antarctica/Casey': 'en-AU',
    'Antarctica/Davis': 'en-AU',
    'Antarctica/DumontDUrville': 'fr-FR',
    'Antarctica/Macquarie': 'en-AU',
    'Antarctica/Mawson': 'en-AU',
    'Antarctica/Palmer': 'es-CL',
    'Antarctica/Rothera': 'en-GB',
    'Antarctica/Syowa': 'ja-JP',
    'Antarctica/Troll': 'no-NO',
    'Antarctica/Vostok': 'ru-RU',
    'Arctic/Longyearbyen': 'no-NO',
    'Asia/Almaty': 'ru-KZ',
    'Asia/Amman': 'ar-JO',
    'Asia/Anadyr': 'ru-RU',
    'Asia/Aqtau': 'ru-KZ',
    'Asia/Aqtobe': 'ru-KZ',
    'Asia/Ashgabat': 'tk-TM',
    'Asia/Atyrau': 'ru-KZ',
    'Asia/Baghdad': 'ar-IQ',
    'Asia/Baku': 'az-Latn-AZ',
    'Asia/Bangkok': 'th-TH',
    'Asia/Barnaul': 'ru-RU',
    'Asia/Beirut': 'ar-LB',
    'Asia/Bishkek': 'ky-KG',
    'Asia/Brunei': 'ms-BN',
    'Asia/Chita': 'ru-RU',
    'Asia/Choibalsan': 'mn-MN',
    'Asia/Colombo': 'si-LK',
    'Asia/Damascus': 'ar-SY',
    'Asia/Dhaka': 'bn-BD',
    'Asia/Dili': 'pt-TL',
    'Asia/Dubai': 'ar-AE',
    'Asia/Dushanbe': 'tg-Cyrl-TJ',
    'Asia/Famagusta': 'tr-CY',
    'Asia/Gaza': 'ar-PS',
    'Asia/Hebron': 'ar-PS',
    'Asia/Ho_Chi_Minh': 'vi-VN',
    'Asia/Hong_Kong': 'zh-HK',
    'Asia/Hovd': 'mn-MN',
    'Asia/Irkutsk': 'ru-RU',
    'Asia/Jakarta': 'id-ID',
    'Asia/Jayapura': 'id-ID',
    'Asia/Jerusalem': 'he-IL',
    'Asia/Kabul': 'fa-AF',
    'Asia/Kamchatka': 'ru-RU',
    'Asia/Karachi': 'ur-PK',
    'Asia/Kathmandu': 'ne-NP',
    'Asia/Khandyga': 'ru-RU',
    'Asia/Kolkata': 'hi-IN',
    'Asia/Krasnoyarsk': 'ru-RU',
    'Asia/Kuala_Lumpur': 'ms-MY',
    'Asia/Kuching': 'ms-MY',
    'Asia/Kuwait': 'ar-KW',
    'Asia/Macau': 'zh-MO',
    'Asia/Magadan': 'ru-RU',
    'Asia/Makassar': 'id-ID',
    'Asia/Manila': 'fil-PH',
    'Asia/Muscat': 'ar-OM',
    'Asia/Nicosia': 'el-CY',
    'Asia/Novokuznetsk': 'ru-RU',
    'Asia/Novosibirsk': 'ru-RU',
    'Asia/Omsk': 'ru-RU',
    'Asia/Oral': 'kk-KZ',
    'Asia/Phnom_Penh': 'km-KH',
    'Asia/Pontianak': 'id-ID',
    'Asia/Pyongyang': 'ko-KP',
    'Asia/Qatar': 'ar-QA',
    'Asia/Qostanay': 'kk-KZ',
    'Asia/Qyzylorda': 'kk-KZ',
    'Asia/Riyadh': 'ar-SA',
    'Asia/Sakhalin': 'ru-RU',
    'Asia/Samarkand': 'uz-Cyrl-UZ',
    'Asia/Seoul': 'ko-KR',
    'Asia/Shanghai': 'zh-CN',
    'Asia/Singapore': 'en-SG',
    'Asia/Srednekolymsk': 'ru-RU',
    'Asia/Taipei': 'zh-TW',
    'Asia/Tashkent': 'uz-Cyrl-UZ',
    'Asia/Tbilisi': 'ka-GE',
    'Asia/Tehran': 'fa-IR',
    'Asia/Thimphu': 'dz-BT',
    'Asia/Tokyo': 'ja-JP',
    'Asia/Tomsk': 'ru-RU',
    'Asia/Ulaanbaatar': 'mn-MN',
    'Asia/Urumqi': 'zh-CN',
    'Asia/Ust-Nera': 'ru-RU',
    'Asia/Vientiane': 'lo-LA',
    'Asia/Vladivostok': 'ru-RU',
    'Asia/Yakutsk': 'ru-RU',
    'Asia/Yangon': 'my-MM',
    'Asia/Yekaterinburg': 'ru-RU',
    'Asia/Yerevan': 'hy-AM',
    'Atlantic/Azores': 'pt-PT',
    'Atlantic/Bermuda': 'en-BM',
    'Atlantic/Canary': 'es-ES',
    'Atlantic/Cape_Verde': 'pt-CV',
    'Atlantic/Faroe': 'fo-FO',
    'Atlantic/Madeira': 'pt-PT',
    'Atlantic/Reykjavik': 'is-IS',
    'Atlantic/South_Georgia': 'en-GB',
    'Atlantic/Stanley': 'en-FK',
    'Atlantic/St_Helena': 'en-SH',
    'Australia/Adelaide': 'en-AU',
    'Australia/Brisbane': 'en-AU',
    'Australia/Broken_Hill': 'en-AU',
    'Australia/Currie': 'en-AU',
    'Australia/Darwin': 'en-AU',
    'Australia/Eucla': 'en-AU',
    'Australia/Hobart': 'en-AU',
    'Australia/Lindeman': 'en-AU',
    'Australia/Lord_Howe': 'en-AU',
    'Australia/Melbourne': 'en-AU',
    'Australia/Perth': 'en-AU',
    'Australia/Sydney': 'en-AU',
    'Europe/Amsterdam': 'nl-NL',
    'Europe/Andorra': 'ca-AD',
    'Europe/Astrakhan': 'ru-RU',
    'Europe/Athens': 'el-GR',
    'Europe/Belgrade': 'sr-Latn-RS',
    'Europe/Berlin': 'de-DE',
    'Europe/Bratislava': 'sk-SK',
    'Europe/Brussels': 'nl-BE',
    'Europe/Bucharest': 'ro-RO',
    'Europe/Budapest': 'hu-HU',
    'Europe/Busingen': 'de-DE',
    'Europe/Chisinau': 'ro-MD',
    'Europe/Copenhagen': 'da-DK',
    'Europe/Dublin': 'en-IE',
    'Europe/Gibraltar': 'en-GI',
    'Europe/Guernsey': 'en-GG',
    'Europe/Helsinki': 'fi-FI',
    'Europe/Isle_of_Man': 'en-IM',
    'Europe/Istanbul': 'tr-TR',
    'Europe/Jersey': 'en-JE',
    'Europe/Kaliningrad': 'ru-RU',
    'Europe/Kiev': 'uk-UA',
    'Europe/Kirov': 'ru-RU',
    'Europe/Lisbon': 'pt-PT',
    'Europe/Ljubljana': 'sl-SI',
    'Europe/London': 'en-GB',
    'Europe/Luxembourg': 'fr-LU',
    'Europe/Madrid': 'es-ES',
    'Europe/Malta': 'mt-MT',
    'Europe/Mariehamn': 'sv-FI',
    'Europe/Minsk': 'be-BY',
    'Europe/Monaco': 'fr-MC',
    'Europe/Moscow': 'ru-RU',
    'Europe/Oslo': 'no-NO',
    'Europe/Paris': 'fr-FR',
    'Europe/Podgorica': 'sr-Latn-ME',
    'Europe/Prague': 'cs-CZ',
    'Europe/Riga': 'lv-LV',
    'Europe/Rome': 'it-IT',
    'Europe/Samara': 'ru-RU',
    'Europe/San_Marino': 'it-SM',
    'Europe/Sarajevo': 'bs-Latn-BA',
    'Europe/Saratov': 'ru-RU',
    'Europe/Simferopol': 'ru-UA',
    'Europe/Skopje': 'mk-MK',
    'Europe/Sofia': 'bg-BG',
    'Europe/Stockholm': 'sv-SE',
    'Europe/Tallinn': 'et-EE',
    'Europe/Tirane': 'sq-AL',
    'Europe/Ulyanovsk': 'ru-RU',
    'Europe/Uzhgorod': 'uk-UA',
    'Europe/Vaduz': 'de-LI',
    'Europe/Vatican': 'it-VA',
    'Europe/Vienna': 'de-AT',
    'Europe/Vilnius': 'lt-LT',
    'Europe/Volgograd': 'ru-RU',
    'Europe/Warsaw': 'pl-PL',
    'Europe/Zagreb': 'hr-HR',
    'Europe/Zaporozhye': 'uk-UA',
    'Europe/Zurich': 'de-CH',
    'Indian/Antananarivo': 'mg-MG',
    'Indian/Chagos': 'en-IO',
    'Indian/Christmas': 'en-CX',
    'Indian/Cocos': 'ms-CC',
    'Indian/Comoro': 'ar-KM',
    'Indian/Kerguelen': 'fr-FR',
    'Indian/Mahe': 'fr-SC',
    'Indian/Maldives': 'dv-MV',
    'Indian/Mauritius': 'fr-MU',
    'Indian/Mayotte': 'fr-YT',
    'Indian/Reunion': 'fr-RE',
    'Pacific/Apia': 'sm-WS',
    'Pacific/Auckland': 'en-NZ',
    'Pacific/Bougainville': 'en-PG',
    'Pacific/Chatham': 'en-NZ',
    'Pacific/Chuuk': 'chk-FM',
    'Pacific/Easter': 'es-CL',
    'Pacific/Efate': 'fr-VU',
    'Pacific/Enderbury': 'en-KI',
    'Pacific/Fakaofo': 'tk-TK',
    'Pacific/Fiji': 'en-FJ',
    'Pacific/Funafuti': 'tvl-TV',
    'Pacific/Galapagos': 'es-EC',
    'Pacific/Gambier': 'fr-PF',
    'Pacific/Guadalcanal': 'en-SB',
    'Pacific/Guam': 'en-GU',
    'Pacific/Honolulu': 'en-US',
    'Pacific/Kiritimati': 'en-KI',
    'Pacific/Kosrae': 'kos-FM',
    'Pacific/Kwajalein': 'en-MH',
    'Pacific/Majuro': 'en-MH',
    'Pacific/Marquesas': 'fr-PF',
    'Pacific/Midway': 'en-UM',
    'Pacific/Nauru': 'na-NR',
    'Pacific/Niue': 'niu-NU',
    'Pacific/Norfolk': 'en-NF',
    'Pacific/Noumea': 'fr-NC',
    'Pacific/Pago_Pago': 'en-AS',
    'Pacific/Palau': 'en-PW',
    'Pacific/Pitcairn': 'en-PN',
    'Pacific/Pohnpei': 'pon-FM',
    'Pacific/Port_Moresby': 'en-PG',
    'Pacific/Rarotonga': 'en-CK',
    'Pacific/Tahiti': 'fr-PF',
    'Pacific/Tarawa': 'en-KI',
    'Pacific/Tongatapu': 'to-TO',
    'Pacific/Wake': 'en-UM',
    'Pacific/Wallis': 'fr-WF',
  };